import { Charts, DashboardCards } from '../dashComponents';

export default function DashBoardMain() {
    return (
        <div className='scrollbar-hidden slide-top pt-10 overflow-y-scroll h-screen'>
            <DashboardCards />
            
        </div>
    );
}
