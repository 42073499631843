import { useEffect, useState } from 'react';
import { storage } from '../utils';
import { check_user } from '../api/auth';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '@/store';
import { CheckUser } from '../utils/UseUser';

export const RoleProtector = ({
    element,
    role,
}: {
    element: JSX.Element;
    role: string;
}): JSX.Element => {
    const token = storage.getToken();
     

    const { userAccount: user } = useAppSelector(store => store.user);

    if (!user || user === null) return <Navigate to={'/login'} />;

    if (!token) return <Navigate to={'/login'} />;

    if (user?.role !== role) return <Navigate to={'/login'} />;

    return element;
};
