import { useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { setPageTitle } from '@/store/themeConfigSlice';
import { useDispatch } from 'react-redux';



export default function ModeratorEvaluations() {
	const location = useLocation();
    const dispatch = useDispatch()
	const link = [
		{
			to: "",
			title: "Pending applications",
			active: location.pathname === "",
		},
		{
			to: "evaluated",
			title: "Reviewed applications",
			active: location.pathname === "evaluated",

		},
        
	];
    useEffect(() => {
        dispatch(setPageTitle('Evaluation'))
    }, [dispatch]);
    
	return (
		<>
           <div className=" my-2 ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <Link to='/account/modulator'  className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <button className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                            Home
                        </button>
                    </Link>
                    
                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            Evaluations 
                        </button>
                    </li>
                </ol>

            </div>
           
			<Tab.Group>
				<Tab.List className="mt-3 flex flex-wrap">
                    {link.map((tab, index) => (
                        <Tab as={Fragment}>
                            {() => (
                                <Link
                                    to={tab.to}
                                    className={`${
                                        tab.active
                                            ? "border-b !border-secondary text-secondary !outline-none"
                                            : ""
                                    } -mb-[1px] flex items-center border-transparent p-5 py-3 before:inline-block hover:border-b hover:!border-secondary hover:text-secondary`}
                                >
                                    
                                    {tab.title}
                                </Link>
                            )}
                        </Tab>
                    ))}
				</Tab.List>
			</Tab.Group>
			<div className=' mt-2 p-4 '>
				<Outlet />
			</div>
		</>
	);
}


