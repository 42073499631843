import { useState } from 'react';
import { toast } from 'react-hot-toast';
import {
    add_topic,
    delete_topic,
    edit_topic,
    get_topics,
    one_topic,
} from '@/core/api/topics';
import { TopicPayload, TopicType } from '@/core/types/topics';
import { PaginationType } from '@/core/types/shared';

export const GetTopics = () => {
    const [loadingTopics, setLoadingTopics] = useState(false);
    const [topics, setTopics]: any = useState<TopicType>();

    const handleGetTopics = async (query?: string) => {
        try {
            setLoadingTopics(true);
            const data: PaginationType<TopicType> = await get_topics(query);
            setTopics(data);
        } catch (error: any) {
            toast.error(error.response.message || 'Error fetching topics');
        } finally {
            setLoadingTopics(false);
        }
    };

    return {
        loadingTopics,
        topics,
        handleGetTopics,
    };
};

export const GetSingleTopic = () => {
    const [loadingTopic, setLoadingTopic] = useState(false);
    const [topic, setTopic]: any = useState<TopicType | null>();

    const handleGetTopic = async (id?: any) => {
        try {
            setLoadingTopic(true);
            const data: TopicType = await one_topic(id);
            setTopic(data);
        } catch (error: any) {
            toast.error(error.response.message || 'Error fetching topic');
        } finally {
            setLoadingTopic(false);
        }
    };

    return {
        loadingTopic,
        topic,
        handleGetTopic,
    };
};

export const EditTopicInfo = () => {
    const [loadingEditTopic, setLoadingEditTopic] = useState(false);

    const handleEditTopic = async (payload: TopicPayload, id: string) => {
        try {
            setLoadingEditTopic(true);
            await edit_topic(payload, id);
            toast.success('Topic information updated');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditTopic(false);
        }
    };

    return {
        handleEditTopic,
        loadingEditTopic,
    };
};

export const useCreateTopic = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);

    const handleCreateTopic = async (payload: TopicPayload) => {
        try {
            setLoadingCreate(true);
            await add_topic(payload);
            toast.success('Topic information added');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreateTopic,
    };
};

export const useDeleteTopic = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);

    const handleDeleteTopic = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_topic(id);
            toast.success('Topic information deleted');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDeleteTopic,
    };
};
