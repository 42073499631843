// src/core/hooks/supervisors.ts
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { SupervisorPayload, SupervisorType } from '@/core/types/supervisor';
import { getSupervisors, getSupervisor, editSupervisor, addSupervisor, deleteSupervisor } from '@/core/api/supervisor';
import { PaginationType } from '@/core/types/shared';
import { ApplicationType } from '@/core/types/application';

export const GetSupervisors = () => {
  const [loadingSupervisors, setLoadingSupervisors] = useState(false);
  const [supervisors, setSupervisors]: any = useState<SupervisorType[] | null>(null);

  const handleGetSupervisors = async (query?:string) => {
    try {
      setLoadingSupervisors(true);
      const data: PaginationType<SupervisorType> =
      await getSupervisors(query);
      setSupervisors(data);
    } catch (error: any) {
      toast.error(error.response.message || 'Error fetching supervisors');
    } finally {
      setLoadingSupervisors(false);
    }
  };

  return {
    loadingSupervisors,
    supervisors,
    handleGetSupervisors,
  };
};

export const GetSingleSupervisor = () => {
  const [loadingSupervisor, setLoadingSupervisor] = useState(false);
  const [supervisor, setSupervisor]: any = useState<SupervisorType | null>(null);

  const handleGetSupervisor = async (id?: string) => {
    try {
      setLoadingSupervisor(true);
      const data: SupervisorType = await getSupervisor(id || '');
      setSupervisor(data);
    } catch (error: any) {
      toast.error(error.response.message || 'Error fetching supervisor');
    } finally {
      setLoadingSupervisor(false);
    }
  };

  return {
    loadingSupervisor,
    supervisor,
    handleGetSupervisor,
  };
};

export const EditSupervisorInfo = () => {
  const [loadingEditSupervisor, setLoadingEditSupervisor] = useState(false);

  const handleEditSupervisor = async (payload: SupervisorPayload, id: string) => {
    try {
      setLoadingEditSupervisor(true);
      await editSupervisor(payload, id);
      toast.success('Supervisor information updated');
    } catch (error: any) {
      toast.error(error.response.message || 'Something went wrong');
    } finally {
      setLoadingEditSupervisor(false);
    }
  };

  return {
    handleEditSupervisor,
    loadingEditSupervisor,
  };
};

export const useCreateSupervisor = () => {
  const [loadingCreateSupervisor, setLoadingCreateSupervisor] = useState(false);

  const handleCreateSupervisor = async (payload: SupervisorPayload) => {
    try {
      setLoadingCreateSupervisor(true);
      await addSupervisor(payload);
      toast.success('Supervisor information added');
    } catch (error: any) {
      toast.error(error.response.message || 'Something went wrong');
    } finally {
      setLoadingCreateSupervisor(false);
    }
  };

  return {
    loadingCreateSupervisor,
    handleCreateSupervisor,
  };
};

export const useDeleteSupervisor = () => {
  const [loadingDeleteSupervisor, setLoadingDeleteSupervisor] = useState(false);

  const handleDeleteSupervisor = async (id: string) => {
    try {
      setLoadingDeleteSupervisor(true);
      await deleteSupervisor(id);
      toast.success('Supervisor information deleted');
    } catch (error: any) {
      toast.error(error.response.message || 'Error Occurred');
    } finally {
      setLoadingDeleteSupervisor(false);
    }
  };

  return {
    loadingDeleteSupervisor,
    handleDeleteSupervisor,
  };
};
