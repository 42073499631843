import IconArchive from '@/components/Icon/IconArchive';
import IconMenuDocumentation from '@/components/Icon/Menu/IconMenuDocumentation';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import {
    GetApplications,
    useCreateApplications,
} from '@/core/hooks/application';
import { GetCategories } from '@/core/hooks/category';
import { useCreateTopic } from '@/core/hooks/topics';
import { ApplicationPayload } from '@/core/types/application';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import Topics from '../../topics';
import QuestionsInfo from '../../questions';
import { GetfilesTypes } from '@/core/hooks/file';

const experienceSchema = z.object({
    title: z.string().min(1, { message: 'Title is required' }),
    maximum_topic_number: z
        .string()
        .min(1, { message: 'Topic Numbers is required' }),
    allow_suggested_topic: z
        .string()
        .min(1, { message: 'Allow SuggestedTopic  is required' }),
    starting_date: z.string().min(1, { message: 'Start Date is required' }),
    code: z.string().min(1, { message: 'Application code  is required' }),
    ending_date: z.string().min(1, { message: 'End Date is required' }),
    number_of_evaluators: z.string(),
    isAcademicManagerAllowed: z.string(),
});

 export default function CreateApplication({ activeTab2, setActiveTab2 }: any) {
    const { loadingCreate, handleCreateApplication } = useCreateApplications();
    const { loadingfiles, files, handleGetfiles } = GetfilesTypes();
    const [description, setdescription] = useState(
        'Some   description of the Application '
    );
    const [value, setValue] = useState('Some   notes of the Application ');
    const [isFileUploadNeeded, setIsFileUploadNeeded] = useState<string>(''); // State to track if file upload is needed

    const querry = location.search;
    useEffect(() => {
        handleGetfiles(querry);
    }, []);

    const [fileTypes, setSelectedfiles] = useState<any | null>([]);

    return (
        
        <div>
             <div className=" mb-5  ">
                    <ol className="flex text-primary font-semibold dark:text-white-dark">
                        <Link
                            to="/account/admin"
                            className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]"
                        >
                            <button className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                                Home
                            </button>
                        </Link>
                        <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                            <Link
                                to="/account/admin/application"
                                className="p-1.5 px-3 ltr:pl-6 rtl:pr-6 relative   h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70"
                            >
                                Application
                            </Link>
                        </li>
                        <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                            <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                                Create Applications
                            </button>
                        </li>
                    </ol>
                </div>
            <Form<ApplicationPayload, typeof experienceSchema>
                schema={experienceSchema}
                onSubmit={async data => {
                    data.instruction_note = value;
                    data.description = description;
                    data.isFileUploadNeeded = isFileUploadNeeded;
                    data.isAcademicManagerAllowed = data.isAcademicManagerAllowed === 'true';
                    if (fileTypes.length > 0) {
                        data.fileTypeIds = fileTypes;
                    }
                    await handleCreateApplication(data);
                    setActiveTab2(activeTab2 === 1 ? 2 : 3);
                }}
                className="mt-3"
            >
                {({ register, formState }) => (
                    <>
                        <div className="my-4 ">
                            <p className="text-lg font-bold ">
                                Create Application{' '}
                            </p>
                        </div>
                        <div className=" panel ">
                            <div className=" ">
                                <div className="grid grid-cols-2 gap-4">
                                    <InputField
                                        type="text"
                                        isLoading={loadingCreate}
                                        placeholder="Name of Application"
                                        label="Application Title"
                                        error={formState.errors.title}
                                        registration={register('title')}
                                    />
                                    <InputField
                                        type="text"
                                        isLoading={loadingCreate}
                                        placeholder="Application code "
                                        label="Application code"
                                        error={formState.errors.code}
                                        registration={register('code')}
                                    />
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <InputField
                                        type="date"
                                        isLoading={loadingCreate}
                                        placeholder="e.g., 2022-01-01"
                                        label="Start Date"
                                        error={formState.errors.starting_date}
                                        registration={register('starting_date')}
                                    />
                                    <InputField
                                        type="date"
                                        isLoading={loadingCreate}
                                        placeholder="e.g., 2026-12-31"
                                        label="End Date"
                                        error={formState.errors.ending_date}
                                        registration={register('ending_date')}
                                    />
                                    <InputField
                                        type="number"
                                        isLoading={loadingCreate}
                                        placeholder="Maximun number of topics"
                                        label="Maximun number of topics"
                                        error={
                                            formState.errors
                                                .maximum_topic_number
                                        }
                                        registration={register(
                                            'maximum_topic_number'
                                        )}
                                    />
                                    <SelectField
                                        placeholder="Allow suggested Topics"
                                        isLoading={loadingCreate}
                                        label="Allow Suggested Topics "
                                        error={
                                            formState.errors
                                                .allow_suggested_topic
                                        }
                                        options={[
                                            {
                                                value: false,
                                                text: 'No',
                                            },
                                            {
                                                value: true,
                                                text: 'Yes',
                                            },
                                        ]}
                                        registration={register(
                                            'allow_suggested_topic'
                                        )}
                                    />
                                    <SelectField
                                        placeholder="Is file upload needed"
                                        isLoading={loadingCreate}
                                        label="Is file upload needed "
                                        error={
                                            formState.errors.isFileUploadNeeded
                                        }
                                        options={[
                                            {
                                                value: false,
                                                text: 'No',
                                            },
                                            {
                                                value: true,
                                                text: 'Yes',
                                            },
                                        ]}
                                        onChange={(e: any) => {
                                            setIsFileUploadNeeded(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                                {isFileUploadNeeded === 'true' && (
                                    <div className="my-5  h-[100px] overflow-y-scroll form-input  ">
                                        <label htmlFor="select category">
                                            {' '}
                                            Select file type{' '}
                                            <span className="font-semibold">
                                                (please ignore this if no file
                                                upload needed)
                                            </span>
                                        </label>

                                        <label className="flex flex-col  mx-2 ">
                                            {files?.list.map((file: any) => (
                                                <>
                                                    {' '}
                                                    <div className="flex flex-row justify-start mb-4">
                                                        <div>
                                                            <input
                                                                id={`checkbox-${file.id}`}
                                                                type="checkbox"
                                                                value={file.id}
                                                                className="form-checkbox w-4 h-4"
                                                                onChange={(
                                                                    e: React.ChangeEvent<HTMLInputElement>
                                                                ) => {
                                                                    const fileId =
                                                                        e.target
                                                                            .value;
                                                                    setSelectedfiles(
                                                                        (
                                                                            prevSelected: string[]
                                                                        ) => {
                                                                            if (
                                                                                prevSelected.includes(
                                                                                    fileId
                                                                                )
                                                                            ) {
                                                                                return prevSelected.filter(
                                                                                    (
                                                                                        id: string
                                                                                    ) =>
                                                                                        id !==
                                                                                        fileId
                                                                                );
                                                                            } else {
                                                                                return [
                                                                                    ...prevSelected,
                                                                                    fileId,
                                                                                ];
                                                                            }
                                                                        }
                                                                    );
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            <label className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                                {file?.name}{' '}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </label>
                                    </div>
                                )}
                                <InputField
                                    type="number"
                                    isLoading={loadingCreate}
                                    placeholder="Number of Evaluators"
                                    label="Number of Evaluators (2 or 3)"
                                    error={
                                        formState.errors.number_of_evaluators
                                    }
                                    registration={register(
                                        'number_of_evaluators'
                                    )}
                                />
                                <SelectField
                                    placeholder="Is Academic Manager Allowed"
                                    isLoading={loadingCreate}
                                    label="Is Academic Manager Allowed"
                                    error={
                                        formState.errors
                                            .isAcademicManagerAllowed
                                    }
                                    options={[
                                        { value: 'true', text: 'Yes' },
                                        { value: 'false', text: 'No' },
                                    ]}
                                    registration={register(
                                        'isAcademicManagerAllowed'
                                    )}
                                />

                                <div className="mt-3">
                                    <label className=" mb-1  dark:text-white-light ">
                                        Description
                                    </label>
                                    <ReactQuill
                                        theme="snow"
                                        value={description}
                                        onChange={val => setdescription(val)}
                                    />
                                </div>
                            </div>
                            <div className="mt-3">
                                <label className=" mb-1  dark:text-white-light ">
                                    Instruction Notes
                                </label>
                                <ReactQuill
                                    theme="snow"
                                    value={value}
                                    onChange={val => setValue(val)}
                                />
                            </div>
                        </div>

                        <div className="flex flex-row justify-end mt-4 ">
                            <Button
                                type="submit"
                                className="btn btn-primary mt-4  ltr:ml-auto rtl:mr-auto"
                                disabled={loadingCreate}
                            >
                                {activeTab2 === 3 ? 'Finish' : 'Next'}
                            </Button>
                        </div>
                    </>
                )}
            </Form>
        </div>
    );
}

