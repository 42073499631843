import React, { useEffect } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import IconPlus from '@/components/Icon/IconPlus';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { EditUser, useCreateUser } from '@/core/hooks/auth';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import { edit_user } from '@/core/api/auth';
import IconPencil from '@/components/Icon/IconPencil';
import { EditAddress } from '@/core/hooks/address';
import { countries } from 'countries-list';

const schema = z.object({
    country: z.string().min(1, { message: 'Country is required' }),
    street: z.string(),
    city: z.string().min(1, { message: 'City is required' }),
    type: z.string().min(1, { message: 'Address type is required' }),

});

export type RegisterInput = z.TypeOf<typeof schema>;

export const UpdateAddress = ({ refresh, user }: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const { loadingEditAddress, hanldeEditaddress } = EditAddress();
    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="bg-warning text-gray-200 p-1 rounded"
                >
                    <IconPencil className="w-4" />
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                        Update Address
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<RegisterInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (data: any) => {
                                                await hanldeEditaddress(
                                                    data,
                                                    user.id
                                                );
                                                refresh((prev: any) => !prev);
                                                setModal1(false);
                                            }}
                                            className="mt-3  "
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                                                    <SelectField
                                        placeholder="Select Country"
                                        isLoading={loadingEditAddress}
                                        label="Country"
                                       defaultValue={user?.country}
                                        error={formState.errors.country}
                                        options={countryOptions}
                                        registration={register('country')}
                                    />
                                        <InputField
                                            type="text"
                                            isLoading={loadingEditAddress}
                                            label="Street"
                                            defaultValue={user?.street}
                                            error={formState.errors.street}
                                            registration={register('street')}
                                        />
                                        <InputField
                                            type="text"
                                            isLoading={loadingEditAddress}
                                            label="City"
                                            defaultValue={user.city}
                                            error={formState.errors.city}
                                            registration={register('city')}
                                        />
                                        <SelectField
                                            placeholder="Select Address Type"
                                            isLoading={loadingEditAddress}
                                            label=" Address type"
                                            defaultValue={user?.type}
                                            error={formState.errors.type}
                                            options={[
                                                {
                                                    value: 'mailing address',
                                                    text: 'Mailing address',
                                                },
                                                {
                                                    value: 'permanent address',
                                                    text: 'Permanent address',
                                                },
                                                {
                                                    value: 'birth place',
                                                    text: 'Birth place',
                                                },
                                            ]}
                                            registration={register('type')}
                                        />
                                       
                                                    </div>

                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingEditAddress
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
