import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios';
import { PaginationType } from '@/core/types/shared';
import { queryString } from '@/core/utils/query';
import { ReferencePayload, ReferenceType } from '@/core/types/reference';

export const add_reference = async (
    payload: ReferencePayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/reference', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_reference = async (
    payload: ReferencePayload,
    id: string
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/reference/${id}`, payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const one_reference = async (
    id: string
): Promise<ReferenceType | any> => {
    try {
        const request = await PRIVATE_API.get(`/reference/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_reference = async (
    id: string
): Promise<ReferenceType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/reference/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_references = async (
    query?: string
): Promise<PaginationType<ReferenceType>> => {
    try {
        const request = await PRIVATE_API.get(`/reference${queryString(query)}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
