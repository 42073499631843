import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField, TextArea } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { EditAddress } from '@/core/hooks/address';
import { EditAcademicInfo } from '@/core/hooks/academics';
import { AcademicInfoPayload } from '@/core/types/academic';
import { EditApplicationInfo } from '@/core/hooks/application';
import { ApplicationPayload, ApplicationType } from '@/core/types/application';
import ReactQuill from 'react-quill';
import { GetfilesTypes } from '@/core/hooks/file';

const ApplicationSchema = z.object({
    title: z.string().min(1, { message: 'Title is required' }),

    maximum_topic_number: z
        .string()
        .min(1, { message: 'Topic Numbers is required' }),
    allow_suggested_topic: z
        .string()
        .min(1, { message: 'Allow SuggestedTopic  is required' }),
    starting_date: z.string().min(1, { message: 'Start Date is required' }),
    ending_date: z.string().min(1, { message: 'End Date is required' }),
    number_of_evaluators: z.string(),
    isAcademicManagerAllowed: z.string(),
});

interface UpdateAcademicInfoProps {
    refresh: (prev: any) => void;
    application: ApplicationType;
}

export const UpdateApplication: React.FC<UpdateAcademicInfoProps> = ({
    refresh,
    application,
}) => {
    const [modal, setModal] = React.useState(false);
    const { loadingEditApplication, handleEditApplication } =
        EditApplicationInfo();
    const [selectedStartDateDate, setSelectedStartDate] = useState(
        application?.starting_date
            ? new Date(application.starting_date)
            : new Date()
    );
    const [selectedendDate, setSelectedEndDate] = useState(
        application?.ending_date
            ? new Date(application.ending_date)
            : new Date()
    );
    const { loadingfiles, files, handleGetfiles } = GetfilesTypes();
    const [description, setDescription] = useState('Add some description Here');
    const [value, setValue] = useState('Add some Instrunction Here');

    const [isFileUploadNeeded, setIsFileUploadNeeded] = useState<string>('');
    const querry = location.search;
    useEffect(() => {
        handleGetfiles(querry);
    }, []);

    const [fileTypes, setSelectedfiles] = useState<any | null>([]);

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="btn btn-warning "
                >
                    Edit this application
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-4xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Update Application
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<
                                            ApplicationPayload,
                                            typeof ApplicationSchema
                                        >
                                            schema={ApplicationSchema}
                                            onSubmit={async data => {
                                                data.instruction_note = value;
                                                data.description = description;
                                                data.isFileUploadNeeded =
                                                    isFileUploadNeeded;
                                                    data.isAcademicManagerAllowed = data.isAcademicManagerAllowed === 'true';
                                                    data.allow_suggested_topic = data.allow_suggested_topic === 'true';
                                                if (fileTypes.length > 0) {
                                                    data.fileTypeIds =
                                                        fileTypes;
                                                }
                                                await handleEditApplication(
                                                    data,
                                                    application.id
                                                );
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="px-4  text-sm mb-3">
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditApplication
                                                            }
                                                            placeholder="name of Application"
                                                            label="Application Title"
                                                            defaultValue={
                                                                application.title
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .title
                                                            }
                                                            registration={register(
                                                                'title'
                                                            )}
                                                        />

                                                        <div className="grid grid-cols-2 gap-4 ">
                                                            <InputField
                                                                type="date"
                                                                label="Start date"
                                                                placeholder="Start date"
                                                                isLoading={
                                                                    loadingEditApplication
                                                                }
                                                                defaultValue={
                                                                    selectedStartDateDate
                                                                        .toISOString()
                                                                        .split(
                                                                            'T'
                                                                        )[0]
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    setSelectedStartDate(
                                                                        new Date(
                                                                            e.target.starting_date
                                                                        )
                                                                    )
                                                                }
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .starting_date
                                                                }
                                                                registration={register(
                                                                    'starting_date'
                                                                )}
                                                                className=""
                                                            />
                                                            <InputField
                                                                type="date"
                                                                label="End date"
                                                                placeholder="End date"
                                                                isLoading={
                                                                    loadingEditApplication
                                                                }
                                                                defaultValue={
                                                                    selectedendDate
                                                                        .toISOString()
                                                                        .split(
                                                                            'T'
                                                                        )[0]
                                                                }
                                                                onChange={(
                                                                    e: any
                                                                ) =>
                                                                    setSelectedEndDate(
                                                                        new Date(
                                                                            e.target.ending_date
                                                                        )
                                                                    )
                                                                }
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .ending_date
                                                                }
                                                                registration={register(
                                                                    'ending_date'
                                                                )}
                                                                className=""
                                                            />

                                                            <InputField
                                                                type="number"
                                                                isLoading={
                                                                    loadingEditApplication
                                                                }
                                                                defaultValue={
                                                                    application?.maximum_topic_number
                                                                }
                                                                placeholder="Maximun number of topics"
                                                                label="Maximun number of topics"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .maximum_topic_number
                                                                }
                                                                registration={register(
                                                                    'maximum_topic_number'
                                                                )}
                                                            />
                                                            <SelectField
                                                                placeholder="Allow suggested Topics"
                                                                isLoading={
                                                                    loadingEditApplication
                                                                }
                                                                defaultValue={
                                                                    application?.allow_suggested_topic
                                                                }
                                                                label="Allow Suggested Topics "
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .allow_suggested_topic
                                                                }
                                                                options={[
                                                                    {
                                                                        value: false,
                                                                        text: 'No',
                                                                    },
                                                                    {
                                                                        value: true,
                                                                        text: 'Yes',
                                                                    },
                                                                ]}
                                                                registration={register(
                                                                    'allow_suggested_topic'
                                                                )}
                                                            />
                                                            <SelectField
                                                                placeholder="Is file upload needed"
                                                                isLoading={
                                                                    loadingEditApplication
                                                                }
                                                                label="Is file upload needed "
                                                                defaultValue={
                                                                    application?.isFileUploadNeeded
                                                                }
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .isFileUploadNeeded
                                                                }
                                                                options={[
                                                                    {
                                                                        value: false,
                                                                        text: 'No',
                                                                    },
                                                                    {
                                                                        value: true,
                                                                        text: 'Yes',
                                                                    },
                                                                ]}
                                                                onChange={(
                                                                    e: any
                                                                ) => {
                                                                    setIsFileUploadNeeded(
                                                                        e.target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </div>

                                                        {isFileUploadNeeded ===
                                                            'true' && (
                                                            <div className="my-5  h-[100px] overflow-y-scroll form-input  ">
                                                                <label htmlFor="select category">
                                                                    {' '}
                                                                    Select file
                                                                    type{' '}
                                                                    <span className="font-semibold">
                                                                        (please
                                                                        ignore
                                                                        this if
                                                                        no file
                                                                        upload
                                                                        needed)
                                                                    </span>
                                                                </label>

                                                                <label className="flex flex-col  mx-2 ">
                                                                    {files?.list.map(
                                                                        (
                                                                            file: any
                                                                        ) => (
                                                                            <>
                                                                                {' '}
                                                                                <div className="flex flex-row justify-start mb-4">
                                                                                    <div>
                                                                                        <input
                                                                                            id={`checkbox-${file.id}`}
                                                                                            type="checkbox"
                                                                                            value={
                                                                                                file.id
                                                                                            }
                                                                                            className="form-checkbox w-4 h-4"
                                                                                            onChange={(
                                                                                                e: React.ChangeEvent<HTMLInputElement>
                                                                                            ) => {
                                                                                                const fileId =
                                                                                                    e
                                                                                                        .target
                                                                                                        .value;
                                                                                                setSelectedfiles(
                                                                                                    (
                                                                                                        prevSelected: string[]
                                                                                                    ) => {
                                                                                                        if (
                                                                                                            prevSelected.includes(
                                                                                                                fileId
                                                                                                            )
                                                                                                        ) {
                                                                                                            return prevSelected.filter(
                                                                                                                (
                                                                                                                    id: string
                                                                                                                ) =>
                                                                                                                    id !==
                                                                                                                    fileId
                                                                                                            );
                                                                                                        } else {
                                                                                                            return [
                                                                                                                ...prevSelected,
                                                                                                                fileId,
                                                                                                            ];
                                                                                                        }
                                                                                                    }
                                                                                                );
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div>
                                                                                        <label className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                                                            {
                                                                                                file?.name
                                                                                            }{' '}
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    )}
                                                                </label>
                                                            </div>
                                                        )}
                                                        <InputField
                                                            type="number"
                                                            isLoading={
                                                                loadingEditApplication
                                                            }
                                                            defaultValue={
                                                                application?.number_of_evaluators
                                                            }
                                                            placeholder="Number of Evaluators 2  or 3"
                                                            label="Number of Evaluators (2 or 3)"
                                                            error={
                                                                formState.errors
                                                                    .number_of_evaluators
                                                            }
                                                            registration={register(
                                                                'number_of_evaluators'
                                                            )}
                                                        />
                                                        <SelectField
                                                            placeholder="Is Academic Manager Allowed"
                                                            isLoading={
                                                                loadingEditApplication
                                                            }
                                                            label="Is Academic Manager Allowed"
                                                            defaultValue={application?.isAcademicManagerAllowed.toString()}
                                                            error={
                                                                formState.errors
                                                                    .isAcademicManagerAllowed
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'true',
                                                                    text: 'Yes',
                                                                },
                                                                {
                                                                    value: 'false',
                                                                    text: 'No',
                                                                },
                                                            ]}
                                                            registration={register(
                                                                'isAcademicManagerAllowed'
                                                            )}
                                                        />
                                                        <div className="p-4">
                                                            <label className=" mb-1  dark:text-white-light ">
                                                                Description
                                                            </label>
                                                            <ReactQuill
                                                                theme="snow"
                                                                defaultValue={
                                                                    application.description
                                                                }
                                                                onChange={
                                                                    setDescription
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="p-4">
                                                        <label className=" mb-1  dark:text-white-light ">
                                                            Instruction Notes
                                                        </label>
                                                        <ReactQuill
                                                            theme="snow"
                                                            defaultValue={
                                                                application.instruction_note
                                                            }
                                                            onChange={setValue}
                                                        />
                                                    </div>
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingEditApplication
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
