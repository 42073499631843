import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { EditAddress } from '@/core/hooks/address';
import { EditAcademicInfo } from '@/core/hooks/academics';
import { AcademicInfoPayload } from '@/core/types/academic';
import { EditApplicationInfo } from '@/core/hooks/application';
import { ApplicationPayload, ApplicationType } from '@/core/types/application';
import ReactQuill from 'react-quill';
import IconEye from '@/components/Icon/IconEye';

const ApplicationSchema = z.object({
  title: z.string().min(1, { message: 'Title is required' }),
  description: z.string().min(1, { message: 'Description is required' }),
  starting_date: z.string().min(1, { message: 'Start Date is required' }),
  ending_date: z.string().min(1, { message: 'End Date is required' }),
});

interface UpdateAcademicInfoProps {
  refresh: (prev: any) => void;
  application: ApplicationType;
}

export const More: React.FC<UpdateAcademicInfoProps> = ({ refresh, application }) => {
  const [modal, setModal] = React.useState(false);
  const { loadingEditApplication, handleEditApplication } = EditApplicationInfo();

  return (
    <div className="">
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={() => setModal(true)}
          className="btn btn-warning btn-sm"
        >
          <IconEye className="w-4" />
        </button>
      </div>
      <Transition appear show={modal} as={Fragment}>
        <Dialog as="div" open={modal} onClose={() => setModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0" />
          </Transition.Child>
          <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
            <div className="flex items-start justify-center min-h-screen px-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  as="div"
                  className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                >
                  <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                    <div className="text-lg flex flex-row justify-center w-full">
                      {application.title}
                    </div>
                  </div>
                  <div className="p-5">
                    <Form<ApplicationPayload, typeof ApplicationSchema>
                      schema={ApplicationSchema}
                      onSubmit={async data => {
                        await handleEditApplication(data, application.id);
                        refresh((prev: any) => !prev);
                        setModal(false);
                      }}
                      className="mt-3"
                    >
                      {({ register, formState }) => (
                        <>
                          <div className="px-4 gap-4 text-sm mb-3">
                            <div className="flex flex-row justify-center border-b ">
                              <p className="text-lg  ">
                                <b>Descriptions</b>
                              </p>
                            </div>
                

                            <div
                                className="mt-3"
                                dangerouslySetInnerHTML={{
                                  __html: application.description || '',
                                }}
                              />
                            <div>
                              <div className="flex flex-row justify-center border-b ">
                                <p className="text-lg ">
                                  <b>Instructions</b>
                                </p>
                              </div>
                              <div
                                className="mt-3"
                                dangerouslySetInnerHTML={{
                                  __html: application.instruction_note || '',
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </Form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
