import {
    FunnelIcon,
    TrashIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { UserFilterPayload, UserType } from '@/core/types/auth';
import { useGetAllUsers } from '@/core/hooks/auth';

import DeleteUserModal from './modals/delete_modal';
import { PaginationType } from '@/core/types/shared';
import Button from '@/components/button';
import Modal from '@/components/modal';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import { UpdateUser } from './modals/Edit_user_modal';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import CreateUser from './create';
import { AddUser } from './modals/createUser';

type UserData = {
    name: string;
    age: string;
    phoneNumber: string;
    password: string;
    address: string;
    location: string;
};

export default function DashUsers() {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<UserType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<UserFilterPayload>({
        from: '',
        to: '',
    });
    const location = useLocation();
    const query = location.search;

    const { loadingUsers, handleGetUsers, users } = useGetAllUsers();

    useEffect(() => {
        handleGetUsers(query);
    }, [query, refresh]);
    const columns: TableColumnV2<UserType>[] = [
        {
            title: 'First Name',
            accessor: 'firstName',
            render: row => <p className="capitalize">{row.firstName}</p>,
        },
        {
            title: 'Last Name',
            accessor: 'lastName',
            render: row => <p className="capitalize ">{row.lastName}</p>,
        },
        {
            title: 'Email',
            accessor: 'email',
            render: row => <p>{row.email}</p>,
        },
     
        {
            title: 'Phone Number',
            accessor: 'phoneNumber',
            render: row => <p>{row.phoneNumber}</p>,
        },
        {
            title: 'Role',
            accessor: 'role',
            render: row => <p>{row.role}</p>,
        },
         
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p>
                    {row?.status === '1' || row?.status === 'active' ? (
                        <span className="badge bg-success">Active</span>
                    ) : (
                        <span className="badge bg-danger">Inactive</span>
                    )}
                </p>
            ),
        },
        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: (row) => (
              <p>{formatDateToLongForm(row?.created_at)}</p>
            ),
          },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>
                    <UpdateUser user={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];
    return (
        <><div className='my-2 '>

            <ol className="flex text-primary font-semibold dark:text-white-dark">
                <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                    <Link to="/account/admin" className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                        Home
                    </Link>
                </li>
                <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                    <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                        Users
                    </button>
                </li>
              
            </ol>

        </div>
        
		<div className="my-4 " >
			<p className="text-lg font-bold "> Users </p>
		</div>
        <div className="">
                <DataTableV2
                tableName='Users'
                    columns={columns}
                    previousPage={users?.previousPage ?? 0}
                    nextPage={users?.nextPage ?? 0}
                    currentPage={users?.currentPage ?? 0}
                    data={users?.list ?? []}
                    total={users?.total ?? 0}
                    lastPage={users?.lastPage ?? 0}
                    isLoading={loadingUsers}
                    header={<div className="w-full flex my-3 justify-end items-center gap-2">
                        <div>
                        <Filter
                            filters={filters}
                            data={users}
                            onFilter={(payload: UserFilterPayload) => {
                                setFilters(payload);
                            } } />
                        </div>
                        <div>
                            <AddUser refresh = {setRefresh}/>
                        </div>
                      
                    </div>} />
                <DeleteUserModal
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete User'} />
            </div></>
    );
}

type FilterProps = {
    filters: UserFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<UserType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        channel: z.string().optional(),
    });
    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
    };
    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, to, channel } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (channel) searchParams.set('channel', channel);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };
    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };
    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<UserFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="flex flex-col justify-between gap-4 md:flex-row">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
            {/* <PrintModal
				title={"Print List of Events"}
				open={openPrint}
				setOpen={setOpenPrint}
				data={data}
			/> */}
        </>
    );
}
