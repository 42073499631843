import z from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import svg from '@/assets/aims_logo_2.png';
import svg2 from '@/assets/img2.jpg';
import CircularProgress from '@mui/material/CircularProgress';
import { RequestOtp, ResetPassword, useLoginUser } from '@/core/hooks/auth';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { useAppSelector } from '@/store';
import { useEffect, useRef, useState } from 'react';
import { storage } from '@/core/utils';
import { check_user } from '@/core/api/auth';
import { CheckUser } from '@/core/utils/UseUser';
import { addUser } from '@/store/slices/userslice';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';
const schema = z.object({
    password: z.string().min(1, 'Password required'),
});

export type CreatePasswordInput = z.TypeOf<typeof schema>;

export const ResetPasswordConfirm = () => {
  const {loadingReset,handleReset}=ResetPassword()
    const numberOfInputs = 6; 
    const [otp, setOtp] = useState<string[]>(
        new Array(numberOfInputs).fill('')
    );

    const inputRefs = useRef<HTMLInputElement[]>([]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const value = e.target.value;
        if (!isNaN(Number(value)) && value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (value && index < numberOfInputs - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };


    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number
    ) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setPageTitle(' Reset Password'));
    }, [dispatch]);

    
    return (
        <div>
           <div className=" flex h-[100vh]  flex-row justify-center px-6 py-6 dark:bg-[#060818] sm:px-10 overflow-none">
                <div className="relative flex w-full max-w-[1502px] flex-row justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[90vh] lg:flex-row lg:gap-10 xl:gap-0">
               <div className="relative flex w-full flex-col items-center justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
               <div className="flex w-full max-w-[440px] items-center justify-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
                            <Link to="/" className="">
                                <div className="md:ml-20  ml-10 w-full  lg:w-[50%] ">
                                   <img src={svg} alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="w-full max-w-[440px] lg:mt-16">
                            <div className="mb-10">
                                <h1 className="text-lg font-bold  text-primary md:text-2xl">
                                    Change Password
                                </h1>
                                <p className="text-md  leading-normal text-white-dark">
                                    Enter your OTP and new password
                                </p>
                            </div>

                            <Form<CreatePasswordInput, typeof schema>
                                schema={schema}
                                onSubmit={(payload: any) => {
                                    const otpString = otp.join('');
                                    payload.otp = otpString;
                                    handleReset(payload);
                                }}
                                className="mt-10"
                            >
                                {({ register, formState }) => (
                                    <>
                                        <div className="space-y-4">
                                            <div className="">
                                                <label
                                                    htmlFor="OTP"
                                                    className=" text-gray-600 dark:text-gray-400 "
                                                >
                                                    Verification code
                                                </label>
                                                <div className="flex justify-between gap-2 ">
                                                    {otp.map((digit, index) => (
                                                        <input
                                                            key={index}
                                                            ref={el =>
                                                                (inputRefs.current[
                                                                    index
                                                                ] =
                                                                    el as HTMLInputElement)
                                                            }
                                                            type="text"
                                                            value={digit}
                                                            onChange={e =>
                                                                handleChange(
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                            onKeyDown={e =>
                                                                handleKeyDown(
                                                                    e,
                                                                    index
                                                                )
                                                            }
                                                            maxLength={1}
                                                            className="form-input text-center"
                                                        />
                                                    ))}
                                                </div>
                                            </div>
                                            <InputField
                                                type="password"
                                                label="New Password "
                                                placeholder="********"
                                                isLoading={loadingReset}
                                                error={
                                                    formState.errors.password
                                                }
                                                registration={register(
                                                    'password'
                                                )}
                                                className="h-13"
                                            />
                                        </div>
                                        {loadingReset ? (
                                            <button
                                                type="submit"
                                                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase  text-white"
                                            >
                                                <CircularProgress
                                                    size={24}
                                                    sx={{ color: 'white' }}
                                                />
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                                            >
                                                change password
                                            </button>
                                        )}
                                    </>
                                )}
                            </Form>
                            <div className="mt-2 text-center capitalize dark:text-white ">
                                <Link
                                    to="/login"
                                    className=" text-primary underline transition hover:text-black dark:hover:text-white"
                                >
                                    Back To login
                                </Link>
                            </div>
                        </div>                 
                        <p className="absolute bottom-6 w-full text-center dark:text-white">
                            AIMS RIC {new Date().getFullYear()} All Rights
                            Reserved.
                        </p>
                    </div>
                    <div className=" website-agency-home">
       
       <div
         className="absolute inset-0 opacity-30 bg-cover bg-center bg-opacity-50 m-0"
         style={{ backgroundImage: `url(${svg2})` }}
       />
     
      
       <div className="website-agency-home-child" />
       <div className="welcome-to-aims-wrapper">
         <h1 className="welcome-to-aims">welcome to aims</h1>
       </div>
       <h1 className="explore-exciting-opportunities">{`Explore exciting opportunities and transform your future with AIMS. `}</h1>
       <div className="join-us-on">
         Join us on a journey of discovery and innovation.
       </div>
     </div>
             
                </div>
            </div>
        </div>
    );
};
