import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { add_language, delete_language, edit_language, get_languages, one_language } from '@/core/api/languages';
import { LanguagePayload, LanguageType } from '@/core/types/languages';
import { PaginationType } from '@/core/types/shared';
import { ExperienceType } from '@/core/types/experience';

export const GetLanguages = () => {
    const [loadingLanguages, setLoadingLanguages] = useState(false);
    const [languages, setLanguages]: any = useState<LanguageType[] | null>();

    const handleGetLanguages = async (query?: string) => {
        try {
            setLoadingLanguages(true);
            const data: PaginationType<LanguageType> = await get_languages(query);
            setLanguages(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingLanguages(false);
        }
    };

    return {
        loadingLanguages,
        languages,
        handleGetLanguages,
    };
};

export const GetMyLanguage = () => {
    const [loadingLanguage, setLoadingLanguage] = useState(false);
    const [language, setLanguage]: any = useState<LanguageType | null>();

    const handleGetLanguage = async (id?: any) => {
        try {
            setLoadingLanguage(true);
            const data: LanguageType = await one_language(id);
            setLanguage(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingLanguage(false);
        }
    };

    return {
        loadingLanguage,
        language,
        handleGetLanguage,
    };
};

export const EditLanguageInfo = () => {
    const [loadingEditLanguage, setLoadingEditLanguage] = useState(false);

    const handleEditLanguage = async (payload: LanguagePayload, id: string) => {
        try {
            setLoadingEditLanguage(true);
            await edit_language(payload, id);
            toast.success('Language Information updated');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditLanguage(false);
        }
    };

    return {
        handleEditLanguage,
        loadingEditLanguage,
    };
};

export const useCreateLanguages = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);

    const handleCreateLanguage = async (payload: LanguagePayload) => {
        try {
            setLoadingCreate(true);
            await add_language(payload);
            toast.success('Language information added');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreateLanguage,
    };
};

export const useDeleteLanguages = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);

    const handleDeleteLanguage = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_language(id);
            toast.success('Language information deleted');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDeleteLanguage,
    };
};
