import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
    CheckCircleIcon,
    DocumentChartBarIcon,
    ExclamationCircleIcon,
    FunnelIcon,
    UsersIcon,
} from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import {
    AdminDashboard,
    GetApplications,
    GetNationalityStats,
    GetStatistics,
} from '@/core/hooks/application';
import { UserFilterPayload, UserType } from '@/core/types/auth';
import {
    useGetEvaluations,
    useGetEvaluators,
    useGteEvaluationStats,
    useGteStatisticsEvaluator,
} from '@/core/hooks/evaluators';
import { EvaluationType } from '@/core/types/evaluation';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import IconEye from '@/components/Icon/IconEye';
import { InputField, SelectField } from '@/components/input';
import { PaginationType } from '@/core/types/shared';
import { z } from 'zod';
import Button from '@/components/button';
import Modal from '@/components/modal';
import { Form } from '@/components/form';
import IconChecks from '@/components/Icon/IconChecks';
import { countries } from 'countries-list';
import { More } from '../modulators/application_decisions/more';
import AdminStats from './adminStats';

export default function DashboardCards() {
    const { userAccount: user }: any = useAppSelector(store => store.user);
    const { statistics, handleGetApplications } = GetStatistics();

    const { statistics: adminDash, handleGetStatistics } = AdminDashboard();

    useEffect(() => {
        if (user.role === 'admin') {
            handleGetStatistics();
        }

        handleGetApplications();
    }, []);

    const renderWarningCard = (title: string, value: number) => {
        const toPath =
            title.toLowerCase() === 'personal information'
                ? 'profile'
                : `profile`;

        if (value !== 100) {
            return (
                <Link to={toPath}>
                    <WarningCard title={title} value="Not filled" />
                </Link>
            );
        }

        return (
            <Link to={toPath}>
                <CheckedCard title={title} />
            </Link>
        );
    };
    console.log(adminDash);

    return (
        <>
            <div className=" ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <p className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                            Dashboard
                        </p>
                    </li>
                </ol>
            </div>
            <div className="w-full flex flex-col justify-start items-start border-gray-200 rounded-t-lg mt-4  px-3">
                {user.role === 'applicant' && (
                    <>
                        <p className="text-lg dark:text-white-light font-bold my-2">
                            Profile Information
                        </p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 w-full">
                            {renderWarningCard('Address', statistics?.address)}
                            {renderWarningCard(
                                'Reference',
                                statistics?.reference
                            )}
                            {renderWarningCard(
                                'Academic ',
                                statistics?.academic
                            )}
                            {renderWarningCard(
                                'Experiences',
                                statistics?.experience
                            )}
                            {renderWarningCard(
                                'Language',
                                statistics?.language
                            )}
                            {renderWarningCard(
                                'Personal ',
                                statistics?.personalInfo
                            )}
                        </div>
                    </>
                )}
                {user?.role === 'admin' && <AdminStats />}

                {user?.role === 'modulator' && (
                    <div>
                        <Decisions />
                    </div>
                )}
                {user?.role === 'academic_manager' && (
                    <div className="w-full">
                        <Decisions2 />
                    </div>
                )}

                {user?.role === 'evaluator' && (
                    <div className="w-full">
                        <StausCardEvaluator />
                    </div>
                )}
            </div>
        </>
    );
}

function CheckedCard({ title }: any) {
    return (
        <DashCard
            bgColor="bg-green-500"
            title={title}
            value="Filled"
            icon={
                <CheckCircleIcon className="bg-green-500 text-white rounded-full " />
            }
        />
    );
}

function WarningCard({ title }: any) {
    return (
        <DashCard
            bgColor="bg-warning"
            title={title}
            value="Not filled"
            icon={
                <ExclamationCircleIcon className="bg-warning text-white  rounded-full" />
            }
        />
    );
}

type DashCardProps = {
    icon: JSX.Element;
    bgColor: any;
    title: string;
    value: any;
    children?: React.ReactNode;
};

function DashCard(props: DashCardProps) {
    return (
        <div className={`panel h-20 w-full p-0   `}>
            <div className="flex p-5">
                <div className="shrink-0 text-white   w-11 h-11 flex justify-center items-center ">
                    {props.icon}
                </div>
                <div className="ltr:ml-3 rtl:mr-3 font-semibold">
                    <p className="text-xl text-primary dark:text-gray-200 ">
                        {props.title}
                    </p>
                    <h5 className="text-gray-700   dark:text-gray-400  text-xl">
                        {props.value}
                    </h5>
                </div>
            </div>
        </div>
    );
}

function StausCardEvaluator() {
    const [applicationId, setApplicationId]= useState<any>({})
    const { loadingEvaluators, handleGetEvaluators, evaluators } =
        useGetEvaluators();
        const { loadingApplications, handleGetApplications, applications } =
        GetApplications();
    const {
        loadingEvaluationsstats,
        evaluationsstats,
        handleGetEvaluationStatus,
    } = useGteEvaluationStats();

    const query = location.search;
    useEffect(() => {
        handleGetApplications(query)
        handleGetEvaluators(query);
       
    }, []);
    useEffect(() => {
        handleGetEvaluationStatus(applicationId);
    }, [applicationId])
    
    return (
        <>
         <div>
            <div className='flex flex-col justify-start  w-1/4 my-3'>
            <label htmlFor="" className=' text-lg font-bold  text-gray-700'>
                Select Application
            </label>
            <SelectField
                        placeholder="Select application"
                        isLoading={loadingApplications}
                     
                        options={(applications?.list || []).map(
                            (app: { id: any; title: any }) => ({
                                value: app?.id,
                                text: app?.title,
                            })
                        )}
                        onChange={e => {
                            setApplicationId(e.target.value);
                        }}
                    />
            </div>
                   
                </div>
            <div className="grid    grid-cols-1 sm:grid-cols-2 md:grid-cols-3   gap-4 W-full">
                <DashCard
                    bgColor="bg-warning"
                    title="Assigned Applications"
                    value={evaluationsstats?.evaluation ?? 0}
                    icon={
                        <ExclamationCircleIcon className="bg-warning text-white m-1 rounded-full " />
                    }
                />
                <DashCard
                    bgColor="bg-warning"
                    title="Evaluated applications"
                    value={ evaluationsstats?.evaluated?? 0}
                    icon={
                        <IconChecks className="bg-success text-white m-1   rounded-full " />
                    }
                />

                <DashCard
                    bgColor="bg-warning"
                    title="Evaluated weak"
                    value={evaluationsstats?.weakEvaluation ?? 0}
                    icon={
                        <ExclamationCircleIcon className="bg-danger text-white m-1 rounded-full " />
                    }
                />
                <DashCard
                    bgColor="bg-warning"
                    title="Evaluated medium"
                    value={evaluationsstats?.mediumEvaluation ?? 0}
                    icon={
                        <IconChecks className="bg-warning text-white   m-1 rounded-full " />
                    }
                />
                <DashCard
                    bgColor="bg-warning"
                    title="Evaluated Strong"
                    value={evaluationsstats?.strongEvaluation ?? 0}
                    icon={
                        <IconChecks className="bg-success text-white m-1 rounded-full " />
                    }
                />
            </div>
        </>
    );
}

function Decisions() {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<UserType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<UserFilterPayload>({
        from: '',
        to: '',
        country: '',
        gender: '',
    });
    const location = useLocation();
    const query = location.search;
    const navigate = useNavigate();
    const { loadingEvaluations, handleGetEvaluations, evaluations } =
        useGetEvaluations();

    useEffect(() => {
        handleGetEvaluations(query);
    }, [query, refresh]);

    const columns: TableColumnV2<EvaluationType>[] = [
        {
            title: 'View',
            accessor: 'actions',
            render: row => (
                <p className="flex flex-row justify-between space-x-2">
                    <button
                        onClick={() => {
                            navigate(
                                `/account/modulator/user_details/step3/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`
                            );
                        }}
                        className="btn btn-primary btn-sm "
                    >
                        <IconEye />
                    </button>
                </p>
            ),
        },
        {
            title: ' Applicant Code',
            accessor: 'submittedApplication.applicant_code',
            render: row => (
                <p>{row.submittedApplication?.applicant_code || '-'} </p>
            ),
        },
        {
            title: 'Applicant Name',
            accessor: 'submittedApplication.user.firstName',
            render: row => (
                <Link
                    to={`/account/modulator/user_details/step3/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`}
                    className="hover:underline"
                >
                    {row.submittedApplication?.user?.firstName || '-'}{' '}
                    {row.submittedApplication?.user?.lastName || '-'}
                </Link>
            ),
        },  {
            title: 'Gender',
            accessor: 'row.submittedApplication?.user?.personalInformation[0]?.gender',
            render: row => (
                <p className='capitalize'>
                    {row.submittedApplication?.user?.personalInformation[0]?.gender}
                </p>
            ),
        },
        {
            title: 'Country',
            accessor: 'row.submittedApplication?.user?.personalInformation[0]?.Nationality',
            render: row => (
                <p>
                    {row.submittedApplication?.user?.personalInformation[0]?.Nationality}
                </p>
            ),
        },
        {
            title: 'Highest Degree',
            accessor: 'levelOfStudy',
            render: row => (
                <p>
                    {row.submittedApplication.user.academicHistories
                        .find(history => history.highest_qualification)
                        ?.levelOfStudy?.toUpperCase() || '-'}
                </p>
            ),
        },

        {
            title: 'Evaluator One decision',
            accessor: 'evaluator_one_marks',
            render: row => <p>{row.evaluator_one_marks || '-'}</p>,
        },

        {
            title: 'Evaluator Two decision',
            accessor: 'evaluator_two_marks',
            render: row => <p>{row.evaluator_two_marks || '-'}</p>,
        },
        {
            title: 'Evaluator Three decision',
            accessor: 'evaluator_three_marks',
            render: row => (
                <>
                    {row?.evaluator_three_marks !== undefined ? (
                        <p className="text-black">
                            {row?.evaluator_three_marks ?? '-'}
                        </p>
                    ) : (
                        <p>No evaluator 3 </p>
                    )}
                </>
            ),
        },
        {
            title: 'Academic Manager Name',
            accessor: 'academicManager.lastName',
            render: row => (
                <>
                    {row?.academicManager !== null ? (
                        <p className="capitalize">
                            {(row.academicManager.lastName || '-') +
                                ' ' +
                                (row.academicManager.firstName || '-')}
                        </p>
                    ) : (
                        <p>No Academic Manager </p>
                    )}
                </>
            ),
        },

        {
            title: 'Academic Manager decision',
            accessor: 'academic_manager_decision',
            render: row => (
                <div
                    className={`badge w-fit ${
                        row?.academic_manager_decision === 'reject'
                            ? 'bg-danger'
                            : row?.academic_manager_decision === 'accept'
                            ? 'bg-success'
                            : ''
                    }`}
                >
                    <p>
                        {row?.academic_manager_decision === 'reject'
                            ? 'Rejected'
                            : row?.academic_manager_decision === 'accept'
                            ? 'Accepted'
                            : '-'}
                    </p>
                </div>
            ),
        },

        {
            title: 'Evaluator One Name',
            accessor: 'evaluatorOne.lastName',
            render: row => (
                <p className="capitalize">
                    {(row.evaluatorOne.lastName || '-') +
                        ' ' +
                        (row.evaluatorOne.firstName || '-')}
                </p>
            ),
        },

        {
            title: 'Evaluator Two Name',
            accessor: 'evaluatorTwo.lastName',
            render: row => (
                <p className="capitalize">
                    {(row.evaluatorTwo.lastName || '-') +
                        ' ' +
                        (row.evaluatorTwo.firstName || '-')}
                </p>
            ),
        },
        {
            title: 'Evaluator Three Name',
            accessor: 'evaluatorThree.lastName',
            render: row => (
                <>
                    {row?.evaluatorThree !== null ? (
                        <p className="capitalize">
                            {(row.evaluatorThree.lastName || '-') +
                                ' ' +
                                (row.evaluatorThree.firstName || '-')}
                        </p>
                    ) : (
                        <p>No evaluator 3 </p>
                    )}
                </>
            ),
        },
        {
            title: 'Academic Manager Name',
            accessor: 'academicManager.lastName',
            render: row => (
                <>
                    {row?.academicManager !== null ? (
                        <p className="capitalize">
                            {(row.academicManager.lastName || '-') +
                                ' ' +
                                (row.academicManager.firstName || '-')}
                        </p>
                    ) : (
                        <p>No Academic Manager </p>
                    )}
                </>
            ),
        },
        {
            title: 'Application Name',
            accessor: 'submittedApplication.application.title',
            render: row => (
                <p>{row.submittedApplication.application.title || '-'}</p>
            ),
        },
        {
            title: 'Evaluation status',
            accessor: 'status',
            render: row => {
                let badgeColor;
                switch (row?.status) {
                    case 'approved':
                        badgeColor = 'success';
                        break;
                    case 'reject':
                        badgeColor = 'danger';
                        break;
                    case 'assigned':
                        badgeColor = 'blue';
                        break;
                    case 'pending':
                        badgeColor = 'warning';
                        break;
                    default:
                        badgeColor = 'gray';
                }
                return (
                    <span className={` capitalize badge bg-${badgeColor}`}>
                        {row.status}
                    </span>
                );
            },
        },
        {
            title: 'Action',
            accessor: 'actions',
            render: row => (
                <p className="flex flex-row justify-between space-x-2">
                    <More evaluation={row} />
                </p>
            ),
        },
    ];

    return (
        <>
            <div className="my-4 ">
                <p className="text-lg font-bold "> Evaluation Decision </p>
            </div>
            <div className="">
                <DataTableV2
                    tableName="Evaluation decision"
                    columns={columns}
                    previousPage={evaluations?.previousPage ?? 0}
                    nextPage={evaluations?.nextPage ?? 0}
                    currentPage={evaluations?.currentPage ?? 0}
                    data={evaluations?.list ?? []}
                    total={evaluations?.total ?? 0}
                    lastPage={evaluations?.lastPage ?? 0}
                    isLoading={loadingEvaluations}
                    header={
                        <div className="w-full flex my-3 justify-end items-center">
                            <Filter
                                filters={filters}
                                data={evaluations}
                                onFilter={(payload: UserFilterPayload) => {
                                    setFilters(payload);
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </>
    );
}

type FilterProps = {
    filters: UserFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<UserType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        country: z.string().optional(),
        gender: z.string().optional(),
    });
    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
        country: '',
        gender: '',
    };

    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);
        const { from, to, country, gender } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (country) searchParams.set('country', country);
        if (gender) searchParams.set('gender', gender);
        console.log(payload);
        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };

    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };

    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));

    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<UserFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="flex flex-col justify-between gap-4 ">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />

                                <SelectField
                                    placeholder="Select Country"
                                    label="Country"
                                    registration={register('country')}
                                    options={countryOptions}
                                />

                                <SelectField
                                    placeholder="Select Gender"
                                    label="Gender"
                                    registration={register('gender')}
                                    options={[
                                        { value: 'male', text: 'Male' },
                                        { value: 'female', text: 'Female' },
                                    ]}
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
}

function Decisions2() {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<UserType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<UserFilterPayload>({
        from: '',
        to: '',
        country: '',
        gender: '',
    });
    const location = useLocation();
    const query = location.search;
    const navigate = useNavigate();
    const { loadingEvaluations, handleGetEvaluations, evaluations } =
        useGetEvaluations();

    useEffect(() => {
        handleGetEvaluations(query);
    }, [query, refresh]);

    const columns: TableColumnV2<EvaluationType>[] = [
        {
            title: 'View',
            accessor: 'actions',
            render: row => (
                <p className="flex flex-row justify-between space-x-2">
                    <button
                        onClick={() => {
                            navigate(
                                `/account/academic_manager/user_details/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`
                            );
                        }}
                        className="btn btn-primary btn-sm "
                    >
                        <IconEye />
                    </button>
                </p>
            ),
        },
        {
            title: ' Applicant Code',
            accessor: 'submittedApplication.applicant_code',
            render: row => (
                <p>{row.submittedApplication?.applicant_code || '-'} </p>
            ),
        },
        {
            title: 'Applicant Name',
            accessor: 'submittedApplication.user.firstName',
            render: row => (
                <Link
                    to={`/account/academic_manager/user_details/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`}
                    className="hover:underline"
                >
                    {row.submittedApplication?.user?.firstName || '-'}{' '}
                    {row.submittedApplication?.user?.lastName || '-'}
                </Link>
            ),
        },

        {
            title: 'Application Name',
            accessor: 'submittedApplication.application.title',
            render: row => (
                <p>{row.submittedApplication.application.title || '-'}</p>
            ),
        },

        {
            title: 'Academic Manager decision',
            accessor: 'academic_manager_decision',
            render: row => (
                <div
                    className={`badge w-fit ${
                        row?.academic_manager_decision === 'reject'
                            ? 'bg-danger'
                            : row?.academic_manager_decision === 'accept'
                            ? 'bg-success'
                            : ''
                    }`}
                >
                    <p>
                        {row?.academic_manager_decision === 'reject'
                            ? 'Rejected'
                            : row?.academic_manager_decision === 'accept'
                            ? 'Accepted'
                            : '-'}
                    </p>
                </div>
            ),
        },
        {
            title: 'Evaluation status',
            accessor: 'status',
            render: row => {
                let badgeColor;
                switch (row?.status) {
                    case 'approved':
                        badgeColor = 'success';
                        break;
                    case 'reject':
                        badgeColor = 'danger';
                        break;
                    case 'assigned':
                        badgeColor = 'blue';
                        break;
                    case 'pending':
                        badgeColor = 'warning';
                        break;
                    default:
                        badgeColor = 'gray';
                }
                return (
                    <span className={` capitalize badge bg-${badgeColor}`}>
                        {row.status}
                    </span>
                );
            },
        },
    ];

    return (
        <>
            <div className="w-full ">
                <p className="text-lg font-bold "> Academic decisions </p>
            </div>
            <div className="w-full">
                <DataTableV2
                    tableName="Evaluation decision"
                    columns={columns}
                    previousPage={evaluations?.previousPage ?? 0}
                    nextPage={evaluations?.nextPage ?? 0}
                    currentPage={evaluations?.currentPage ?? 0}
                    data={evaluations?.list ?? []}
                    total={evaluations?.total ?? 0}
                    lastPage={evaluations?.lastPage ?? 0}
                    isLoading={loadingEvaluations}
                    header={
                        <div className="w-full flex my-3 justify-end items-center">
                            <Filter
                                filters={filters}
                                data={evaluations}
                                onFilter={(payload: UserFilterPayload) => {
                                    setFilters(payload);
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </>
    );
}
