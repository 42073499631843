import Button from '@/components/button';
import Modal from '@/components/modal';
import {
    GetSubmittedUserDetails,
    getUserSubmittedDetails,
} from '@/core/hooks/application';
import { EvaluationStepOne, useGetEvaluations } from '@/core/hooks/evaluators';
import { Getfiless } from '@/core/hooks/file';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function ReviewUserStep1() {
    const { userId }: any = useParams();
    const { rowId }: any = useParams();
    
    const { applicationId }: any = useParams();
    const navigate = useNavigate();
    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [comment, setComments] = useState<string>('');
    const [error, setError] = useState<string>('');

    const { loadingApplications, handleGetApplications, applications } =
        getUserSubmittedDetails();

    const { handleGetEvaluations, evaluations } = useGetEvaluations();
    const { loadingUserdata, handleGetUserInfo, usersInfo } =
        GetSubmittedUserDetails();


    const { loadingEvaluaete, handleEvaluete } = EvaluationStepOne();
    const [marks, setMarks] = useState<string | any>('');

    const { loadingfiless, filess, handleGetfiless } = Getfiless();

    const query = location.search;
    const PageSize = 1000000;
    const updatePageSizeInQuery = (query: string): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('userId', userId);
        return '?' + urlParams.toString();
    };


    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query);
        handleGetApplications(userId);
        handleGetfiless(updatedQuery);
        handleGetUserInfo(userId);
        handleGetEvaluations();
    }, []);

    if (loadingApplications) {
        return (
            <div className="flex flex-row justify-center">
                <CircularProgress style={{ color: 'black' }} />
            </div>
        );
    }

    const handleMarkApplication = async () => {
        if (!comment || !marks) {
            setError(
                'Please provide   decison  and reasons before submitting.'
            );
            return;
        }

        setConfirmationOpen(true);
    };
    const application = applications?.applications.find(
        (app: any) => app.id === applicationId
    );

    const filteredFiles = filess?.list?.filter(
        (file: any) => file.user.id === userId
    );

    return (
        <>
            <div className="panel w-full   p-5 ">
                <div className=" border border-primary  ">
                    <div className="panel mx-w-lg">
                        <div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                            Attached Files
                        </div>
                        {filess &&
                            filess?.list.map((file: any, index: number) => (
                                <a
                                    key={file.id}
                                    href={file.file_link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-500 font-semibold hover:underline capitalize"
                                >
                                    <p className="py-2">
                                        {index + 1}.{' '}
                                        <span className="text-gray-600">
                                            {file.fileType.name}
                                        </span>
                                    </p>
                                </a>
                            ))}
                    </div>
                </div>
                
                {application && (
                    <div className="  my-5">
                        <AnswersAnswered application={application} />
                    </div>
                )}

                <div className="flex flex-col justify-between space-y-4">
                    <div className="flex flex-row justify-start space-x-3 items-center">
                        <p className="font-semibold text-medium">
                            Your decision :
                        </p>
                        <select
                            name=""
                            id=""
                            className="form-select"
                            onChange={e => setMarks(e.target.value)}
                            value={marks}
                        >
                            <option value="">select your opinion</option>
                            <option value="strong">Strong</option>
                            <option value="medium">Medium</option>
                            <option value="weak">Weak</option>
                        </select>
                    </div>

                    <div className="flex flex-row justify-start space-x-3 items-center">
                        <p className="font-semibold text-medium">
                            Your Reason :
                        </p>
                        <textarea
                            className="form-textarea"
                            placeholder="Reasons"
                            onChange={e => setComments(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex flex-row justify-end">
                    {error && <p className="text-danger text-sm">{error}</p>}
                </div>

                <div className="flex mt-4 flex-row justify-end">
                    <button
                        className="btn btn-primary"
                        onClick={handleMarkApplication}
                    >
                        Submit
                    </button>
                </div>
            </div>
            <ConfirmationModal
                open={confirmationOpen}
                setOpen={setConfirmationOpen}
                onConfirm={async () => {
                    const res: any = { marks, comment };

                    await handleEvaluete(rowId, res);
                    navigate(`/account/evaluator/evaluation/evaluate/${applicationId}`);
                    setConfirmationOpen(false);
                }}
            />
        </>
    );
}

export default ReviewUserStep1;


function AnswersAnswered({ application }: any) {
    const { topicId }: any = useParams();

    console.log(topicId === 'notopic');

    return (
        <div>
            <div className="border border-primary mt-3">
                <div className="uppercase text-lg font-bold p-2 bg-primary flex flex-row justify-center text-white">
                    Application Details
                </div>
                <div className="p-3">
                    <h1 className="text-xl font-bold mb-4 text-primary">
                        {application?.title}
                    </h1>

                    <div
                        className="mt-3"
                        dangerouslySetInnerHTML={{
                            __html: application.description || '',
                        }}
                    />

                    {topicId !== 'notopic'
                        ? application.topics
                              .filter((topic: any) => topic.id === topicId)
                              .map((topic: any) => (
                                  <div key={topic.id}>
                                      <p className="text-lg font-bold">Topic</p>
                                      <div>
                                          <h3 className="text-lg font-bold mb-2 mx-2 text-gray-800 dark:text-gray-400">
                                              Name:{' '}
                                              <span className="font-normal">
                                                  {topic.name}
                                              </span>
                                          </h3>
                                          <p className="mx-2 font-bold text-lg text-gray-800 dark:text-gray-400">
                                              Description:{' '}
                                              <span className="font-normal">
                                                  {topic.description}
                                              </span>
                                          </p>
                                      </div>
                                      <div>
                                          <p className="my-2 text-lg font-bold">
                                              Questions
                                          </p>
                                          <div className="flex flex-col space-y-4">
                                              {topic.questions?.map(
                                                  (
                                                      question: any,
                                                      index: number
                                                  ) => (
                                                      <div
                                                          key={question.id}
                                                          className="flex flex-col items-start space-y-2"
                                                      >
                                                          <div className="flex flex-row">
                                                              <div className="bg-gray-200 p-3 rounded-lg max-w-md">
                                                                  <div>
                                                                      <p className="capitalize">
                                                                          <span className="text-lg font-bold">
                                                                              {index +
                                                                                  1}
                                                                              .
                                                                          </span>{' '}
                                                                          {
                                                                              question.title
                                                                          }
                                                                      </p>
                                                                      <p className="ml-4">
                                                                          {
                                                                              question.description
                                                                          }
                                                                      </p>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <div className="ml-4">
                                                              <div className="font-bold">
                                                                  <p className="">
                                                                      Answer
                                                                  </p>
                                                              </div>
                                                              {question.answers?.map(
                                                                  (
                                                                      answer: any
                                                                  ) => (
                                                                      <div
                                                                          key={
                                                                              answer.id
                                                                          }
                                                                          className="flex mt-1 flex-col items-end"
                                                                      >
                                                                          <div className="bg-gray-100 p-3 rounded-lg">
                                                                              <p className="">
                                                                                  {
                                                                                      answer.answer
                                                                                  }
                                                                              </p>
                                                                          </div>
                                                                      </div>
                                                                  )
                                                              )}
                                                          </div>
                                                      </div>
                                                  )
                                              )}
                                          </div>
                                      </div>
                                  </div>
                              ))
                        : application.topics.map((topic: any) => (
                              <div key={topic.id}>
                                  <p className="text-lg font-bold">Topic</p>
                                  <div>
                                      <h3 className="text-lg font-bold mb-2 mx-2 text-gray-800 dark:text-gray-400">
                                          Name:{' '}
                                          <span className="font-normal">
                                              {topic.name}
                                          </span>
                                      </h3>
                                      <p className="mx-2 font-bold text-lg text-gray-800 dark:text-gray-400">
                                          Description:{' '}
                                          <span className="font-normal">
                                              {topic.description}
                                          </span>
                                      </p>
                                  </div>
                                  <div>
                                      <p className="my-2 text-lg font-bold">
                                          Questions
                                      </p>
                                      <div className="flex flex-col space-y-4">
                                          {topic.questions?.map(
                                              (
                                                  question: any,
                                                  index: number
                                              ) => (
                                                  <div
                                                      key={question.id}
                                                      className="flex flex-col items-start space-y-2"
                                                  >
                                                      <div className="flex flex-row">
                                                          <div className="bg-gray-200 p-3 rounded-lg max-w-md">
                                                              <div>
                                                                  <p className="capitalize">
                                                                      <span className="text-lg font-bold">
                                                                          {index +
                                                                              1}
                                                                          .
                                                                      </span>{' '}
                                                                      {
                                                                          question.title
                                                                      }
                                                                  </p>
                                                                  <p className="ml-4">
                                                                      {
                                                                          question.description
                                                                      }
                                                                  </p>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="ml-4">
                                                          <div className="font-bold">
                                                              <p className="">
                                                                  Answer
                                                              </p>
                                                          </div>
                                                          {question.answers?.map(
                                                              (
                                                                  answer: any
                                                              ) => (
                                                                  <div
                                                                      key={
                                                                          answer.id
                                                                      }
                                                                      className="flex mt-1 flex-col items-end"
                                                                  >
                                                                      <div className="bg-gray-100 p-3 rounded-lg">
                                                                          <p className="">
                                                                              {
                                                                                  answer.answer
                                                                              }
                                                                          </p>
                                                                      </div>
                                                                  </div>
                                                              )
                                                          )}
                                                      </div>
                                                  </div>
                                              )
                                          )}
                                      </div>
                                  </div>
                              </div>
                          ))}
                    
                    <div className="mt-4">
                        {application?.suggestedTopics.length > 0 && (
                            <p className="text-lg font-bold">
                                Suggested Topics
                            </p>
                        )}
                        {application?.suggestedTopics?.map(
                            (topic: any, index: any) => (
                                <div key={index}>
                                    <div className="mt-2">
                                        <h3 className="text-lg font-bold mx-2 text-gray-800">
                                            {index + 1}. name:{' '}
                                            <span>{topic?.title}</span>
                                        </h3>
                                        <p className="mx-2 font-bold text-md text-gray-600">
                                            Description{' '}
                                            <span className="font-normal">
                                                {topic?.description}
                                            </span>
                                        </p>
                                    </div>
                                    <div>
                                        <div className="flex flex-col px-4">
                                            <div className="text-lg font-bold">
                                                <p>Skills Needed</p>
                                            </div>

                                            <div className="flex flex-wrap mt-2">
                                                {topic?.skills_needed?.map(
                                                    (skill: any) => (
                                                        <div
                                                            key={skill?.name}
                                                            className="bg-primary text-white py-1 px-2 rounded-full mr-2 mb-2"
                                                        >
                                                            # {skill?.name}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
const ConfirmationModal = ({ open, setOpen, onConfirm }: any) => {
    return (
        <Modal show={open} setShow={setOpen} title="Confirm Submission">
            <p>Are you sure you want to submit this decision</p>
            <div className="flex gap-3  justify-end">
                <Button onClick={() => setOpen(false)} background="bg-gray-500">
                    Cancel
                </Button>
                <Button onClick={onConfirm}>Submit</Button>
            </div>
        </Modal>
    );
};
