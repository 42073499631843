import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import { z } from 'zod';
import { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { FunnelIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import { GetApplications } from '@/core/hooks/application';
import { GetTopics } from '@/core/hooks/topics';
import { TopicType, topicFilterPayload } from '@/core/types/topics';
import { More } from './modal/More';
import { UpdateTopicInfo } from './modal/edit_topic';
import DeleteTopic from './modal/delete_topic';
import AddTopic from './modal/create_topic';
import DataTablev3 from '@/components/datatable/Datatable2';
import { capitalize } from '@mui/material';
import { PaginationType } from '@/core/types/shared';
import Modal from '@/components/modal';
import Questions from '../questions';

export default function Topics() {
    const { applicationId }: any = useParams();
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<TopicType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const [filters, setFilters] = useState<topicFilterPayload>({
        from: '',
        to: '',
        applicationId: '',
    });
    const query = location.search;
    const { userAccount: user }: any = useAppSelector(store => store.user);
    console.log(user);
    const { loadingTopics, handleGetTopics, topics } = GetTopics();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    const updatePageSizeInQuery = (
        query:
            | string
            | string[][]
            | Record<string, string>
            | URLSearchParams
            | undefined,
        applicationID: number
    ) => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('applicationId', applicationId);
        return '?' + urlParams.toString();
    };

    const PageSize = 1000000;

    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, applicationId);
        handleGetTopics(updatedQuery);
    }, [query, refresh]);
    const columns: TableColumnV2<TopicType>[] = [
        {
            title: 'Name',
            accessor: 'name',
            render: row => (
                <p className="capitalize">{capitalize(row?.name)}</p>
            ),
        },
        {
            title: 'Description',
            accessor: 'description',
            render: row => (
                <p className="whitespace-wrap">
                    {row.description.length > 50
                        ? `${row.description.slice(0, 50)}...`
                        : row.description}
                </p>
            ),
        },
        {
            title: 'Type',
            accessor: 'type',
            render: row => <p>{capitalize(row?.type)}</p>,
        },
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p>
                    {row?.status === '1' || row?.status === 'active' ? (
                        <span className="badge bg-success">Active</span>
                    ) : (
                        <span className="badge bg-danger">Inactive</span>
                    )}
                </p>
            ),
        },
        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full"
                    >
                        <TrashIcon className="w-4" />
                    </button>

                    <More topic={row} refresh={setRefresh} />
                    <UpdateTopicInfo topicInfo={row} refresh={setRefresh} />
                    <Questions topicId = {row.id}/>
                </div>
            ),
        },
    ];

    const activeTopics = topics?.list?.filter(
        (topic: TopicType) => topic.status === 'active'
    );

    return (

        <div className="panel py-4  mt-4 ">
	<div className="my-4 " >
			<p className="text-lg font-bold "> Topics  </p>
		</div>
            <div className="">
                <DataTablev3
                    columns={columns}
                    previousPage={topics?.previousPage ?? 0}
                    nextPage={topics?.nextPage ?? 0}
                    currentPage={topics?.currentPage ?? 0}
                    data={activeTopics}
                    total={topics?.total ?? 0}
                    lastPage={topics?.lastPage ?? 0}
                    isLoading={loadingTopics}
                    header={
                        <div className="flex flex-row justify-end gap-3  mb-2  ">
                            <AddTopic refresh={setRefresh} />

                            <Filter
                                filters={filters}
                                data={topics}
                                onFilter={(payload: topicFilterPayload) => {
                                    setFilters(payload);
                                }}
                            />
                        </div>
                    }
                />
                <DeleteTopic
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete Topic'}
                />
            </div>
        </div>
    );
}

type FilterProps = {
    filters: topicFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<TopicType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const { loadingApplications, handleGetApplications, applications } =
        GetApplications();

    const query = location.search;
    console.log(query);
    useEffect(() => {
        handleGetApplications(query);
    }, [query]);

    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        applicationId: z.string().optional(),
    });

    const defaultFilters: topicFilterPayload = {
        from: '',
        to: '',
        applicationId: '',
    };

    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, to, applicationId } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (applicationId) searchParams.set('applicationId', applicationId);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };

    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };
    return (
        <>
            <div className=" justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<topicFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="flex flex-col justify-between">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />
                                
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
}
