import { FunnelIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { UserFilterPayload, UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import Button from '@/components/button';
import Modal from '@/components/modal';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { useGetEvaluators } from '@/core/hooks/evaluators';

import DeleteUserModal from '../../users/modals/delete_modal';
import { UpdateEvaluator } from './modal';
import { AssignApplicationEvaluator } from './modal/Assing';

export default function DashEvaluators() {
    const {applicationId}:any= useParams();
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<UserType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<UserFilterPayload>({
        from: '',
        to: '',
    });
    const location = useLocation();
    const query = location.search;

    const { loadingEvaluators, handleGetEvaluators, evaluators } =
        useGetEvaluators();

        const updatePageSizeInQuery = () => {
            const urlParams = new URLSearchParams(query);
            
            urlParams.set('applicationId', applicationId);
            return '?' + urlParams.toString();
        };


    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery();
        handleGetEvaluators(updatedQuery);
    }, [query, refresh]);


    const columns: TableColumnV2<UserType>[] = [
        {
            title: 'First Name',
            accessor: 'firstName',
            render: row => <p className="capitalize">{row.firstName}</p>,
        },
        {
            title: 'Last Name',
            accessor: 'lastName',
            render: row => <p className="capitalize ">{row.lastName}</p>,
        },
        {
            title: 'Email',
            accessor: 'email',
            render: row => <p>{row.email}</p>,
        },

        {
            title: 'Phone Number',
            accessor: 'phoneNumber',
            render: row => <p>{row.phoneNumber}</p>,
        },
        {
            title: 'Assigned Applications',
            accessor: 'phoneNumber',
            render: row => <p>{row.totalEvaluationsAssigned}</p>,
        },

        {
            title: 'Evaluated Application',
            accessor: 'phoneNumber',
            render: row => <p>{row.totalEvaluationsCompleted}</p>,
        },

        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p>
                    {row?.status === '1' || row?.status === 'active' ? (
                        <span className="badge bg-success">Active</span>
                    ) : (
                        <span className="badge bg-danger">Inactive</span>
                    )}
                </p>
            ),
        },
        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>
                    <UpdateEvaluator user={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];
    return (
        <>
            <div className="my-4 ">
                <p className="text-lg font-bold "> Evaluators </p>
            </div>
            <div className="">
                <DataTableV2
                    columns={columns}
                    tableName="Evaluators"
                    previousPage={evaluators?.previousPage ?? 0}
                    nextPage={evaluators?.nextPage ?? 0}
                    currentPage={evaluators?.currentPage ?? 0}
                    data={evaluators?.list ?? []}
                    total={evaluators?.total ?? 0}
                    lastPage={evaluators?.lastPage ?? 0}
                    isLoading={loadingEvaluators}
                    header={
                        <div className="w-full flex my-3  space-x-2 justify-end items-center">
                            <div>
                                <Filter
                                    filters={filters}
                                    data={evaluators}
                                    onFilter={(payload: UserFilterPayload) => {
                                        setFilters(payload);
                                    }}
                                />
                            </div>

                            <div>
                                <AssignApplicationEvaluator />
                            </div>
                        </div>
                    }
                />
                <DeleteUserModal
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete User'}
                />
            </div>
        </>
    );
}

type FilterProps = {
    filters: UserFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<UserType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        channel: z.string().optional(),
    });
    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
    };
    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, to, channel } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (channel) searchParams.set('channel', channel);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };
    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };
    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<UserFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="flex flex-col justify-between gap-4 md:flex-row">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
}
