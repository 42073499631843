import { FunnelIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { ModFilter, UserFilterPayload, UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import Button from '@/components/button';
import Modal from '@/components/modal';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { useGetEvaluations, useGetEvaluators } from '@/core/hooks/evaluators';
import { countries } from 'countries-list';
import { EvaluationType } from '@/core/types/evaluation';
import IconEye from '@/components/Icon/IconEye';
import { More } from './more';
import { useAppSelector } from '@/store';

const transformStatusToDisplayText = (text: string) => {
    const words = text.split('_');
    const capitalizedWords = words.map(
        (word: string) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(' ');
};

type FilterProps = {
    filters: UserFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<UserType> | null;
};

function Filter(props: FilterProps) {
    const { loadingEvaluators, handleGetEvaluators, evaluators } =
        useGetEvaluators();
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        country: z.string().optional(),
        gender: z.string().optional(),
        status: z.string().optional(),
        userId: z.string().optional(),
        evaluatorOneDecision: z.string().optional(),
        evaluatorTwoDecision: z.string().optional(),
        evaluatorThreeDecision: z.string().optional(),
    });
    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
        country: '',
        gender: '',
        status: '',
        userId: '',
        evaluatorOneDecision: '',
        evaluatorTwoDecision: '',
        evaluatorThreeDecision: '',
    };

    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);
        const {
            from,
            to,
            country,
            gender,
            status,
            userId,
            evaluatorOneDecision,
            evaluatorTwoDecision,
            evaluatorThreeDecision,
        } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (country) searchParams.set('country', country);
        if (gender) searchParams.set('gender', gender);
        if (status) searchParams.set('status', status);
        if (userId) searchParams.set('userId', userId);
        if (evaluatorOneDecision)
            searchParams.set('evaluatedOneDecision', evaluatorOneDecision);
        if (evaluatorTwoDecision)
            searchParams.set('evaluatedTwoDecision', evaluatorTwoDecision);
        if (evaluatorThreeDecision)
            searchParams.set('evaluatedThreeDecision', evaluatorThreeDecision);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };

    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };
    const query = location.search;

    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));

    const updatePageSizeInQuery = (query: string, pageSize: number): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('pageSize', pageSize.toString());
        return '?' + urlParams.toString();
    };

    const PageSize = 1000000;
    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, PageSize);
        handleGetEvaluators(updatedQuery);
    }, []);
    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'} size="3xl">
                <Form<UserFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="grid grid-cols-2   gap-4 ">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />

                                <SelectField
                                    placeholder="Select Country"
                                    label="Country"
                                    registration={register('country')}
                                    options={countryOptions}
                                />

                                <SelectField
                                    placeholder="Select Gender"
                                    label="Gender"
                                    registration={register('gender')}
                                    options={[
                                        { value: 'male', text: 'Male' },
                                        { value: 'female', text: 'Female' },
                                    ]}
                                />
                                <SelectField
                                    placeholder="Select status"
                                    label="Status"
                                    registration={register('status')}
                                    options={[
                                        { value: 'pending', text: 'Pending' },
                                        { value: 'approved', text: 'Approved' },
                                        { value: 'reject', text: 'Rejected' },
                                    ]}
                                />
                                <SelectField
                                    placeholder="Select Evaluator"
                                    isLoading={loadingEvaluators}
                                    label="User"
                                    options={(evaluators?.list || []).map(
                                        (category: {
                                            id: any;
                                            firstName: any;
                                            lastName: any;
                                        }) => ({
                                            value: category?.id,
                                            text: `${category?.firstName} ${category?.lastName}`,
                                        })
                                    )}
                                    registration={register('userId')}
                                />
                                <SelectField
                                    placeholder="Evaluator One Decison"
                                    label="evaluator One Decision"
                                    registration={register(
                                        'evaluatorOneDecision'
                                    )}
                                    options={[
                                        { value: 'weak', text: 'Weak' },
                                        { value: 'medium', text: 'Medium' },
                                        { value: 'strong', text: 'Strong' },
                                    ]}
                                />
                                <SelectField
                                    placeholder="Evaluator Two Decison"
                                    label="Evaluator Two Decision"
                                    registration={register(
                                        'evaluatorTwoDecision'
                                    )}
                                    options={[
                                        { value: 'weak', text: 'Weak' },
                                        { value: 'medium', text: 'Medium' },
                                        { value: 'strong', text: 'Strong' },
                                    ]}
                                />
                                <SelectField
                                    placeholder="Evaluator Three Decison"
                                    label="Evaluator Three decision"
                                    registration={register(
                                        'evaluatorThreeDecision'
                                    )}
                                    options={[
                                        { value: 'weak', text: 'Weak' },
                                        { value: 'medium', text: 'Medium' },
                                        { value: 'strong', text: 'Strong' },
                                    ]}
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
}

export default function EvaluationsStep3Checked() {
    const [open, setOpen] = useState(false);
    const {applicationId} :any= useParams()
    const [selected, setSelected] = useState<UserType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<UserFilterPayload>({
        from: '',
        to: '',
        country: '',
        gender: '',
        status: '',
    });
    const location = useLocation();
    const query = location.search;
    const navigate = useNavigate();
    const { loadingEvaluations, handleGetEvaluations, evaluations } =
        useGetEvaluations();

    const updatePageSizeInQuery = (query: string): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('moderated', 'true');
        urlParams.set('applicationIs',applicationId );
        return '?' + urlParams.toString();
    };
    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query);
        handleGetEvaluations(updatedQuery);
    }, [query, refresh]);

    const filteredEvaluations = evaluations?.list.filter(
        (evaluation: EvaluationType) => {
            if (!evaluation.status || evaluation.status === null) {
                return false;
            }

            return true;
        }
    );

    const columns: TableColumnV2<EvaluationType>[] = [
        {
            title: 'View',
            accessor: 'actions',
            render: row => (
                <p className="flex flex-row justify-between space-x-2">
                    <button
                        onClick={() => {
                            navigate(
                                `/account/modulator/user_details/step3/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`
                            );
                        }}
                        className="btn btn-primary btn-sm "
                    >
                        <IconEye />
                    </button>
                </p>
            ),
        },
        {
            title: ' Applicant Code',
            accessor: 'submittedApplication.applicant_code',
            render: row => (
                <p>{row.submittedApplication?.applicant_code || '-'} </p>
            ),
        },
        {
            title: 'Applicant Name',
            accessor: 'submittedApplication.user.firstName',
            render: row => (
                <Link
                    to={`/account/modulator/user_details/step3/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`}
                    className="hover:underline"
                >
                    {row.submittedApplication?.user?.firstName || '-'}{' '}
                    {row.submittedApplication?.user?.lastName || '-'}
                </Link>
            ),
        },
        {
            title: 'Gender',
            accessor: 'row.submittedApplication?.user?.personalInformation[0]?.gender',
            render: row => (
                <p className='capitalize'>
                    {row.submittedApplication?.user?.personalInformation[0]?.gender}
                </p>
            ),
        },
        {
            title: 'Country',
            accessor: 'row.submittedApplication?.user?.personalInformation[0]?.Nationality',
            render: row => (
                <p>
                    {row.submittedApplication?.user?.personalInformation[0]?.Nationality}
                </p>
            ),
        },
        {
            title: 'Highest Degree',
            accessor: 'levelOfStudy',
            render: row => (
                <p>
                    {row.submittedApplication.user.academicHistories
                        .find(history => history.highest_qualification)
                        ?.levelOfStudy?.toUpperCase() || '-'}
                </p>
            ),
        },

        {
            title: 'Evaluator One decision',
            accessor: 'evaluator_one_marks',
            render: row => (
                <p>{row.evaluator_one_marks?.toUpperCase() || '-'}</p>
            ),
        },

        {
            title: 'Evaluator Two decision',
            accessor: 'evaluator_two_marks',
            render: row => (
                <p>{row.evaluator_two_marks?.toUpperCase() || '-'}</p>
            ),
        },
        {
            title: 'Evaluator Three decision',
            accessor: 'evaluator_three_marks',
            render: row => (
                <>
                    {row?.evaluatorThree !== null ? (
                        <p className="text-black">
                            {row?.evaluator_three_marks ?? '-'}
                        </p>
                    ) : (
                        <p>No evaluator 3</p>
                    )}
                </>
            ),
        },
        {
            title: 'Academic Manager decision',
            accessor: 'academic_manager_decision',
            render: row => (
                <div
                    className={`badge w-fit ${
                        row?.academic_manager_decision === 'reject'
                            ? 'bg-danger'
                            : row?.academic_manager_decision === 'accept'
                            ? 'bg-success'
                            : ' text-white dark:text-black'
                    }`}
                >
                    <p className="">
                        {row?.academic_manager_decision === 'reject'
                            ? 'Rejected'
                            : row?.academic_manager_decision === 'accept'
                            ? 'Accepted'
                            : '-'}
                    </p>
                </div>
            ),
        },

        {
            title: 'Evaluator One Name',
            accessor: 'evaluatorOne.lastName',
            render: row => (
                <p className="capitalize">
                    {(row.evaluatorOne.lastName || '-') +
                        ' ' +
                        (row.evaluatorOne.firstName || '-')}
                </p>
            ),
        },

        {
            title: 'Evaluator Two Name',
            accessor: 'evaluatorTwo.lastName',
            render: row => (
                <p className="capitalize">
                    {(row.evaluatorTwo.lastName || '-') +
                        ' ' +
                        (row.evaluatorTwo.firstName || '-')}
                </p>
            ),
        },
        {
            title: 'Evaluator Three Name',
            accessor: 'evaluatorThree.lastName',
            render: row => (
                <>
                    {row?.evaluatorThree !== null ? (
                        <p className="capitalize">
                            {(row.evaluatorThree.lastName || '-') +
                                ' ' +
                                (row.evaluatorThree.firstName || '-')}
                        </p>
                    ) : (
                        <p>No Evaluator 3 </p>
                    )}
                </>
            ),
        },
        {
            title: 'Academic Manager Name',
            accessor: 'academicManager.lastName',
            render: row => (
                <>
                    {row?.academicManager !== null ? (
                        <p className="capitalize">
                            {(row.academicManager.lastName || '-') +
                                ' ' +
                                (row.academicManager.firstName || '-')}
                        </p>
                    ) : (
                        <p>No Academic Manager </p>
                    )}
                </>
            ),
        },

        {
            title: 'Application Name',
            accessor: 'submittedApplication.application.title',
            render: row => (
                <p>{row.submittedApplication.application.title || '-'}</p>
            ),
        },
        {
            title: 'Evaluation status',
            accessor: 'status',
            render: row => {
                let badgeColor;
                switch (row?.status) {
                    case 'approved':
                        badgeColor = 'success';
                        break;
                    case 'reject':
                        badgeColor = 'danger';
                        break;
                    case 'assigned':
                        badgeColor = 'blue';
                        break;
                    case 'pending':
                        badgeColor = 'warning';
                        break;
                    default:
                        if (row?.status.startsWith('accepted')) {
                            badgeColor = 'success';
                        } else {
                            badgeColor = 'warning';
                        }
                }
                return (
                    <span className={` capitalize badge bg-${badgeColor}`}>
                        {transformStatusToDisplayText(row.status)}
                    </span>
                );
            },
        },
        {
            title: 'Action',
            accessor: 'actions',
            render: row => (
                <p className="flex flex-row justify-between space-x-2">
                    <More evaluation={row} />
                </p>
            ),
        },
    ];

    const { userAccount: user }: any = useAppSelector(store => store.user);
    return (
        <>
            <div className="">
                <DataTableV2
                    columns={columns}
                    tableName="Evaluation Decision"
                    previousPage={evaluations?.previousPage ?? 0}
                    nextPage={evaluations?.nextPage ?? 0}
                    currentPage={evaluations?.currentPage ?? 0}
                    data={filteredEvaluations ?? []}
                    total={evaluations?.total ?? 0}
                    lastPage={evaluations?.lastPage ?? 0}
                    isLoading={loadingEvaluations}
                    header={
                        <div className="w-full flex my-3 justify-end items-center">
                            <Filter
                                filters={filters}
                                data={evaluations}
                                onFilter={(payload: ModFilter) => {
                                    setFilters(payload);
                                }}
                            />
                        </div>
                    }
                />
            </div>
        </>
    );
}
