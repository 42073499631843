import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios';

import { PaginationType } from '@/core/types/shared';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { queryString } from '@/core/utils/query';
import { AddressPayload } from '@/core/types/address';



export const add_address = async (
    AddressPayload: AddressPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/address', AddressPayload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_adddress = async (payload : AddressPayload, id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/address/${id}`,payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const my_address = async (): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.get('/address/my-address');
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_address = async (id: string): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/address/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const get_one_address = async (id: string): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.get(`/address/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_addresses = async (query? : string): Promise<PaginationType<UserType>> => {
    try {
        const request = await PRIVATE_API.get(`/address${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


