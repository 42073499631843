import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import {
    GetMyAddress,
    getAddresses,
    useCreateAddress,
} from '@/core/hooks/address';
import { AddressType } from '@/core/types/address';
import { z } from 'zod';
import IconX from '@/components/Icon/IconX';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import DeleteUserModal from '../users/modals/delete_modal';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import { AddExperience } from '../experience/modal/Add_Experience';
import { GetApplications } from '@/core/hooks/application';
import { ApplicationType } from '@/core/types/application';
import { GetCategories } from '@/core/hooks/category';
import { CategoryType } from '@/core/types/category';
import { active } from 'sortablejs';
import { SuggestedTopicPayload, SuggestedTopicType } from '@/core/types/suggested-topics';
import { GetSingleSuggestedTopic } from '@/core/hooks/suggested-topics';
import CreateSuggestedTopic from './modal/create_suggested_topic';
import UpdateSuggestedTopic from './modal/edit_suggested_topic';
import DeleteSuggestedTopic from './modal/delete_suggested_topic';
import DataTablev3 from '@/components/datatable/Datatable2';

export default function SuggestedTopics() {
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<SuggestedTopicType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = location.search;
    const { userAccount: user }: any = useAppSelector(store => store.user);
  

    const { loadingSuggestedTopic, handleGetSuggestedTopic, suggestedTopic } =
        GetSingleSuggestedTopic();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetSuggestedTopic(query);
    }, [query, refresh]);

    const columns: TableColumnV2<SuggestedTopicType>[] = [
        {
            title: 'Title',
            accessor: 'title',
            render: row => <p className="capitalize">{row?.title}</p>,
        },

        {
            title: 'Description',
            accessor: 'description',
            render: row => (
                <p className="capitalize whitespace-wrap">
                    {row.description.slice(0, 30)}
                    {row.description.length > 30 && '...'}
                </p>
            ),
        },
        

        {
            title: 'Needed skills',
            accessor: '',
            render: row => (
                <div>
                    {' '}
                    {row.skills_needed.map(skill => (
                        <p>{skill.name}</p>
                    ))}
                </div>
            ),
        },
        {
            title: 'Creator Name',
            accessor: 'user.firstName',
            render: row => (
                <p className="capitalize whitespace-wrap">{row.user.firstName}{row.user.lastName}</p>
            ),
        },

        {
            title: 'Creator email',
            accessor: 'user.email',
            render: row => (
                <p className=" whitespace-wrap">{row.user.email}</p>
            ),
        },

        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>

                    
                     <UpdateSuggestedTopic topic={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];

    return (
        <div className="panel py-4 ">
            <div>
            <div className="flex flex-row justify-end">
                    <CreateSuggestedTopic />
                </div>
            </div>
            <div className="">
                <DataTablev3
                    columns={columns}
                    previousPage={suggestedTopic?.previousPage ?? 0}
                    nextPage={suggestedTopic?.nextPage ?? 0}
                    currentPage={suggestedTopic?.currentPage ?? 0}
                    data={suggestedTopic?.list ?? []}
                    total={suggestedTopic?.total ?? 0}
                    lastPage={suggestedTopic?.lastPage ?? 0}
                    isLoading={loadingSuggestedTopic}
                />
                <DeleteSuggestedTopic
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete Suggested Topic'}
                />
            </div>
        </div>
    );
}
