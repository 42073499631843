import { GetAnswers } from '@/core/hooks/answers';
import { GetApplications, useApplyApplication } from '@/core/hooks/application';
import { GetTopics } from '@/core/hooks/topics';
import { ApplicationFilterPayload, ApplicationType } from '@/core/types/application';
import { CircularProgress } from '@mui/material';
import  { useEffect, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { UpdateApplication } from '../modal/Update_Application';
import { PaginationType } from '@/core/types/shared';
import Button from '@/components/button';
import { InputField } from '@/components/input';
import Modal from '@/components/modal';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Form } from '@/components/form';
import { UserFilterPayload } from '@/core/types/auth';
import { z } from 'zod';


function ApplicatonDATA() {
    const { applicationId }: any = useParams();
    const { handleGetApplications, applications } = GetApplications();
    const { handleGetAnswers, answers } = GetAnswers();
    const { handleGetTopics, topics } = GetTopics();
    const { applyApplication, loadingApply } = useApplyApplication();
    const [selectedApplication, setSelectedApplication] =
        useState<ApplicationType | null>(null);

    const location = useLocation();
    const query = location.search;

    useEffect(() => {
        handleGetApplications(query);
        handleGetTopics(query);
        handleGetAnswers(query);
    }, [query]);

    useEffect(() => {
        const selected = applications?.list.find(
            (app: { id: any }) => app.id === applicationId
        );

        setSelectedApplication(selected);
    }, [applications, applicationId]);

    useEffect(() => {
        const selected = applications?.list.find(
            (app: { id: any }) => app.id === applicationId
        );
        setSelectedApplication(selected);
    }, [applications, applicationId]);

    const handleApply = () => {
        applyApplication(applicationId);
    };

    return (
        <>
	<div className="  flex flex-row justify-between " >
			<p className="text-lg font-bold "> Application data  </p>
            <div>
            {
               selectedApplication && (
                    <UpdateApplication  application={selectedApplication} refresh={function (prev: any): void {
                        throw new Error('Function not implemented.');
                    } } />
                )
            }
            </div>
          
            
		</div>
            {selectedApplication ? (
                <><div className="mx-auto mt-8 p-6 rounded-lg shadow-md">
                  
                    <div className='flex flex-row justify-between'>
                    <h1 className="text-xl font-bold mb-4 text-primary dark:text-gray-400">
                        {selectedApplication?.title}
                    </h1>
                    <div>
               
                    </div>
                    
                    </div>
                  

                    <div
                        className="dark:text-gray-400"
                        dangerouslySetInnerHTML={{
                            __html: selectedApplication?.description || '',
                        }} />
                    <div>
                        <h2 className="text-2xl font-bold mb-2 text-primary">
                            Instructions
                        </h2>
                        <div
                            className="dark:text-gray-400"
                            dangerouslySetInnerHTML={{
                                __html: selectedApplication?.instruction_note || '',
                            }} />
                    </div>
                </div></>
            ) : (
                <div>
                    <CircularProgress/>
                </div>
            )}
        </>
    );
}



export default function ApplicationDetails() {
	const location = useLocation();
	const link = [
		{
			to: "",
			title: "Application Details",
			active: location.pathname === "application-details",
		},
		{
			to: "evaluators",
			title: "Evaluators",
			active: location.pathname === "evaluators",

		},
        {
			to: "academic_managers",
			title: "Academic managers",
			active: location.pathname === "academic_managers",
		},
        {
			to: "moderators",
			title: "Moderators",
			active: location.pathname === "moderators",
		},
		{
			to: "applicants",
			title: "Appplicants",
			active: location.pathname === "applicants",
		},
		{
			to: "applications",
			title: "Applications",
			active: location.pathname === "applications",
		},
        
        {
			to: "evaluation",
			title: " Evaluations",
			active: location.pathname === "evaluations",
		},
	];
	return (
		<>
           <div className=" my-2 ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <Link to='/account/admin'  className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <button className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                            Home
                        </button>
                    </Link>
                    <li className="bg-[hsl(223,21%,94%)] dark:bg-[#1b2e4b]">
                        <Link to='/account/admin/application' className="p-1.5 px-3 ltr:pl-6 rtl:pr-6 relative   h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                            Applications
                        </Link>
                    </li>
                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            Application Data 
                        </button>
                    </li>
                </ol>

            </div>
           
			<Tab.Group>
				<Tab.List className="mt-3 flex flex-wrap">
                    {link.map((tab, index) => (
                        <Tab as={Fragment}>
                            {() => (
                                <Link
                                    to={tab.to}
                                    className={`${
                                        tab.active
                                            ? "border-b !border-secondary text-secondary !outline-none"
                                            : ""
                                    } -mb-[1px] flex items-center border-transparent p-5 py-3 before:inline-block hover:border-b hover:!border-secondary hover:text-secondary`}
                                >
                                    
                                    {tab.title}
                                </Link>
                            )}
                        </Tab>
                    ))}
				</Tab.List>
			</Tab.Group>
			<div className=' mt-2 p-4 '>
				<Outlet />
			</div>
		</>
	);
}

function TabLink(props: { title: string; to: string; active: boolean }) {
	return (
		<Link
			to={props.to}
			className={`py-2 px-3 ${
				props.active ? "bg-primary text-gray-200" : "bg-gray-200"
			} hover:bg-indigo-500 hover:text-gray-200`}
		>
			{props.title}
		</Link>
	);
}




type FilterProps = {
    filters: ApplicationFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<ApplicationType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        userId: z.string().optional(),
    });

    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
    };
    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, to, userId } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (userId) searchParams.set('userId', userId);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };
    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };

    

    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<ApplicationFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="grid grid-cols-1">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />
                              
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
            {/* <PrintModal
				title={"Print List of Events"}
				open={openPrint}
				setOpen={setOpenPrint}
				data={data}
			/> */}
        </>
    );
}