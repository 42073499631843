// src/core/api/suggestedTopics.ts
import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import { PaginationType } from '@/core/types/shared';

import { queryString } from '@/core/utils/query';
import PRIVATE_API from '../axios';
import { SuggestedTopicPayload, SuggestedTopicType } from '@/core/types/suggested-topics';

export const addSuggestedTopic = async (payload: SuggestedTopicPayload): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/suggested_topic', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const editSuggestedTopic = async (
    payload: SuggestedTopicPayload,
    id: string
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/suggested_topic/${id}`, payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const oneSuggestedTopic = async (id: string): Promise<SuggestedTopicType | any> => {
    try {
        const request = await PRIVATE_API.get(`/suggested_topic/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const deleteSuggestedTopic = async (id: string): Promise<SuggestedTopicType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/suggested_topic/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const getSuggestedTopics = async (
    query?: string
): Promise<PaginationType<SuggestedTopicType>> => {
    try {
        const request = await PRIVATE_API.get(`/suggested_topic${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
