import {
    delete_user,
    edit_user,
    get_users,
    login_user,
    register_user,
} from '@/core/api/auth';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import { useAppDispatch } from '@/store';
import { addUser, removeUser } from '@/store/slices/userslice';
import { storage } from '@/core/utils';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { add_personal_information, get_personal_information, my_personal_information } from '@/core/api/personal_Info';
import { PersonalInfoPayload } from '@/core/types/personal_info';

export const useGetPersonalInfos = () => {
    const [loadingInformation, setLoadingInformation] = useState(false);
    const [personalInfo, setPersonalInfo]: any = useState<PaginationType<UserType> | null>();
    const handleGetPersonalInfo = async (query?: string, search?: string) => {
        try {
            setLoadingInformation(true);
            const data: PaginationType<UserType> = await get_personal_information(query);
            setPersonalInfo(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingInformation(false);
        }
    };

    return {
        loadingInformation,
        personalInfo,
        handleGetPersonalInfo,
    };
};

export const useGetMyPersonalInfo = () => {
    const [loadingInformation, setLoadingInformation] = useState(false);
    const [personalInfo, setPersonalInfo]: any = useState<PaginationType<UserType> | null>();
    const handleGetMyPersonalInfo =async()=>{
        try {
            setLoadingInformation(true);
            const data: PaginationType<UserType> = await my_personal_information();
            setPersonalInfo(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingInformation(false);
        }
    }
  
    return {
        loadingInformation,
        personalInfo,
        handleGetMyPersonalInfo,
    };
};



export const AddPersonalInfo = () => {
    const [LoadingAddPersoanlInfo,setLoadingAddPersonalInfo] = useState(false);
    const handleAddPersonalInfo = async (payload: PersonalInfoPayload, id: string) => {
        try {
            setLoadingAddPersonalInfo(true);
            add_personal_information(payload, id).then(() => toast.success('Personal Info updated'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingAddPersonalInfo(false);
        }
    };

    return {
        handleAddPersonalInfo,
        LoadingAddPersoanlInfo,
    };
};


