import AcademicInfo from '@/app/dashboard/academics';
import CreateAddress from '@/app/dashboard/address';
import Applications from '@/app/dashboard/applications';
import CreateApplication from '@/app/dashboard/applications/createApplication';
import Categories from '@/app/dashboard/category';
import DashBoardMain from '@/app/dashboard/dashMain';
import ExperiencesInfo from '@/app/dashboard/experience';
import LanguageInfo from '@/app/dashboard/languages';
import CreatePersonalInfo from '@/app/dashboard/personal_Information';
import QuestionsInfo from '@/app/dashboard/questions';
import ReferenceInfo from '@/app/dashboard/references';
import SuggestedTopics from '@/app/dashboard/suggested-topics';
import Topics from '@/app/dashboard/topics';
import CreateTopic from '@/app/dashboard/topics/modal/create_topic';
import DashUsers from '@/app/dashboard/users';
import CreateUser from '@/app/dashboard/users/create';
import Error404 from '@/app/errors/4004Error';
import ApplicationLayout from '@/app/layouts/applicationLayout';
import CategoryLayout from '@/app/layouts/categories';
import ProfileLayout from '@/app/layouts/personal_nformation';
import QuestionLayout from '@/app/layouts/question';
import SuggestedTopicsLayout from '@/app/layouts/suggested-topics';
import SupervisorLayout from '@/app/layouts/supervisor/indes';
import TopicsLayout from '@/app/layouts/topics';
import UsersLayout from '@/app/layouts/user';
import AdminLayout from '@/components/layout/admin/DefaultLayout';
import { Navigate, useRoutes } from 'react-router-dom';
import { RoleProtector } from '@/core/protector';
import ApplicantLayout from '@/components/layout/applicant/ApplicantLayout';
import Apply from '@/app/dashboard/applications/apply';
import ApplyLayout from '@/app/layouts/apply';
import AdminProfile from '@/app/layouts/admin-profile';
import { Settings } from '@/app/dashboard/adminProfile';
import SupervisorsInfo from '@/app/dashboard/supervisors';
import SubmittedApplications from '@/app/dashboard/applications/submitted-application';
import MyApplications from '@/app/dashboard/applications/myApplications';
import { SignUpPage } from '@/app/auth/signup';
import AdminViewSupervisorsInfo from '@/app/dashboard/supervisors/admin_supervisor';
import SubmittedUser from '@/app/dashboard/applications/submitted_user';
import SubmittedUserLayout from '@/app/layouts/user_details';
import Applicants from '@/app/dashboard/applications/applicants';
import MyProfileLayout from '@/app/layouts/my_profile';
import MyProfile from '@/app/dashboard/view_profile';
import Home from '@/app/home';
import RequestOtpSend from '@/app/auth/reset/RequestOtp';
import { ResetPasswordConfirm } from '@/app/auth/reset/ResetPassword';
import { ActivateUser } from '@/app/dashboard/users/activation';
import EvaluatorLayout from '@/components/layout/evaluator/Evaluator';
import Evaluations from '@/app/dashboard/evaluation/step1';
import DashEvaluators from '@/app/dashboard/evaluation/evaluators';
import SubmittedApplicationsForEvaluation from '@/app/dashboard/evaluation/submitted-application';
import SubmittedApplicationsForEvaluationLayout from '@/app/layouts/evaluation';
import ReviewUserStep1 from '@/app/dashboard/evaluation/step1/submitted';
import ReviewUserStep2 from '@/app/dashboard/evaluation/step2/submitted';
import Evaluationsstep2 from '@/app/dashboard/evaluation/step2';
import MySubmittedApplications from '@/app/dashboard/applications/mySubmittedApplication';
import ApplicationDecision from '@/app/dashboard/applications/application-decision';
import Dashmoderators from '@/app/dashboard/modulators';
import EvaluationsStep3 from '@/app/dashboard/modulators/application_decisions/pending';
import ReviewUserStep3 from '@/app/dashboard/modulators/application_decisions/submitted';
import Addmoderator from '@/app/dashboard/modulators/create/create_modulator';
import NonEligible from '@/app/dashboard/modulators/nonEligible';
import ReviewUserNonEligible from '@/app/dashboard/modulators/nonEligible/submitted';
import ModulaorLayout from '@/components/layout/modulator/modulator';
import FileUpload from '@/app/dashboard/upload';
import ApplicatonDetails from '@/app/dashboard/applications/appicationDetails';
import LogIn from '@/app/auth/login';
import Notifications from '@/app/dashboard/notifications';
import NotificationsLayout from '@/app/layouts/notification';
import IncompleteApplications from '@/app/dashboard/applications/incomplete';
import ApplicatonDATA from '@/app/dashboard/applications/application_data';
import AcademicManagers from '@/app/dashboard/academic_managers';
import AcademicManagerLayout from '@/components/layout/academic-manager/Academic_manager';
import AcademicManagerEvaluations from '@/app/dashboard/academic_managers/evaluations';
import AcademicManagerReviewUser from '@/app/dashboard/academic_managers/evaluations/sunmitted';
import ApplicantsApplications from '@/app/dashboard/applications/applicant_application';
import Step1Checked from '@/app/dashboard/evaluation/step1/checked';
import Step1Pending from '@/app/dashboard/evaluation/step1/pending';
import EvaluatorApplications from '@/app/dashboard/evaluation/layout';
import AcademicPending from '@/app/dashboard/academic_managers/evaluations/pending';
import AcademicChecked from '@/app/dashboard/academic_managers/evaluations/checked';
import AcademicManagerApplications from '@/app/dashboard/academic_managers/layout';
import SubmittedApplicationsForEvaluationLayout2 from '@/app/layouts/evaluation/evaluationsByevaluator';
import SubmittedApplicationsForEvaluationLayout3 from '@/app/layouts/evaluation/evaluationByModerator';
import SubmittedApplicationsForEvaluationLayoutModerator from '@/app/layouts/evaluation/evaluationsByModulator';
import ModeratorEvaluations from '@/app/dashboard/modulators/layout';
import EvaluationsStep3Pending from '@/app/dashboard/modulators/application_decisions/pending';
import EvaluationsStep3Checked from '@/app/dashboard/modulators/application_decisions/checked';
import ModulatorApplicationsList from '@/app/dashboard/modulators/application_decisions';

export default function AppRoutes() {
    return useRoutes([
        { path: '/', element: <Navigate to="/home" replace /> },
        { path: 'home', element: <Home /> },
        { path: 'login', element: <LogIn /> },
        { path: 'register', element: <SignUpPage /> },
        { path: 'reset-password', element: <RequestOtpSend /> },
        { path: 'confirm_reset', element: <ResetPasswordConfirm /> },
        { path: '*', element: <Error404 /> },
        {
            path: 'user/activate/account',
            element: <ActivateUser />,
        },
        {
            path: 'account',
            children: [
                {
                    path: 'admin',
                    element: (
                        <RoleProtector role="admin" element={<AdminLayout />} />
                    ),

                    children: [
                        {
                            index: true,
                            element: <DashBoardMain />,
                        },
                        {
                            path: 'notifications',
                            element: <NotificationsLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <Notifications />,
                                },
                            ],
                        },

                        {
                            path: 'user_details',
                            element: <SubmittedUserLayout />,
                            children: [
                                {
                                    path: ':userId/:applicationId/:topicId',
                                    element: <SubmittedUser />,
                                },
                            ],
                        },
                        {
                            path: 'users',
                            element: <UsersLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <DashUsers />,
                                },

                                {
                                    path: 'create',
                                    element: <CreateUser />,
                                },
                            ],
                        },
                        {
                            path: 'evaluation',
                            element: <UsersLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <ApplicationDecision />,
                                },
                            ],
                        },

                        {
                            path: 'moderator',
                            element: <UsersLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <Dashmoderators />,
                                },

                                {
                                    path: 'create',
                                    element: <Addmoderator />,
                                },
                            ],
                        },

                        {
                            path: 'categories',
                            element: <CategoryLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <Categories />,
                                },
                            ],
                        },

                        {
                            path: 'supervisors',
                            element: <SupervisorLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <AdminViewSupervisorsInfo />,
                                },
                                {
                                    path: 'create',
                                    element: <CreateTopic />,
                                },
                            ],
                        },
                        {
                            path: 'questions',
                            element: <QuestionLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <QuestionsInfo />,
                                },
                                {
                                    path: 'create',
                                    element: <CreateTopic />,
                                },
                            ],
                        },

                        {
                            path: 'application',
                            element: <ApplicationLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <Applications />,
                                },
                                {
                                    path: 'applicants',
                                    element: <Applicants />,
                                },
                                {
                                    path: 'submitted',
                                    element: <SubmittedApplications />,
                                },
                                {
                                    path: 'application-details/:applicationId',
                                    element: <ApplicatonDetails />,
                                    children: [
                                        {
                                            index: true,
                                            element: <ApplicatonDATA />,
                                        },

                                        {
                                            path: 'applications',
                                            element: <ApplicantsApplications />,
                                            children: [
                                                {
                                                    index: true,
                                                    element: (
                                                        <IncompleteApplications />
                                                    ),
                                                },
                                                {
                                                    path: 'submitted-applications',
                                                    element: (
                                                        <SubmittedApplications />
                                                    ),
                                                },
                                            ],
                                        },
                                        {
                                            path: 'evaluation',
                                            element: <ApplicationDecision />,
                                        },
                                        {
                                            path: 'academic_managers',
                                            element: <AcademicManagers />,
                                        },
                                        {
                                            path: 'evaluators',
                                            element: <DashEvaluators />,
                                        },
                                        {
                                            path: 'moderators',
                                            element: <Dashmoderators />,
                                        },
                                        {
                                            path: 'applicants',
                                            element: <Applicants />,
                                        },
                                    ],
                                },
                                {
                                    path: 'create',
                                    element: <CreateApplication />,
                                },
                            ],
                        },
                        {
                            path: 'profile',
                            element: <AdminProfile />,
                            children: [
                                {
                                    index: true,
                                    element: <Settings />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'applicant',
                    element: (
                        <RoleProtector
                            role="applicant"
                            element={<ApplicantLayout />}
                        />
                    ),
                    children: [
                        {
                            children: [
                                {
                                    index: true,
                                    element: <DashBoardMain />,
                                },

                                {
                                    path: 'upload',
                                    element: <FileUpload />,
                                },

                                {
                                    path: 'categories',
                                    element: <CategoryLayout />,
                                    children: [
                                        {
                                            index: true,
                                            element: <Categories />,
                                        },
                                    ],
                                },

                                {
                                    path: 'suggested-topics',
                                    element: <SuggestedTopicsLayout />,
                                    children: [
                                        {
                                            index: true,
                                            element: <SuggestedTopics />,
                                        },
                                        {
                                            path: 'create',
                                            element: <CreateTopic />,
                                        },
                                    ],
                                },
                                {
                                    path: 'supervisors',
                                    element: <SupervisorLayout />,
                                    children: [
                                        {
                                            index: true,
                                            element: <SupervisorsInfo />,
                                        },
                                        {
                                            path: 'create',
                                            element: <CreateTopic />,
                                        },
                                    ],
                                },
                                {
                                    path: 'topics',
                                    element: <TopicsLayout />,
                                    children: [
                                        {
                                            index: true,
                                            element: <Topics />,
                                        },
                                        {
                                            path: 'create',
                                            element: <CreateTopic />,
                                        },
                                    ],
                                },
                                {
                                    path: 'suggested-topics',
                                    element: <SuggestedTopicsLayout />,
                                    children: [
                                        {
                                            index: true,
                                            element: <SuggestedTopics />,
                                        },
                                        {
                                            path: 'create',
                                            element: <CreateTopic />,
                                        },
                                    ],
                                },
                                {
                                    path: 'supervisors',
                                    element: <SupervisorLayout />,
                                    children: [
                                        {
                                            index: true,
                                            element: <Topics />,
                                        },
                                        {
                                            path: 'create',
                                            element: <CreateTopic />,
                                        },
                                    ],
                                },

                                {
                                    path: 'application',

                                    element: <ApplicationLayout />,
                                    children: [
                                        {
                                            index: true,
                                            element: <Applications />,
                                        },
                                        {
                                            path: 'submitted',
                                            element: (
                                                <MySubmittedApplications />
                                            ),
                                        },
                                        {
                                            path: 'my-applications',
                                            element: <MyApplications />,
                                        },
                                    ],
                                },
                                {
                                    path: 'apply',
                                    element: <ApplyLayout />,
                                    children: [
                                        {
                                            path: ':application_id',
                                            element: <Apply />,
                                        },
                                    ],
                                },
                                {
                                    path: 'profile',
                                    element: <ProfileLayout />,
                                    children: [
                                        {
                                            index: true,
                                            element: <CreatePersonalInfo />,
                                        },
                                        {
                                            path: 'address',
                                            element: <CreateAddress />,
                                        },
                                        {
                                            path: 'academic-information',
                                            element: <AcademicInfo />,
                                        },
                                        {
                                            path: 'experience',
                                            element: <ExperiencesInfo />,
                                        },
                                        {
                                            path: 'language',
                                            element: <LanguageInfo />,
                                        },
                                        {
                                            path: 'reference',
                                            element: <ReferenceInfo />,
                                        },
                                    ],
                                },
                                {
                                    path: 'my_profile',
                                    element: <MyProfileLayout />,
                                    children: [
                                        {
                                            index: true,
                                            element: <MyProfile />,
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'evaluator',
                    element: (
                        <RoleProtector
                            role="evaluator"
                            element={<EvaluatorLayout />}
                        />
                    ),

                    children: [
                        {
                            index: true,
                            element: <DashBoardMain />,
                        },

                        {
                            path: 'evaluation',
                            element: <UsersLayout />,
                            children: [
                                {
                                    path: 'applications',
                                    element: <EvaluatorApplications />,
                                },
                                {
                                    path: 'evaluate/:applicationId',
                                    element: <Evaluations />,
                                    children: [
                                        {
                                            index: true,
                                            element: <Step1Pending />,
                                        },
                                        {
                                            path: 'evaluated',
                                            element: <Step1Checked />,
                                        },
                                    ],
                                },
                                {
                                    path: 'step2',
                                    element: <Evaluationsstep2 />,
                                },
                                {
                                    path: 'evaluate',
                                    element: (
                                        <SubmittedApplicationsForEvaluation />
                                    ),
                                },
                            ],
                        },
                        {
                            path: 'user_details/step1',
                            element: (
                                <SubmittedApplicationsForEvaluationLayout2 />
                            ),
                            children: [
                                {
                                    path: ':userId/:rowId/:applicationId/:topicId',
                                    element: <ReviewUserStep1 />,
                                },
                            ],
                        },
                        {
                            path: 'user_details/step2',
                            element: (
                                <SubmittedApplicationsForEvaluationLayout />
                            ),
                            children: [
                                {
                                    path: ':userId/:rowId/:applicationId',
                                    element: <ReviewUserStep2 />,
                                },
                            ],
                        },
                        {
                            path: 'application',
                            element: <ApplicationLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <Applications />,
                                },

                                {
                                    path: 'submitted',
                                    element: <SubmittedApplications />,
                                },

                                {
                                    path: 'create',
                                    element: <CreateApplication />,
                                },
                            ],
                        },

                        {
                            path: 'profile',
                            element: <AdminProfile />,
                            children: [
                                {
                                    index: true,
                                    element: <Settings />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'modulator',
                    element: (
                        <RoleProtector
                            role="modulator"
                            element={<ModulaorLayout />}
                        />
                    ),

                    children: [
                        {
                            index: true,
                            element: <DashBoardMain />,
                        },

                        {
                            path: 'applications',
                            element: <UsersLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <ModulatorApplicationsList />,
                                },
                                {
                                    path: 'evaluate/:applicationId',
                                    element: <ModeratorEvaluations />,
                                    children: [
                                        {
                                            index: true,
                                            element: (
                                                <EvaluationsStep3Pending />
                                            ),
                                        },
                                        {
                                            path: 'evaluated',
                                            element: (
                                                <EvaluationsStep3Checked />
                                            ),
                                        },
                                    ],
                                },
                                {
                                    path: 'non-eligible',
                                    element: <NonEligible />,
                                },
                            ],
                        },
                        {
                            path: 'user_details/step3',
                            element: (
                                <SubmittedApplicationsForEvaluationLayoutModerator />
                            ),
                            children: [
                                {
                                    path: ':userId/:rowId/:applicationId',
                                    element: <ReviewUserStep3 />,
                                },
                            ],
                        },
                        {
                            path: 'user_details/non-eligible',
                            element: (
                                <SubmittedApplicationsForEvaluationLayout />
                            ),
                            children: [
                                {
                                    path: ':userId/:rowId/:applicationId',
                                    element: <ReviewUserNonEligible />,
                                },
                            ],
                        },
                        {
                            path: 'application',
                            element: <ApplicationLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <Applications />,
                                },

                                {
                                    path: 'submitted',
                                    element: <SubmittedApplications />,
                                },

                                {
                                    path: 'create',
                                    element: <CreateApplication />,
                                },
                            ],
                        },

                        {
                            path: 'profile',
                            element: <AdminProfile />,
                            children: [
                                {
                                    index: true,
                                    element: <Settings />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'academic_manager',
                    element: (
                        <RoleProtector
                            role="academic_manager"
                            element={<AcademicManagerLayout />}
                        />
                    ),

                    children: [
                        {
                            index: true,
                            element: <DashBoardMain />,
                        },

                        {
                            path: 'evaluation',
                            element: <UsersLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <AcademicManagerApplications />,
                                },
                                {
                                    path: 'evaluate/:applicationId',
                                    element: <AcademicManagerEvaluations />,
                                    children: [
                                        {
                                            index: true,
                                            element: <AcademicPending />,
                                        },
                                        {
                                            path: 'checked',
                                            element: <AcademicChecked />,
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            path: 'user_details/',
                            element: (
                                <SubmittedApplicationsForEvaluationLayout3 />
                            ),
                            children: [
                                {
                                    path: ':userId/:rowId/:applicationId',
                                    element: <AcademicManagerReviewUser />,
                                },
                            ],
                        },

                        {
                            path: 'application',
                            element: <ApplicationLayout />,
                            children: [
                                {
                                    index: true,
                                    element: <Applications />,
                                },

                                {
                                    path: 'submitted',
                                    element: <SubmittedApplications />,
                                },
                            ],
                        },

                        {
                            path: 'profile',
                            element: <AdminProfile />,
                            children: [
                                {
                                    index: true,
                                    element: <Settings />,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ]);
}
