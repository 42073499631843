
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { PaginationType } from '@/core/types/shared';
import { SuggestedTopicPayload, SuggestedTopicType } from '@/core/types/suggested-topics';
import { getSuggestedTopics, oneSuggestedTopic, editSuggestedTopic, addSuggestedTopic, deleteSuggestedTopic } from '@/core/api/suggested-topics';

export const GetSuggestedTopics = () => {
    const [loadingSuggestedTopics, setLoadingSuggestedTopics] = useState(false);
    const [suggestedTopics, setSuggestedTopics]: any = useState<SuggestedTopicType>();

    const handleGetSuggestedTopics = async (query?: string) => {
        try {
            setLoadingSuggestedTopics(true);
            const data: PaginationType<SuggestedTopicType> = await getSuggestedTopics(query);
            setSuggestedTopics(data);
        } catch (error: any) {
            toast.error(error.response.message || 'Error fetching suggested topics');
        } finally {
            setLoadingSuggestedTopics(false);
        }
    };

    return {
        loadingSuggestedTopics,
        suggestedTopics,
        handleGetSuggestedTopics,
    };
};

export const GetSingleSuggestedTopic = () => {
    const [loadingSuggestedTopic, setLoadingSuggestedTopic] = useState(false);
    const [suggestedTopic, setSuggestedTopic]: any = useState<SuggestedTopicType | null>();

    const handleGetSuggestedTopic = async (id?: any) => {
        try {
            setLoadingSuggestedTopic(true);
            const data: SuggestedTopicType = await oneSuggestedTopic(id);
            setSuggestedTopic(data);
        } catch (error: any) {
            toast.error(error.response.message || 'Error fetching suggested topic');
        } finally {
            setLoadingSuggestedTopic(false);
        }
    };

    return {
        loadingSuggestedTopic,
        suggestedTopic,
        handleGetSuggestedTopic,
    };
};

export const EditSuggestedTopicInfo = () => {
    const [loadingEditSuggestedTopic, setLoadingEditSuggestedTopic] = useState(false);

    const handleEditSuggestedTopic = async (payload: SuggestedTopicPayload, id: string) => {
        try {
            setLoadingEditSuggestedTopic(true);
            await editSuggestedTopic(payload, id);
            toast.success('Suggested topic information updated');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditSuggestedTopic(false);
        }
    };

    return {
        handleEditSuggestedTopic,
        loadingEditSuggestedTopic,
    };
};

export const useCreateSuggestedTopic = () => {
    const [loadingCreateSuggestedTopic, setLoadingCreateSuggestedTopic] = useState(false);

    const handleCreateSuggestedTopic = async (payload: SuggestedTopicPayload) => {
        try {
            setLoadingCreateSuggestedTopic(true);
            await addSuggestedTopic(payload);
            toast.success('Suggested topic information added');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingCreateSuggestedTopic(false);
        }
    };

    return {
        loadingCreateSuggestedTopic,
        handleCreateSuggestedTopic,
    };
};

export const useDeleteSuggestedTopic = () => {
    const [loadingDeleteSuggestedTopic, setLoadingDeleteSuggestedTopic] = useState(false);

    const handleDeleteSuggestedTopic = async (id: string) => {
        try {
            setLoadingDeleteSuggestedTopic(true);
            await deleteSuggestedTopic(id);
            toast.success('Suggested topic information deleted');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDeleteSuggestedTopic(false);
        }
    };

    return {
        loadingDeleteSuggestedTopic,
        handleDeleteSuggestedTopic,
    };
};
