import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField, TextArea } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { ExperiencePayload } from '@/core/types/experience'; // Updated import
import { EditExperienceInfo } from '@/core/hooks/experience';
import { countries } from 'countries-list';

const experienceSchema = z.object({
    employer_name: z.string().min(1, { message: 'Employer Name is required' }),
    country: z.string().min(1, { message: 'Country is required' }),
    city: z.string().min(1, { message: 'City is required' }),
    employer_website: z.string(),
    start_date: z.string().min(1, { message: 'Start Date is required' }),
    end_date: z.string().min(1, { message: 'End Date is required' }),
    position: z.string().min(1, { message: 'Position is required' }),
    roles_and_responsibilities: z.string().min(1, {
        message: 'Position roles and responsibilities  is required',
    }),
    type: z.string().min(1, { message: 'Type is required' }),

    employer_sector: z
        .string()
        .min(1, { message: 'Employer Website is required' }),
});

interface UpdateExperienceProps {
    refresh: (prev: any) => void;
    experience: ExperiencePayload;
}

export const UpdateExperience: React.FC<UpdateExperienceProps> = ({
    refresh,
    experience,
}: any) => {
    const [modal, setModal] = React.useState(false);
    const { loadingEditExperience, handleEditExperience } =
        EditExperienceInfo();
    const [selectedStartDate, setSelectedStartDate] = useState(
        experience?.start_date ? new Date(experience.start_date) : new Date()
    );
    const [selectedEndDate, setSelectedendDate] = useState(
        experience?.end_date ? new Date(experience.end_date) : new Date()
    );
    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="bg-warning text-gray-200 p-1 rounded"
                >
                    <IconPencil className="w-4" />
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Update Experience Information
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<
                                            ExperiencePayload,
                                            typeof experienceSchema
                                        >
                                            schema={experienceSchema}
                                            onSubmit={async data => {
                                                await handleEditExperience(
                                                    data,
                                                    experience.id
                                                );
                                                refresh((prev: any) => !prev);
                                                setModal(false);
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="px-4 grid grid-cols-2  gap-4 text-sm mb-3">
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditExperience
                                                            }
                                                            defaultValue={
                                                                experience?.employer_name
                                                            }
                                                            label="Employer Name"
                                                            error={
                                                                formState.errors
                                                                    .employer_name
                                                            }
                                                            registration={register(
                                                                'employer_name'
                                                            )}
                                                        />
                                                        <SelectField
                                                            placeholder="Select Country"
                                                            isLoading={
                                                                loadingEditExperience
                                                            }
                                                            label="Country"
                                                            defaultValue={
                                                                experience?.country
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .country
                                                            }
                                                            options={
                                                                countryOptions
                                                            }
                                                            registration={register(
                                                                'country'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditExperience
                                                            }
                                                            defaultValue={
                                                                experience?.city
                                                            }
                                                            label="City"
                                                            error={
                                                                formState.errors
                                                                    .city
                                                            }
                                                            registration={register(
                                                                'city'
                                                            )}
                                                        />
  
  <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditExperience
                                                            }
                                                            defaultValue={experience?.employer_website}
                                                            placeholder="e.g., www.example.com"
                                                            label="Employer Website"
                                                            error={
                                                                formState.errors
                                                                    .employer_website
                                                            }
                                                            registration={register(
                                                                'employer_website'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="date"
                                                            label="Start date"
                                                            placeholder="Start date"
                                                            isLoading={
                                                                loadingEditExperience
                                                            }
                                                            defaultValue={
                                                                selectedStartDate
                                                                    .toISOString()
                                                                    .split(
                                                                        'T'
                                                                    )[0]
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                setSelectedStartDate(
                                                                    new Date(
                                                                        e.target.start_date
                                                                    )
                                                                )
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .start_date
                                                            }
                                                            registration={register(
                                                                'start_date'
                                                            )}
                                                            className=""
                                                        />
                                                        <InputField
                                                            type="date"
                                                            label="End date"
                                                            placeholder="End date"
                                                            isLoading={
                                                                loadingEditExperience
                                                            }
                                                            defaultValue={
                                                                selectedEndDate
                                                                    .toISOString()
                                                                    .split(
                                                                        'T'
                                                                    )[0]
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                setSelectedendDate(
                                                                    new Date(
                                                                        e.target.end_date
                                                                    )
                                                                )
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .end_date
                                                            }
                                                            registration={register(
                                                                'end_date'
                                                            )}
                                                            className=""
                                                        />

                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditExperience
                                                            }
                                                            defaultValue={
                                                                experience?.position
                                                            }
                                                            label="Position"
                                                            error={
                                                                formState.errors
                                                                    .position
                                                            }
                                                            registration={register(
                                                                'position'
                                                            )}
                                                        />

                                                        <SelectField
                                                            placeholder="Employer sector"
                                                            isLoading={
                                                                loadingEditExperience
                                                            }
                                                            label="Employer sector"
                                                            error={
                                                                formState.errors
                                                                    .employer_sector
                                                            }
                                                            defaultValue={
                                                                experience?.employer_sector
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'academic',
                                                                    text: 'Academic',
                                                                },
                                                                {
                                                                    value: 'research',
                                                                    text: 'Research',
                                                                },
                                                                {
                                                                    value: 'industry',
                                                                    text: 'Industry',
                                                                },
                                                            ]}
                                                            registration={register(
                                                                'employer_sector'
                                                            )}
                                                        />
                                                        <SelectField
                                                            placeholder="Type"
                                                            isLoading={
                                                                loadingEditExperience
                                                            }
                                                            defaultValue={
                                                                experience?.type
                                                            }
                                                            label="Type"
                                                            error={
                                                                formState.errors
                                                                    .type
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'Entrepreneurial',
                                                                    text: 'Entrepreneurial',
                                                                },
                                                                {
                                                                    value: 'Internship',
                                                                    text: 'Internship',
                                                                },
                                                                {
                                                                    value: 'Professional Employment',
                                                                    text: 'Professional Employment',
                                                                },
                                                                {
                                                                    value: 'Research',
                                                                    text: 'Research',
                                                                },
                                                                {
                                                                    value: 'Volunteer',
                                                                    text: 'Volunteer',
                                                                },
                                                                {
                                                                    value: 'Work Study',
                                                                    text: 'Work Study',
                                                                },
                                                            ]}
                                                            registration={register(
                                                                'type'
                                                            )}
                                                        />
                                                    </div>
                                                    <div className='px-4 '>
                                                        <TextArea
                                                            isLoading={
                                                                loadingEditExperience
                                                            }
                                                            defaultValue={
                                                                experience?.roles_and_responsibilities || '-'
                                                            }
                                                            label="Position Description"
                                                            error={
                                                                formState.errors
                                                                    .roles_and_responsibilities
                                                            }
                                                            registration={register(
                                                                'roles_and_responsibilities'
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingEditExperience
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
