import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import { PaginationType } from '@/core/types/shared';
import { queryString } from '@/core/utils/query';
import {FileType } from '@/core/types/file';
import PRIVATE_API from '../api/axios';

export const add_files = async (
    payload: FileType
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/files', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_files = async (
    payload: FileType,
    id: string
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/files/${id}`, payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const one_files = async (
    id: string
): Promise<FileType | any> => {
    try {
        const request = await PRIVATE_API.get(`/files/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_files = async (
    id: string
): Promise<FileType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/files/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_filess = async (
    query?: string
): Promise<PaginationType<FileType>> => {
    try {
        const request = await PRIVATE_API.get(`/files${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_file_types = async (
    query?: string
): Promise<PaginationType<FileType>> => {
    try {
        const request = await PRIVATE_API.get(`/file-type${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
