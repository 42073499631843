import z from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import svg from '@/assets/aims_logo_2.png';
import svg2 from '@/assets/img2.jpg';
import CircularProgress from '@mui/material/CircularProgress';
import { useLoginUser } from '@/core/hooks/auth';
import { AuthLoginPayload } from '@/core/types/auth';
import { useEffect } from 'react';
import { storage } from '@/core/utils';
import { check_user } from '@/core/api/auth';
import { addUser } from '@/store/slices/userslice';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';

const schema: any = z.object({
    username: z
        .string()
        .min(1, ' User Name required')
        .refine(value => value.trim() === value, {
            message: 'username cannot have leading or trailing spaces',
        }),
    password: z.string().min(1, 'Password required'),
});

export type LoginInput = z.TypeOf<typeof schema>;

export const Login = () => {
    const { loadingLogin, handleLogin, error } = useLoginUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = storage.getToken();

    const fetchUser = async () => {
        if (token) {
            try {
                const user = await check_user();
                dispatch(addUser(user));
                if (user) {
                    navigate(`/account/${user?.role}`);
                }
            } catch (error) {
                return error;
            }
        } else {
        }
    };

    useEffect(() => {
        fetchUser();
    });

    useEffect(() => {
        dispatch(setPageTitle('Login'));
    }, [dispatch]);

    return (
        <div>
            <div className="  h-[100vh] flex flex-row justify-center px-6 py-6 dark:bg-[#060818] sm:px-10  overflow-none">
                <div className="relative flex flex-row  w-full max-w-[1502px] justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[90vh] lg:flex-row lg:gap-10 xl:gap-0">
                    <div className="relative flex w-full flex-col items-center justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
                        <div className="flex w-full max-w-[440px] items-center justify-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
                            <Link to="/" className="">
                                <div className="ml-10 w-full  lg:w-[50%] ">
                                    <img src={svg} alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="w-full max-w-[440px] lg:mt-16">
                            <div className="mb-10">
                                <h1 className="text-3xl font-bold  !leading-snug text-primary md:text-4xl">
                                    Sign in
                                </h1>
                                <p className="text-base  leading-normal text-white-dark">
                                    Enter your email and password to login
                                </p>
                            </div>

                            <Form<LoginInput, typeof schema>
                                schema={schema}
                                onSubmit={(payload: AuthLoginPayload) =>
                                    handleLogin(payload)
                                }
                                className="mt-10"
                            >
                                {({ register, formState }) => (
                                    <>
                                        <div className="space-y-4">
                                            <InputField
                                                type="text"
                                                label="Email"
                                                placeholder="Your email"
                                                isLoading={loadingLogin}
                                                error={
                                                    formState.errors.username
                                                }
                                                registration={register(
                                                    'username'
                                                )}
                                                className="h-13"
                                            />

                                            <InputField
                                                type="password"
                                                label="Password"
                                                placeholder="********"
                                                isLoading={loadingLogin}
                                                error={
                                                    formState.errors.password
                                                }
                                                registration={register(
                                                    'password'
                                                )}
                                                className="h-13"
                                            />
                                            {error && (
                                                <p className="text-red-500 text-sm font-bold ">
                                                    {' '}
                                                    {error}
                                                </p>
                                            )}
                                        </div>
                                        <div className="flex flex-col  ">
                                            <div className="flex flex-row items-center  justify-between">
                                                <Link
                                                    to="/reset-password"
                                                    className="text-sm font-medium text-primary"
                                                >
                                                    Forgot password?
                                                </Link>
                                                <p className="text-sm font-medium">
                                                    Didn't verify account{' '}
                                                    <Link
                                                        to="/user/activate/account"
                                                        className="text-primary font-bold "
                                                    >
                                                        {' '}
                                                        Verify now{' '}
                                                    </Link>
                                                </p>
                                            </div>
                                        </div>
                                        {loadingLogin ? (
                                            <button
                                                type="submit"
                                                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase  text-white"
                                            >
                                                <CircularProgress
                                                    size={24}
                                                    sx={{ color: 'white' }}
                                                />
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                                            >
                                                Log In
                                            </button>
                                        )}
                                    </>
                                )}
                            </Form>

                            <div className="mt-2 text-center capitalize dark:text-white ">
                                Don't have an account ?&nbsp;
                                <Link
                                    to="/register"
                                    className="uppercase text-primary underline transition hover:text-black dark:hover:text-white"
                                >
                                    SIGN UP
                                </Link>
                            </div>
                        </div>
                        <p className="absolute bottom-6 w-full text-center dark:text-white">
                            AIMS RIC {new Date().getFullYear()} All Rights
                            Reserved.
                        </p>
                    </div>
                    <div className=" website-agency-home">
                        <div
                            className=" absolute inset-0 opacity-30 bg-cover bg-center bg-opacity-50 m-0"
                            style={{ backgroundImage: `url(${svg2})` }}
                        />

                        <div className="website-agency-home-child" />
                        <div className="welcome-to-aims-wrapper">
                            <h1 className="welcome-to-aims">welcome to aims</h1>
                        </div>
                        <h1 className="explore-exciting-opportunities">{`Explore exciting opportunities and transform your future with AIMS. `}</h1>
                        <div className="join-us-on">
                            Join us on a journey of discovery and innovation.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
