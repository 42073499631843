import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import { PaginationType } from '@/core/types/shared';
import { TopicPayload, TopicType } from '@/core/types/topics';
import { queryString } from '@/core/utils/query';
import PRIVATE_API from '../axios';

export const send_email_test = async (payload: TopicPayload): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/email/send/test', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const send_email = async (
    payload: TopicPayload,
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/email/send', payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


