import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { EditAddress } from '@/core/hooks/address';
import { EditAcademicInfo } from '@/core/hooks/academics';
import { AcademicInfoPayload } from '@/core/types/academic';
import { countries } from 'countries-list';

const academicInfoSchema = z.object({
    institution: z.string().min(1, { message: 'Institution is required' }),
    country: z.string().min(1, { message: 'Country is required' }),
    city: z.string().min(1, { message: 'City is required' }),

    startDate: z.string().min(1, { message: 'Start Date is required' }),
    endDate: z.string().min(1, { message: 'End Date is required' }),
   
    levelOfStudy: z.string().min(1, { message: 'Level of Study is required' }),
    degree: z.string().min(1, { message: 'Degree is required' }),
    major: z.string().min(1, { message: 'Major is required' }),
    highest_qualification: z.string(),
    language_of_instructions: z
        .string()
        .min(1, { message: 'Primary Language is required' }),
});

interface UpdateAcademicInfoProps {
    refresh: (prev: any) => void;
    academicInfo: AcademicInfoPayload;
}

export const UpdateAcademicInfo: React.FC<UpdateAcademicInfoProps> = ({
    refresh,
    academicInfo,
}) => {
    const [modal, setModal] = React.useState(false);
    const { loadingEditAcademic, hanldeEditAcademic } = EditAcademicInfo();
    const [selectedstartDate, setSelectedstartDate] = useState(
        academicInfo?.startDate ? new Date(academicInfo.startDate) : new Date()
    );
    const [selectedendDate, setSelectedendDate] = useState(
        academicInfo?.endDate ? new Date(academicInfo.endDate) : new Date()
    );
    const [selectedGardDate, setSelectedGradDate] = useState(
        academicInfo?.endDate ? new Date(academicInfo.endDate) : new Date()
    );

    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));
    const [graduationDate, setGraduationDate] =useState('');

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="bg-warning text-gray-200 p-1 rounded"
                >
                    <IconPencil className="w-4" />
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Update Academic Information
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<
                                            any,
                                            typeof academicInfoSchema
                                        >
                                            schema={academicInfoSchema}
                                            onSubmit={async (data) => {
                                                data.highest_qualification = data.highest_qualification === 'true';
                                                if (graduationDate) {
                                                    data.graduationDate = graduationDate;
                                                }
                                                await hanldeEditAcademic(data,academicInfo?.id);
                                                refresh((prev: any) => !prev);
                                                setModal(false);
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="px-4 grid grid-cols-2  gap-4 text-sm mb-3">
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            defaultValue={
                                                                academicInfo?.institution
                                                            }
                                                            label="Institution"
                                                            error={
                                                                formState.errors
                                                                    .institution
                                                            }
                                                            registration={register(
                                                                'institution'
                                                            )}
                                                        />
                                                        <SelectField
                                                            placeholder="Select Country"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            label="Nationality"
                                                            defaultValue={
                                                                academicInfo?.country
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .country
                                                            }
                                                            options={
                                                                countryOptions
                                                            }
                                                            registration={register(
                                                                'country'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            defaultValue={
                                                                academicInfo?.city
                                                            }
                                                            label="City"
                                                            error={
                                                                formState.errors
                                                                    .city
                                                            }
                                                            registration={register(
                                                                'city'
                                                            )}
                                                        />

                                                        <InputField
                                                            type="date"
                                                            label="Start date"
                                                            placeholder="Start date"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            defaultValue={
                                                                selectedstartDate
                                                                    .toISOString()
                                                                    .split(
                                                                        'T'
                                                                    )[0]
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                setSelectedstartDate(
                                                                    new Date(
                                                                        e.target.startDate
                                                                    )
                                                                )
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .startDate
                                                            }
                                                            registration={register(
                                                                'startDate'
                                                            )}
                                                            className=""
                                                        />
                                                        <InputField
                                                            type="date"
                                                            label="End date"
                                                            placeholder="End date"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            defaultValue={
                                                                selectedendDate
                                                                    .toISOString()
                                                                    .split(
                                                                        'T'
                                                                    )[0]
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                setSelectedendDate(
                                                                    new Date(
                                                                        e.target.endDate
                                                                    )
                                                                )
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .endDate
                                                            }
                                                            registration={register(
                                                                'endDate'
                                                            )}
                                                            className=""
                                                        />
                                                        <InputField
                                                            type="date"
                                                            label="Graduation  date"
                                                            placeholder="Graduation date"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            defaultValue={
                                                                selectedGardDate
                                                                    .toISOString()
                                                                    .split(
                                                                        'T'
                                                                    )[0]
                                                            }
                                                            onChange={(
                                                                e: any
                                                            ) =>
                                                                setSelectedGradDate(
                                                                    new Date(
                                                                        e.target.graduationDate
                                                                    )
                                                                )
                                                            }
                                                            error={
                                                                formState.errors
                                                                    .graduationDate
                                                            }
                                                            registration={register(
                                                                'graduationDate',
                                                                {
                                                                    setValueAs:
                                                                        value => {
                                                                            setGraduationDate(
                                                                                value
                                                                            );
                                                                            return value;
                                                                        },
                                                                }
                                                            )}
                                                            className=""
                                                        />
                                                        <SelectField
                                                            placeholder="Level of Study"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            defaultValue={
                                                                academicInfo?.levelOfStudy
                                                            }
                                                            label=" Level of study"
                                                            error={
                                                                formState.errors
                                                                    .levelOfStudy
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'diploma',
                                                                    text: 'diploma',
                                                                },
                                                                {
                                                                    value: 'undergraduate',
                                                                    text: 'Undergraduate',
                                                                },
                                                                {
                                                                    value: 'postgraduate_diploma',
                                                                    text: 'PostGraduate Diploma  ',
                                                                },
                                                                {
                                                                    value: 'masters',
                                                                    text: 'Masters',
                                                                },
                                                                {
                                                                    value: 'phd',
                                                                    text: 'PHD',
                                                                },
                                                            ]}
                                                            registration={register(
                                                                'levelOfStudy'
                                                            )}
                                                        />
                                                        <SelectField
                                                            placeholder="Is this highest Qualification ?"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            label="Is highest level"
                                                            defaultValue={academicInfo?.highest_qualification}
                                                            error={
                                                                formState.errors
                                                                    .highest_qualification
                                                            }
                                                            
                                                            options={[
                                                                {
                                                                    value: true,
                                                                    text: 'yes',
                                                                },
                                                                {
                                                                    value: false,
                                                                    text: 'No',
                                                                },
                                                            ]}
                                                            registration={register(
                                                                'highest_qualification'
                                                            )}
                                                        />

                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            defaultValue={
                                                                academicInfo?.degree
                                                            }
                                                            label="Degree"
                                                            error={
                                                                formState.errors
                                                                    .degree
                                                            }
                                                            registration={register(
                                                                'degree'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            defaultValue={
                                                                academicInfo?.major
                                                            }
                                                            label="Major"
                                                            error={
                                                                formState.errors
                                                                    .major
                                                            }
                                                            registration={register(
                                                                'major'
                                                            )}
                                                        />

                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingEditAcademic
                                                            }
                                                            defaultValue={
                                                                academicInfo?.language_of_instructions
                                                            }
                                                            label="Language of  instructions"
                                                            error={
                                                                formState.errors
                                                                    .language_of_instructions
                                                            }
                                                            registration={register(
                                                                'language_of_instructions'
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingEditAcademic
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
