import z from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import svg from '@/assets/aims_logo_2.png';
import CircularProgress from '@mui/material/CircularProgress';
import { SignUp, useLoginUser } from '@/core/hooks/auth';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { useAppSelector } from '@/store';
import svg2 from '@/assets/img2.jpg';
import { useEffect, useState } from 'react';
import { storage } from '@/core/utils';
import { check_user } from '@/core/api/auth';
import { CheckUser } from '@/core/utils/UseUser';
import { addUser } from '@/store/slices/userslice';
import { useDispatch } from 'react-redux';
import { countries } from 'countries-list';
import { PhoneInput } from 'react-international-phone';

const schema: any = z
    .object({
        firstName: z.string().min(1, 'First Name required'),
        lastName: z.string().min(1, 'Last Name required'),
        email: z
            .string()
            .min(1, 'Email Name required')
            .refine((value) => value.trim() === value, {
                message: 'Email cannot have leading or trailing spaces',
            }),
        password: z.string().min(1, 'Password required'),

        confirmPassword: z.string().min(1, 'Password confirmation is required'),
    })
    .refine(data => data.password === data.confirmPassword, {
        path: ['confirmPassword'],
        message: 'Passwords do not match',
    });

export type LoginInput = z.TypeOf<typeof schema>;

export const SignUpPage = () => {
    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));

    const { handleCreate, loadingCreate } = SignUp();
    const [errorMessage, setErrorMessage] = useState('');

    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
    const [phone, setPhone] = useState<string | any>(null);
    const [phonError, setPhonError] = useState<string | any>(null);

    const ValidatePhoneNumber = (value: string | null): string | undefined => {
        if (!value || value.trim() === '') {
            setPhonError('Phone Number is required');
        }
        return undefined; // No error
    };

    useEffect(() => {
        if (phone) {
            if (phone.replace(/[\s\+\-]/g, '').length > 12 || phone.replace(/[\s\+\-]/g, '').length < 12) {
                setErrorMessage('Phone number  must have 12 numbers ');
            } else {
                setErrorMessage('');
            }
        } else {
            setErrorMessage('Phone number cannot be empty');
        }
    }, [phone]);
    
    

    return (
        <div>
              <div className=" flex min-h-[100vh flex-row justify-center  py-6 px-6  dark:bg-[#060818]  overflow-scroll">
                <div className="relative flex w-full max-w-[1502px] flex-col justify-between rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[90vh] lg:flex-row lg:gap-10 xl:gap-0">
          
                    <div className="relative flex w-full flex-col items-center justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
                        <div className="flex w-full max-w-[440px] items-center justify-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
                            <Link to="/" className="">
                                <div className=" ml-10  w-[50%] ">
                                   <img src={svg} alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="w-full max-w-[550px] ">
                            <div className="mb-10">
                                <h1 className="text-3xl font-bold  !leading-snug text-primary md:text-4xl">
                                    Register
                                </h1>
                            </div>

                            <Form<LoginInput, typeof schema>
                                schema={schema}
                                onSubmit={async (payload: UserPayload) => {
                                  
                                    
                        payload.phoneNumber = phone
                                    await handleCreate(payload);
                                }}
                            
                                className="mt-10"
                            >
                                {({ register, formState }) => (
                                    <>
                                        <div className="grid grid-cols-1 md:grid-cols-2 gap-2 ">
                                            <InputField
                                                type="text"
                                                label="First Name"
                                                placeholder="First Name"
                                                isLoading={loadingCreate}
                                                error={
                                                    formState.errors.firstName
                                                }
                                                registration={register(
                                                    'firstName'
                                                )}
                                                className="h-13"
                                            />

                                            <InputField
                                                type="text"
                                                label="Last Name"
                                                placeholder="Last Name"
                                                isLoading={loadingCreate}
                                                error={
                                                    formState.errors.lastName
                                                }
                                                registration={register(
                                                    'lastName'
                                                )}
                                                className="h-13"
                                            />

                                            <InputField
                                                type="text"
                                                label="Email"
                                                placeholder="Email"
                                                isLoading={loadingCreate}
                                                error={formState.errors.email}
                                                registration={register('email')}
                                                className="h-13"
                                            />
                                            {/* <SelectField
                                                label="Country"
                                                placeholder="Select Country"
                                                defaultValue={null}
                                                onChange={e =>
                                                    handleCountryChange(
                                                        e.target.value
                                                    )
                                                }
                                                options={countryOptions}
                                                error={formState.errors.country}
                                            /> */}
                                            <div className=''>
                                                <label htmlFor="">Phone Number </label>
                                            <PhoneInput
                                                className=" w-full"
                                                defaultCountry='RW'  
                                                onChange={(value) => {
                                                    setPhone(value.replace(/[\s\+\-]/g, '')); // Remove spaces and '+' sign
                                                }}
                                            
                                            />
                                            
                                            {errorMessage && (
                                                <div className="text-red-500 mt-3">
                                                    {errorMessage}
                                                </div>
                                            )}
                                            </div>
                                          

                                            <InputField
                                                type="password"
                                                label="Password"
                                                placeholder="********"
                                                isLoading={loadingCreate}
                                                error={
                                                    formState.errors.password
                                                }
                                                registration={register(
                                                    'password'
                                                )}
                                                className="h-13"
                                            />

                                            <InputField
                                                type="password"
                                                label="Confirm Password"
                                                placeholder="********"
                                                isLoading={loadingCreate}
                                                error={
                                                    formState.errors
                                                        .confirmPassword
                                                }
                                                registration={register(
                                                    'confirmPassword'
                                                )}
                                                className="h-13"
                                            />
                                        </div>
                                        {loadingCreate ? (
                                            <button
                                                type="submit"
                                                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase  text-white"
                                            >
                                                <CircularProgress
                                                    size={24}
                                                    sx={{ color: 'white' }}
                                                />
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                                            >
                                                Register
                                            </button>
                                        )}
                                    </>
                                )}
                            </Form>

                            <div className="mt-2 text-center capitalize dark:text-white ">
                                Already have an account ?&nbsp;
                                <Link
                                    to="/login"
                                    className="uppercase text-primary underline transition hover:text-black dark:hover:text-white"
                                >
                                    Login
                                </Link>
                            </div>
                        </div>
                        <p className="absolute bottom-6 w-full text-center dark:text-white">
                            AIMS RIC {new Date().getFullYear()} All Rights
                            Reserved.
                        </p>
                    </div>
                    <div className=" website-agency-home">
       
       <div
         className="absolute inset-0 opacity-30 bg-cover bg-center bg-opacity-50 m-0"
         style={{ backgroundImage: `url(${svg2})` }}
       />
     
      
       <div className="website-agency-home-child" />
       <div className="welcome-to-aims-wrapper">
         <h1 className="welcome-to-aims">welcome to aims</h1>
       </div>
       <h1 className="explore-exciting-opportunities">{`Explore exciting opportunities and transform your future with AIMS. `}</h1>
       <div className="join-us-on">
         Join us on a journey of discovery and innovation.
       </div>
     </div>
                </div>
            </div>
        </div>
    );
};
