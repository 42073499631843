import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FileType } from '@/core/types/file';
import { PaginationType } from '@/core/types/shared';
import { ExperienceType } from '@/core/types/experience';
import { add_files, delete_files, edit_files, get_file_types, get_filess, one_files } from '@/core/file';

export const GetfilesTypes = () => {
    const [loadingfiles, setLoadingfiles] = useState(false);
    const [files, setfiless]: any = useState<FileType[] | null>();

    const handleGetfiles = async (query?: string) => {
        try {
            setLoadingfiles(true);
            const data: PaginationType<FileType> = await get_file_types(query);
            setfiless(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingfiles(false);
        }
    };

    return {
        loadingfiles,
        files,
        handleGetfiles,
    };
};

export const Getfiless = () => {
    const [loadingfiless, setLoadingfiless] = useState(false);
    const [filess, setfiless]: any = useState<FileType[] | null>();

    const handleGetfiless = async (query?: string) => {
        try {
            setLoadingfiless(true);
            const data: PaginationType<FileType> = await get_filess(query);
            setfiless(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingfiless(false);
        }
    };

    return {
        loadingfiless,
        filess,
        handleGetfiless,
    };
};

export const GetMyfiles = () => {
    const [loadingfiles, setLoadingfiles] = useState(false);
    const [files, setfiles]: any = useState<FileType | null>();

    const handleGetfiles = async (id?: any) => {
        try {
            setLoadingfiles(true);
            const data: FileType = await one_files(id);
            setfiles(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingfiles(false);
        }
    };

    return {
        loadingfiles,
        files,
        handleGetfiles,
    };
};

export const EditfilesInfo = () => {
    const [loadingEditfiles, setLoadingEditfiles] = useState(false);

    const handleEditfiles = async (payload: FileType, id: string) => {
        try {
            setLoadingEditfiles(true);
            await edit_files(payload, id);
            toast.success('files Information updated');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditfiles(false);
        }
    };

    return {
        handleEditfiles,
        loadingEditfiles,
    };
};

export const useUploadFile = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);

    const handleUploadfiles = async (payload: FileType) => {
        try {
            setLoadingCreate(true);
            await add_files(payload);
            toast.success('File Uploaded');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
handleUploadfiles    };
};

export const useDeletefiles = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);

    const handleDeletefile = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_files(id);
            toast.success('File Deleted');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDeletefile,
    };
};
