import { send_email, send_email_test } from '@/core/api/notification';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export const useSendEmail = () => {
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);

  const handleSendEmail = async (payload: any) => {
    try {
      setLoadingSendEmail(true);
      await send_email(payload);
      toast.success('Email sent successfully');
    } catch (error: any) {
      toast.error(error.response.message || 'Failed to send email');
    } finally {
      setLoadingSendEmail(false);
    }
  };

  return {
    loadingSendEmail,
    handleSendEmail,
  };
};

export const useSendTestEmail = () => {
  const [loadingSendTestEmail, setLoadingSendTestEmail] = useState(false);

  const handleSendTestEmail = async (payload: any) => {
    try {
      setLoadingSendTestEmail(true);
      await send_email_test(payload);
      toast.success('Test email sent successfully');
    } catch (error: any) {
      toast.error(error.response.message || 'Failed to send test email');
    } finally {
      setLoadingSendTestEmail(false);
    }
  };

  return {
    loadingSendTestEmail,
    handleSendTestEmail,
  };
};
