
import Button from '@/components/button';
import Modal from '@/components/modal';
import { useDeletecademics } from '@/core/hooks/academics';
import { useDeleteExperiences } from '@/core/hooks/experience';
import { useDeleteUser } from '@/core/hooks/auth';
import { useDeleteLanguages } from '@/core/hooks/languages';
import React, { SetStateAction } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import { useDeleteQuestion } from '@/core/hooks/question';
import DeleteTopic from '../../topics/modal/delete_topic';
import { useDeleteTopic } from '@/core/hooks/topics';
import { useDeleteSupervisor } from '@/core/hooks/supervisor';

type DeleteSuperviosrModalProps = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    title: string;
    selected: any;
    refresh:  React.Dispatch<SetStateAction<boolean>>;
}

export default function DeleteSupervisor({ open, setOpen, title, selected, refresh }: DeleteSuperviosrModalProps) {
    const { loadingDeleteSupervisor, handleDeleteSupervisor } = useDeleteSupervisor();
    return (
        <Modal show={open} setShow={setOpen} title={title} >
            <div className="flex flex-col justify-center items-center">
                <div className="pb-4 max-w-[500px]">Are you sure you want to delete the Supervisor <b>{selected?.name }</b>  ?</div>
                <Button onClick={() => {
                    handleDeleteSupervisor(selected.id).then(() => {
                        setOpen(false);
                    });
                    refresh((prev) => !prev);
                }} disabled={loadingDeleteSupervisor}>{ loadingDeleteSupervisor ? <LoaderIcon /> : 'Delete' }</Button>
            </div>
        </Modal>
    );
}
