import { useAppSelector } from '@/store';
import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

export default function SupervisorLayout() {
    const location = useLocation();
    const { userAccount:user } = useAppSelector((store) => store.user);

    const link = [
        {
            to:  `/${user?.role}/supervisors`,
            title: 'Supervisors',
            active: location.pathname ===  `/${user?.role}/supersors`,
        },
       
    ];

    return (
        <>
         <div>
                        <ul className="flex space-x-2 rtl:space-x-reverse">
                            <li>
                                <Link to="/admin" className="text-primary hover:underline">
                                    Dashboard
                                </Link>
                            </li>

                            <li className="before:content-['/'] ltr:before:mr-2 rtl:before:ml-2">
                                <span>Supervisor</span>
                            </li>
                        </ul>
                    </div>
                    <Tab.Group>
            <Tab.List className="mt-3 flex flex-wrap">
                {link.map((tab, index) => (
                    <Tab as={Fragment}>
                        {() => (
                            <Link
                                to={tab.to}
                                className={`${
                                    tab.active
                                        ? "border-b !border-secondary text-secondary !outline-none"
                                        : ""
                                } -mb-[1px] flex items-center border-transparent p-5 py-3 before:inline-block hover:border-b hover:!border-secondary hover:text-secondary`}
                            >
                                {tab.title}
                            </Link>
                        )}
                    </Tab>
                ))}
            </Tab.List>
        </Tab.Group>
        <div className="mt-5">
            <Outlet />
        </div>
    </>
    );
}
