import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import { GetMyAddress, useCreateAddress } from '@/core/hooks/address';
import { AddressType } from '@/core/types/address';
import { z } from 'zod';
import IconX from '@/components/Icon/IconX';
import { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { TrashIcon } from '@heroicons/react/24/outline';
import { capitalize } from '@mui/material';
import DeletAddressModal from './modal/delete_Address';
import { UpdateAddress } from './modal/update_Address';
import DataTablev3 from '@/components/datatable/Datatable2';
import { countries } from 'countries-list';
const schema = z.object({
    country: z.string().min(1, { message: 'Country is required' }),
    street: z.string(),
    city: z.string().min(1, { message: 'City is required' }),
    type: z.string().min(1, { message: 'Address type is required' }),

});

export default function CreateAddress
() {
    const { loadingCreate, handleCreate } = useCreateAddress();
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<AddressType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = location.search;
    const { loadingAddress, handleGetAddresses, addresses } = GetMyAddress();
    const MAX_ADDRESS_COUNT = 2;

    const remainingAddressCount = MAX_ADDRESS_COUNT - (addresses?.length ?? 0);
    const canAddMoreAddresses = remainingAddressCount > 0;

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetAddresses(query);
    }, [query, refresh]);
    const columns: TableColumnV2<AddressType>[] = [
        {
            title: 'Type',
            accessor: 'type',
            render: row => <p className=''>{capitalize(row?.type)}</p>,
        },
     
        {
            title: 'Country',
            accessor: 'country',
            render: row => <p className="capitalize">{row?.country}</p>,
        },
        {
            title: 'street',
            accessor: 'street',
            render: row => <p className="capitalize ">{row?.street  || '-'}</p>,
        },
        {
            title: 'City',
            accessor: 'city',
            render: row => <p className='capitalize'>{row?.city}</p>,
        },

     
        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>
                    <UpdateAddress user={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];
    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));

    return (
        <div className="panel">
            <div className="">
                {showForm ? (
                    <Form<AddressType, typeof schema>
                    onSubmit={async (data) => {
                   
                        await handleCreate(data);
                        setRefresh(true);
                        toggleFormVisibility();
                       
                    }}
                    
                        schema={schema}
                    >
                        {({ register, formState }) => (
                            <div className="w-full">
                                <div className="px-4 flex flex-col text-sm ">
                             
                                    <div className="flex flex-row justify-between">
                                    <p className='text-lg font-semibold '>Add New Address</p>
                                        {showForm && (
                                            <button
                                                onClick={closeForm}
                                                className=" btn btn-sm btn-primary"
                                            >
                                                <IconX />
                                            </button>
                                        )}
                                    </div>
                                    <div className="flex mt-2  flex-col md:flex-row gap-2">
                                    <SelectField
                                        placeholder="Select Country"
                                        isLoading={loadingCreate}
                                        label="Country"
                                       
                                        error={formState.errors.country}
                                        options={countryOptions}
                                        registration={register('country')}
                                    />
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreate}
                                            label="Street"
                                            error={formState.errors.street}
                                            registration={register('street')}
                                        />
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreate}
                                            label="City"
                                            error={formState.errors.city}
                                            registration={register('city')}
                                        />
                                        <SelectField
                                            placeholder="Select Address Type"
                                            isLoading={loadingCreate}
                                            label="Address type"
                                            error={formState.errors.type}
                                            options={[
                                                {
                                                    value: 'mailing address',
                                                    text: 'Mailing address',
                                                },
                                                {
                                                    value: 'permanent address',
                                                    text: 'Permanent address',
                                                },
                                              
                                            ]}
                                            registration={register('type')}
                                        />
                                       
                                    </div>
                                    <div className="my-3 flex justify-end">
                                        <Button disabled={loadingCreate}>
                                            Add
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Form>
                ) : (
                    <div className='flex flex-row justify-end'>
                         <button
                        onClick={toggleFormVisibility}
                        className="btn btn-primary btn-sm "
                    >
                        New Address
                    </button>
                    </div>
                   
                )}
            </div>
            <div className="">
                <div>
                {canAddMoreAddresses ? (
                  <p className='text-red-500 font-bold text-md '> Add atleast {remainingAddressCount}  address{remainingAddressCount !== 1 ?'es' : ''}.</p> 
                ) : (
                    ''
                )}
                </div>
                <DataTablev3
            
                    columns={columns}
                    previousPage={0}
                    nextPage={0}
                    currentPage={1}
                    data={addresses ?? []}
                    total={addresses?.length ?? 0}
                    lastPage={addresses?.lastPage ?? 0}
                    isLoading={loadingAddress}
                />
                <DeletAddressModal
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete User'}
                />
            </div>
        </div>
    );
}
