import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { EditApplicationInfo } from '@/core/hooks/application';
import IconEye from '@/components/Icon/IconEye';
import { TopicType } from '@/core/types/topics';


interface MoreTopicProps {
  refresh: (prev: any) => void;
  topic: TopicType;
}

export const More: React.FC<MoreTopicProps> = ({ refresh, topic }) => {
  const [modal, setModal] = React.useState(false);
  const { loadingEditApplication, handleEditApplication } = EditApplicationInfo();

  return (
    <div className="">
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={() => setModal(true)}
          className="bg-primary  text-white px-4 py-2 rounded-full mr-2 "
        >
          <IconEye className="w-4" />
        </button>
      </div>
      <Transition appear show={modal} as={Fragment}>
        <Dialog as="div" open={modal} onClose={() => setModal(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0" />
          </Transition.Child>
          <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
            <div className="flex items-start justify-center min-h-screen px-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  as="div"
                  className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                >
                  <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                    <div className="text-lg flex flex-row justify-center w-full">
                      {topic.name}
                    </div>
                  </div>
                  <div className="p-5">
                   
                        <>
                          <div className="px-4 gap-4 text-sm mb-3">
                            <div className="flex flex-row justify-center border-b ">
                              <p className="text-lg  ">
                                <b>Descriptions</b>
                              </p>
                            </div>
                            <p className="mt-3 ">
                              {topic?.description }
                            </p>
                            <div>
                              <div className="flex flex-row justify-center border-b ">
                                <p className="text-lg ">
                                  <b>Instructions</b>
                                </p>
                              </div>
                              <div
                                className="mt-3"
                                dangerouslySetInnerHTML={{
                                  __html: topic.instruction_note || '',
                                }}
                              />
                            </div>
                          </div>
                        </>
                      
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
