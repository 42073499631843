import z from 'zod';
import { Link, useNavigate } from 'react-router-dom';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import svg from '@/assets/aims_logo_2.png';
import svg2 from '@/assets/img2.jpg';
import CircularProgress from '@mui/material/CircularProgress';
import { RequestOtp, ResetPassword, useLoginUser } from '@/core/hooks/auth';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { useAppSelector } from '@/store';
import { useEffect, useState } from 'react';
import { storage } from '@/core/utils';
import { check_user } from '@/core/api/auth';
import { CheckUser } from '@/core/utils/UseUser';
import { addUser } from '@/store/slices/userslice';
import { useDispatch } from 'react-redux';
import { setPageTitle } from '@/store/themeConfigSlice';

const schema = z.object({
    username: z
    .string()
    .min(1, 'user Name required')
    .refine((value) => value.trim() === value, {
        message: 'username  cannot have leading or trailing spaces',
    }),
});

export type ResetInput = z.TypeOf<typeof schema>;


export const RequestOtpSend = () => {
 
    const dispatch = useDispatch();
const { loadingReset,handleReset}=RequestOtp()

useEffect(() => {
    dispatch(setPageTitle('Reset password'));
}, [dispatch]);

    return (
        <div>
            <div className=" flex h-[100vh] flex-row justify-center  py-10 dark:bg-[#060818]  overflow-none">
                <div className="relative flex  w-full max-w-[1502px] flex-row  justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[90vh] lg:flex-row lg:gap-10 xl:gap-0">
         
                    <div className="relative flex w-full flex-col items-center justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
                    <div className="flex w-full max-w-[440px] items-center justify-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
                            <Link to="/" className="">
                                <div className="lg:ml-20 ml-10  w-full  lg:w-[50%] ">
                                   <img src={svg} alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="w-full max-w-[440px] lg:mt-16">
                            <div className="mb-10">
                                <h1 className="text-xl font-bold  !leading-snug text-primary md:text-4xl">
                                    Reset Password
                                </h1>
                                <p className="text-base  leading-normal text-white-dark">
                                    Enter your username to reset your password
                                </p>
                            </div>

                            <Form<ResetInput, typeof schema>
                                schema={schema}
                                onSubmit={(payload: any) =>
                                    handleReset(payload)
                                }
                                className="mt-10"
                            >
                                {({ register, formState }) => (
                                    <>
                                        <div className="space-y-4">
                                            <InputField
                                                type="text"
                                                label="Username"
                                                placeholder="Username"
                                                isLoading={loadingReset}
                                                
                                                error={
                                                    formState.errors.username
                                                }
                                                registration={register(
                                                    'username'
                                                )}
                                                className="h-13"
                                            />
                                        </div>
                                        {loadingReset ? (
                                            <button
                                                type="submit"
                                                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase  text-white"
                                            >
                                                <CircularProgress
                                                    size={24}
                                                    sx={{ color: 'white' }}
                                                />
                                            </button>
                                        ) : (
                                            <button
                                                type="submit"
                                                className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                                            >
                                                Continue
                                            </button>
                                        )}
                                    </>
                                )}
                            </Form>

                            <div className="mt-2 text-center capitalize dark:text-white ">
                                <Link
                                    to="/login"
                                    className=" text-primary underline transition hover:text-black dark:hover:text-white"
                                >
                                    Back To login
                                </Link>
                            </div>
                        </div>                    
                        <p className="absolute bottom-6 w-full text-center dark:text-white">
                            AIMS RIC {new Date().getFullYear()} All Rights
                            Reserved.
                        </p>
                    </div>
                    <div className=" website-agency-home">
       
       <div
         className="absolute inset-0 opacity-30 bg-cover bg-center bg-opacity-50 m-0"
         style={{ backgroundImage: `url(${svg2})` }}
       />
     
      
       <div className="website-agency-home-child" />
       <div className="welcome-to-aims-wrapper">
         <h1 className="welcome-to-aims">welcome to aims</h1>
       </div>
       <h1 className="explore-exciting-opportunities">{`Explore exciting opportunities and transform your future with AIMS. `}</h1>
       <div className="join-us-on">
         Join us on a journey of discovery and innovation.
       </div>
     </div>
                </div>
            </div>
        </div>
    );
};

export default RequestOtpSend;
