
import Button from '@/components/button';
import Modal from '@/components/modal';
import { useDeletecademics } from '@/core/hooks/academics';
import { useDeleteExperiences } from '@/core/hooks/experience';
import { useDeleteReferences } from '@/core/hooks/Reference';
import { useDeleteUser } from '@/core/hooks/auth';
import React, { SetStateAction } from 'react';
import { LoaderIcon } from 'react-hot-toast';

type DeleteUserModalProps = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    title: string;
    selected: any;
    refresh:  React.Dispatch<SetStateAction<boolean>>;
}

export default function DeleteUserRefs({ open, setOpen, title, selected, refresh }: DeleteUserModalProps) {
    const { handleDeleteReference, loadingDelete } = useDeleteReferences();
    return (
        <Modal show={open} setShow={setOpen} title={title} >
            <div className="flex flex-col justify-center items-center">
                <div className="pb-4 max-w-[500px]">Are you sure you want to delete Reference  <b>{selected?.firstname } {selected?.lastName}</b>  ?</div>
                <Button onClick={() => {
                    handleDeleteReference(selected.id).then(() => {
                        setOpen(false);
                    });
                    refresh((prev) => !prev);
                }} disabled={loadingDelete}>{ loadingDelete ? <LoaderIcon /> : 'Delete' }</Button>
            </div>
        </Modal>
    );
}
