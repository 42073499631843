
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import { useAppDispatch } from '@/store';
import { addUser, removeUser } from '@/store/slices/userslice';
import { storage } from '@/core/utils';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

import { AddressPayload } from '@/core/types/address';

import { ExperiencePayload, ExperienceType } from '@/core/types/experience';
import { add_experience, delete_experience, edit_experience, get_experinces, one_experience } from '@/core/api/experience';

export const GetExperiences = () => {
    const [loadingExperiences, setLoadingExperiences] = useState(false);
    const [experiences, setExperiences]: any = useState<PaginationType<UserType> | null>();

    const handleGetExperiences = async (query?: string, search?: string) => {
        try {
            setLoadingExperiences(true);
            const data: PaginationType<ExperienceType> = await get_experinces(query);
            setExperiences(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingExperiences(false);
        }
    };

    return {
        loadingExperiences,
        experiences,
        handleGetExperiences,
    };
};

export const GetMyExperiences = () => {
    const [loadingExperiences, setLoadingExperiences] = useState(false);
    const [experiences, setExperiences]: any = useState<PaginationType<ExperienceType> | null>();

    const handleGetExperiences = async (id?: any) => {
        try {
            setLoadingExperiences(true);
            const data: PaginationType<ExperienceType> = await one_experience(id);
            setExperiences(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingExperiences(false);
        }
    };

    return {
        loadingExperiences,
        experiences,
        handleGetExperiences,
    };
};

export const EditExperienceInfo = () => {
    const [loadingEditExperience, setLoadingEditExperience] = useState(false);

    const handleEditExperience = async (payload: ExperiencePayload, id: string) => {
        try {
            setLoadingEditExperience(true);
            await edit_experience(payload, id);
            toast.success('Experience Information updated');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditExperience(false);
        }
    };

    return {
        handleEditExperience,
        loadingEditExperience,
    };
};

export const useCreateExperiences = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);

    const handleCreateExperience = async (payload: ExperiencePayload) => {
        try {
            setLoadingCreate(true);
            await add_experience(payload);
            toast.success('Experience information added');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreateExperience,
    };
};

export const useDeleteExperiences = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);

    const handleDeleteExperience = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_experience(id);
            toast.success('Experience information deleted');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDeleteExperience,
    };
};
