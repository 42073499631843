import React, { useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Button from '@/components/button';
import { SelectField } from '@/components/input';
import { AddSubCategory, GetCategories } from '@/core/hooks/category';
import { set } from 'lodash';
import { CategoryPayload } from '@/core/types/category';

interface AddCategoryProps {
    refresh: (prev: any) => void;
    data: any;
}

export const AddSubCategories: React.FC<AddCategoryProps> = ({
    refresh,
    data,
}) => {
    const [modal, setModal] = React.useState(false);
    const { loadingAddSubCategory, handleAddSubcategory } = AddSubCategory();
    const [SubcategoryId, setSubcategoryId] = useState<any>('')
   
   
    const categoryId = data?.id
    
    const payload:any = {
        categoryId: categoryId.toString(),
        SubcategoryId: SubcategoryId,
      };
      
    const submit = async (e: any) => {
        e.preventDefault();
     await handleAddSubcategory(payload);
        refresh((prev: any) => !prev);
        setModal(false);
    };
    const { loadingCategories, handleGetCategories, categories } =
    GetCategories();

useEffect(() => {
    handleGetCategories();
}, []);

    return (
        <div className="">
            <div className="flex items-center justify-center">
            <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="btn btn-primary btn-sm"
                >
              Add  Sub category
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Add Sub category
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <form onSubmit={submit}>
                                            <div className="px-4 grid grid-cols-2 gap-4 text-sm mb-3">
                                               
                                                              <SelectField
                                    placeholder="Select   Sub Category"
                                    isLoading={
                                        loadingAddSubCategory ||
                                        loadingCategories
                                    }
                                   
                                    label=" Sub Category"

                                    onChange={e =>
                                        setSubcategoryId(e.target.value)
                                    }
                                    options={(categories?.list || [])
                                        .filter(
                                            (topic: { status: string }) =>
                                                topic.status === 'active'
                                        )
                                        .map((app: { id: any; name: any }) => ({
                                            value: app?.id,
                                            text: app?.name,
                                        }))}
                                />

                                            </div>
                                            <div className="my-3 flex justify-end">
                                                <Button>Submit</Button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
