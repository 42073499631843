import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import Filter from '@/components/filters/Filter';
import { GetIncompleteApplications } from '@/core/hooks/application';
import {
    ApplicationType,
    ApplicationFilterPayload,
} from '@/core/types/application';
import { UserType } from '@/core/types/auth';
import { useAppSelector } from '@/store';
import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

export default function IncompleteApplications() {
    const { applicationId }: any = useParams();
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<ApplicationType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<ApplicationFilterPayload>({
        from: '',
        to: '',
        userId: '',
    });
    const query = location.search;

    const { userAccount: user }: any = useAppSelector(store => store.user);
    const { loadingApplications, handleGetApplications, applications } =
        GetIncompleteApplications();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetApplications(applicationId, query);
    }, [query]);

    const columns: TableColumnV2<UserType>[] = [
        // {
        //     title: 'Application',
        //     accessor: 'application.title',
        //     render: row => (
        //         <p className="capitalize">{row?.title}</p>
        //     ),
        // },
        // {
        //     title: 'Description',
        //     accessor: 'application.description',
        //     render: row => (
        //         <p className="capitalize whitespace-wrap">
        //             {row?.description?.slice(0, 50)}
        //             {row?.description &&
        //                 row?.description.length > 50 &&
        //                 '...'}
        //         </p>
        //     ),
        // },

        // {
        //     title: 'Application deadline',
        //     accessor: 'application.ending_date',
        //     render: row => (
        //         <p>{formatDateToLongForm(row?.ending_date)}</p>
        //     ),
        // },
        {
            title: ' Name',
            accessor: 'firstName',
            render: row => (
                <p className="capitalize whitespace-wrap">
                    {row?.firstName} {row?.lastName}
                </p>
            ),
        },
        {
            title: ' Email',
            accessor: 'email',
            render: row => (
                <p className="capitalize whitespace-wrap">{row?.email}</p>
            ),
        },
        {
            title: ' Nationality',
            accessor: 'personalInformation.Nationality',
            render: row => (
                <p className="capitalize whitespace-wrap">
                    {' '}
                    {row?.personalInformation[0]?.Nationality ?? '-'}
                </p>
            ),
        },

        {
            title: ' status',
            accessor: 'row.user.status',
            render: row => {
                let badgeColor;
                switch (row?.status) {
                    case 'active':
                        badgeColor = 'success';
                        break;
                    case 'inactive':
                        badgeColor = 'danger';
                        break;
                    case 'assigned':
                        badgeColor = 'blue';
                        break;
                    case 'pending':
                        badgeColor = 'warning';
                        break;
                    default:
                        badgeColor = 'gray';
                }
                return (
                    <span className={` badge bg-${badgeColor}`}>
                        {row?.status}
                    </span>
                );
            },
        },
    ];

    return (
        <>
            <div>
                <div className="my-4 ">
                    <p className="text-lg font-bold ">
                        Applicants with incomplete applications{' '}
                    </p>
                </div>
            </div>
            <div className="panel py-4 ">
                <div className="">
                    {user.role === 'admin' && (
                        <DataTableV2
                            tableName="Incomplete Applications"
                            columns={columns}
                            previousPage={applications?.previousPage ?? 0 }
                            nextPage={applications?.nextPage ?? 0}
                            currentPage={applications?.currentPage ?? 1}
                            data={applications?.list ?? []}
                            total={applications?.total ?? 0}
                            lastPage={applications?.lastPage ?? 0}
                            isLoading={loadingApplications}
                            header={
                                <div className="w-full flex my-3 justify-end items-center">
                                    <Filter
                                        filters={filters}
                                        data={applications}
                                        onFilter={(
                                            payload: ApplicationFilterPayload
                                        ) => {
                                            setFilters(payload);
                                        }}
                                    />
                                </div>
                            }
                        />
                    )}

                    {/* <DeletApplications
        refresh={setRefresh}
        selected={selected}
        open={open}
        setOpen={setOpen}
        title={'Delete Applications'}
    /> */}
                </div>
            </div>
        </>
    );
}
