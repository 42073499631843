import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios';

import { PaginationType } from '@/core/types/shared';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { queryString } from '@/core/utils/query';
import { AddressPayload } from '@/core/types/address';
import { AcademicInfoPayload, AcademicInfoType } from '@/core/types/academic';



export const add_academic = async (
    payload: AcademicInfoPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/academic', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_academics = async (payload : AcademicInfoPayload, id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/academic/${id}`,payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const one_academic = async (id:string): Promise<AcademicInfoType | any> => {
    try {
        const request = await PRIVATE_API.get(`academic/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_academic = async (id: string): Promise<AcademicInfoType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/academic/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


export const getAcademics = async (query? : string): Promise<PaginationType<AcademicInfoType>> => {
    try {
        const request = await PRIVATE_API.get(`/academic${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


