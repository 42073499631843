import React, { useEffect } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { useState, Fragment } from 'react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import IconPlus from '@/components/Icon/IconPlus';
import { z } from 'zod';
import { useNavigate } from 'react-router-dom';
import { EditUser, useCreateUser } from '@/core/hooks/auth';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import { edit_user } from '@/core/api/auth';
import IconPencil from '@/components/Icon/IconPencil';

const schema = z.object({
     email: z
            .string()
            .min(1, 'Email Name required')
            .refine((value) => value.trim() === value, {
                message: 'Email cannot have leading or trailing spaces',
            }),
      
    firstName: z.string().min(1, { message: 'First name is required' }),
    lastName: z.string().min(1, { message: 'Last name is required' }),
    phoneNumber: z.string().min(1, { message: 'Phone Number is required' }),

});

export type RegisterInput = z.TypeOf<typeof schema>;

export const Updatemoderator = ({ refresh, user }: any) => {
    const [modal1, setModal1] = useState(false);
    const navigate = useNavigate();
    const { loadingEditUser, hanldeEditUser } = EditUser();
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="bg-warning text-gray-200 p-1 rounded"
                >
                    <IconPencil className="w-4" />
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Update User
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<RegisterInput, typeof schema>
                                            schema={schema}
                                            onSubmit={async (data: any) => {
                                                data.status = 'active'
                                                await hanldeEditUser(
                                                    data,
                                                    user.id
                                                );
                                                refresh((prev: any) => !prev);
                                                setModal1(false);
                                            }}
                                            className="mt-3  "
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                                                        <div className="flex flex-col md:flex-row gap-2">
                                                            <InputField
                                                                type="email"
                                                                isLoading={
                                                                    loadingEditUser
                                                                }
                                                                label="Email"
                                                                defaultValue={
                                                                    user.email
                                                                }
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .email
                                                                }
                                                                registration={register(
                                                                    'email'
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="flex flex-col md:flex-row gap-2">
                                                            <InputField
                                                                type="text"
                                                                isLoading={
                                                                    loadingEditUser
                                                                }
                                                                label="First Name"
                                                                defaultValue={
                                                                    user.firstName
                                                                }
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .firstName
                                                                }
                                                                registration={register(
                                                                    'firstName'
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="flex flex-col md:flex-row gap-2">
                                                            <InputField
                                                                type="text"
                                                                isLoading={
                                                                    loadingEditUser
                                                                }
                                                                label="Last Name"
                                                                defaultValue={
                                                                    user.lastName
                                                                }
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .lastName
                                                                }
                                                                registration={register(
                                                                    'lastName'
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="flex flex-col md:flex-row gap-2">
                                                            <InputField
                                                                type="text"
                                                                isLoading={
                                                                    loadingEditUser
                                                                }
                                                                defaultValue={
                                                                    user.phoneNumber
                                                                }
                                                                label="Phone number"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .phoneNumber
                                                                }
                                                                registration={register(
                                                                    'phoneNumber'
                                                                )}
                                                            />
                                                        </div>
                                                        
                                                        
                                                    </div>

                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingEditUser
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
