// src/core/api/answers.ts
import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios';
import { AnswerPayload, AnswerType } from '@/core/types/answers';
import { queryString } from '@/core/utils/query';
import { PaginationType } from '@/core/types/shared';

export const addAnswer = async (payload: AnswerPayload): Promise<any> => {
  try {
    const request = await PRIVATE_API.post('/answer', payload);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const editAnswer = async (payload: AnswerPayload, id: string): Promise<any> => {
  try {
    const request = await PRIVATE_API.patch(`/answer/${id}`, payload);
    return await request.data;
  } catch (error) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const getAnswers = async (    query?: string):Promise<PaginationType<AnswerType>> => {
  try {
    const request = await PRIVATE_API.get(`/answer${queryString(query)}`);
    return await request.data;
  } catch (error) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const getAnswer = async (id: string): Promise<AnswerType | any> => {
  try {
    const request = await PRIVATE_API.get(`/answer/${id}`);
    return await request.data;
  } catch (error: any) {
    throw new  CustomError(AxiosErrorHandler(error));
  }
};

export const deleteAnswer = async (id: string): Promise<any> => {
  try {
    const request = await PRIVATE_API.delete(`/answer/${id}`);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};
