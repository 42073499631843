import { useState } from 'react';
import { Tab } from '@headlessui/react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import IconUser from '@/components/Icon/IconUser';
import IconClock from '@/components/Icon/IconClock';
import IconListCheck from '@/components/Icon/IconListCheck';
import { AcademicCapIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline';
import IconMenuWidgets from '@/components/Icon/Menu/IconMenuWidgets';
import IconMenuCalendar from '@/components/Icon/Menu/IconMenuCalendar';
import IconMicrophoneOff from '@/components/Icon/IconMicrophoneOff';
import IconMenuApps from '@/components/Icon/Menu/IconMenuApps';
import { useAppSelector } from '@/store';

const ProfileLayout = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0); 
    const navigate = useNavigate();
    const { userAccount:user } = useAppSelector((store) => store.user);

   
    const links = [
        {
            to:  `/${user?.role}/profile`,
            icon: <IconUser />,
            title: 'Personal ',
            index: 0, 
        },
        {
            to:  `/${user?.role}/profile/address`,
            icon: <IconClock />,
            title: 'Address',
            index: 1,
        },
        {
            to:  `/${user?.role}/profile/academic-information`,
            icon: <IconMenuCalendar />,
            title: 'Academics',
            index: 2,
        },
        {
            to:  `/${user?.role}/profile/experience`,
            icon: <IconMenuWidgets />,
            title: 'Experience',
            index: 3,
        },
        {
            to: `/${user?.role}/profile/language`,
            icon: <IconMenuApps />,
            title: 'Languages',
            index: 4,
        },
        {
            to:  `/${user?.role}/profile/reference`,
            icon: <IconClock />,
            title: 'Reference',
            index: 5,
        },
    ];

    return (
        <div className="w-full">
                <div className=" my-2 ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <Link  to = '/account/applicant' className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                            Home
                        </Link>
                    </li>

                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            Update  Profile
                        </button>
                    </li>
                </ol>
            </div>
           
            <div className="mt-4">
                <Outlet />
            </div>
          
          
        </div>
    );
};

export default ProfileLayout;
