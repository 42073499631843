import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import {
    GetMyAddress,
    getAddresses,
    useCreateAddress,
} from '@/core/hooks/address';
import { AddressType } from '@/core/types/address';
import { z } from 'zod';
import IconX from '@/components/Icon/IconX';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { FunnelIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';

import {
    GetApplications,
    GetSubmittedApplications,
    SubmitApplication,
    useApplyApplication,
} from '@/core/hooks/application';
import {
    ApplicationFilterPayload,
    ApplicationType,
} from '@/core/types/application';

import DataTablev3 from '@/components/datatable/Datatable2';
import { UserFilterPayload, UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import Modal from '@/components/modal';
import { useGetAllApplicants, useGetAllUsers } from '@/core/hooks/auth';
import IconEye from '@/components/Icon/IconEye';
import UserDatas from './More';

export default function Applicants() {
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<ApplicationType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<ApplicationFilterPayload>({
        from: '',
        to: '',
        userId: '',
    });
    const query = location.search;

    const { loadingSubmit, submitApplication } = SubmitApplication();
    const handleApply = (application_id: any) => {
        submitApplication(application_id);
    };

    const { userAccount: user }: any = useAppSelector(store => store.user);
    const { loadingUsers, handleApplicants, applicants } =
        useGetAllApplicants();

    useEffect(() => {
        handleApplicants(query);
    }, [query, refresh]);

    const columns: TableColumnV2<UserType>[] = [
       
        {
            title: 'First Name',
            accessor: 'firstName',
            render: row => <p className="capitalize">{row.firstName}</p>,
        },
        {
            title: 'Last Name',
            accessor: 'lastName',
            render: row => <p className="capitalize ">{row.lastName}</p>,
        },
        {
            title: 'Email',
            accessor: 'email',
            render: row => <p>{row.email}</p>,
        },
     
        {
            title: 'Phone Number',
            accessor: 'phoneNumber',
            render: row => <p>{row.phoneNumber}</p>,
        },
        {
            title: 'Role',
            accessor: 'role',
            render: row => <p>{row.role}</p>,
        },
         
        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p>
                    {row?.status === '1' || row?.status === 'active' ? (
                        <span className="badge bg-success">Active</span>
                    ) : (
                        <span className="badge bg-danger">Inactive</span>
                    )}
                </p>
            ),
        },
        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: (row) => (
              <p>{formatDateToLongForm(row?.created_at)}</p>
            ),
          },
          {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <p>
                    <UserDatas user = {row}/>
                </p>
            ),
        },
    ];

    return (
        <><div>
         
            <div className="my-4 ">
                <p className="text-lg font-bold ">Applicants </p>
            </div>
        </div><div className="panel py-4 ">
                <div className="">
                    <DataTableV2
                    tableName='Applicants'
                        columns={columns}
                        previousPage={applicants?.previousPage ?? 0}
                        nextPage={applicants?.nextPage ?? 0}
                        currentPage={applicants?.currentPage ?? 0}
                        data={applicants?.list ?? []}
                        total={applicants?.total ?? 0}
                        lastPage={applicants?.lastPage ?? 0}
                        isLoading={loadingUsers}
                        header={<div className="w-full flex my-3 justify-end items-center">
                            <Filter
                                filters={filters}
                                data={applicants?.list}
                                onFilter={(
                                    payload: ApplicationFilterPayload
                                ) => {
                                    setFilters(payload);
                                } } />
                        </div>} />

                    {/* <DeletApplications
        refresh={setRefresh}
        selected={selected}
        open={open}
        setOpen={setOpen}
        title={'Delete Applications'}
    /> */}
                </div>
            </div></>
    );
}

type FilterProps = {
    filters: ApplicationFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<ApplicationType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        userId: z.string().optional(),
    });

    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
    };
    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, to, userId } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (userId) searchParams.set('userId', userId);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };
    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };

    const query = location.search;
    const { loadingUsers, handleGetUsers, users } = useGetAllUsers();
    useEffect(() => {
        handleGetUsers(query);
    }, []);

    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<ApplicationFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="grid grid-cols-1">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />
                                <SelectField
                                    placeholder="Select User"
                                    isLoading={loadingUsers}
                                    label="User"
                                    error={formState.errors.userId}
                                    options={(users?.list || [])
                                        .filter(
                                            (user: {
                                                status: string;
                                                role: string;
                                            }) =>
                                                user.status === 'active' &&
                                                user.role === 'admin'
                                        )
                                        .map(
                                            (user: {
                                                id: any;
                                                firstName: any;
                                                lastName: any;
                                            }) => ({
                                                value: user?.id,
                                                text: `${user?.firstName} ${user?.lastName}`,
                                            })
                                        )}
                                    registration={register('userId')}
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
            {/* <PrintModal
				title={"Print List of Events"}
				open={openPrint}
				setOpen={setOpenPrint}
				data={data}
			/> */}
        </>
    );
}
