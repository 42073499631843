import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API, { PRIVATE_API_v2 } from '../axios';
import { PaginationType } from '@/core/types/shared';
import { UserType } from '@/core/types/auth';
import { queryString } from '@/core/utils/query';
import { ApplicationPayload, ApplicationType } from '@/core/types/application';

export const add_application = async (
    payload: ApplicationPayload
  ): Promise<any> => {
    try {
      const request = await PRIVATE_API.post('/application', payload);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };
  
 
export const assign_application = async (
  payload: ApplicationPayload
): Promise<any> => {
  try {
    const request = await PRIVATE_API.post('/application/assign-evaluator-to-topics', payload);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
}; 
export const assign_application_modulator = async (
  payload: any
): Promise<any> => {
  try {
    const request = await PRIVATE_API_v2.post('/evaluation/assign-modulator-on-application', payload);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
}; 
export const assign_application_academic_manager = async (
  payload: any
): Promise<any> => {
  try {
    const request = await PRIVATE_API_v2.post('/evaluation/assign-academic-manager-on-application', payload);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
}; 

  export const edit_application = async (
    payload: ApplicationPayload,
    id: string
  ): Promise<any> => {
    try {
      const request = await PRIVATE_API.patch(`/application/${id}`, payload);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };
  
  export const one_application = async (
    id: string
  ): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API.get(`/application/${id}`);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };

  export const modulator_applications = async (
    id?: string
  ): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API_v2.get(`/evaluation/modulator/applications?modulatorId=${id}`);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };
  
  
  export const delete_application = async (
    id: string
  ): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API.delete(`/application/${id}`);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };
  export const activate_application = async (
    id: string
  ): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API.post(`/application/activate/${id}`);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };
  
  export const get_applications = async (
    query?: string,pageSize?:number
  ): Promise<PaginationType<ApplicationType>> => {
    try {
      const request = await PRIVATE_API.get(`/application${queryString(query)}`);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };

  export const get_application_by_id = async (id: string): Promise<ApplicationType> => {
    try {
      const request = await PRIVATE_API.get(`/application/${id}`);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };
  export const apply_application = async (
    applicationId: string
  ): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API.post(`/application/apply`,{applicationId});
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };

  export const submit_application = async (
    applicationId: string
  ): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API.post(`/submit`,{applicationId});
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };
  export const get_submitted_application = async (
    query?: string
  ): Promise<PaginationType<ApplicationType>> => {
    try {
      const request = await PRIVATE_API.get(`/submit${queryString(query)}`);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };

  export const get_application_stats = async (
    dateRange: string = 'monthly', // Default to 'monthly' if not specified
    applicationId?: string
): Promise<PaginationType<ApplicationType>> => {
    try {
        let url = `/submit/admin/statistics`;
        if (dateRange) {
            url += `?dateRange=${dateRange}`;
        }
        if (applicationId) {
            url += `&applicationId=${applicationId}`;
        }
        const request = await PRIVATE_API.get(url);
        return request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

  
  export const get_incomplete_applications = async (
    applicationId:string, query?: string,
  ): Promise<PaginationType<ApplicationType>> => {
    try {
      const request = await PRIVATE_API.get(`/application/${applicationId}/pending-applications${queryString(query)}`);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };

  

  export const get_active_application = async (
    query?: string
  ): Promise<PaginationType<ApplicationType>> => {
    try {
      const request = await PRIVATE_API.get(`/application/active/${queryString(query)}`);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };


  export const get_details_user= async (
    userId: string,
    query?: string,
  ): Promise<PaginationType<ApplicationType>> => {
    try {
      const request = await PRIVATE_API.get(`/submit/${userId}${query ? queryString(query) : ''}`);
      return request.data;
    } catch (error) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };
  
  export const get_details_profile= async (
    userId: string,
    query?: string,
  ): Promise<PaginationType<ApplicationType>> => {
    try {
      const request = await PRIVATE_API.get(`/submit/userInfo/${userId}${query ? queryString(query) : ''}`);
      return request.data;
    } catch (error) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };

  export const my_applications = async (): Promise<ApplicationType | any> => {
    try {
        const request = await PRIVATE_API.get('/application/my-applications');
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_statistics = async (): Promise<UserType | any> => {
  try {
      const request = await PRIVATE_API.get('/submit/statistic');
      return await request.data;
  } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
  }
};

export const get_admin_statistics = async (applicationId?: string, dateRange?: string): Promise<UserType | any> => {
  try {
      // Construct the URL with optional query parameters
      let url = `/stats/admin/?dateRange=${dateRange || 'monthly'}`;
      
      // If applicationId is available, append it to the URL
      if (applicationId) {
          url += `&applicationId=${applicationId}`;
      }

      const request = await PRIVATE_API_v2.get(url);
      return request.data;
  } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
  }
}


export const get_nationality_stats = async ( nationality?: string,applicationId?: string,): Promise<UserType | any> => {
  try {
      
      let url = `/submit/stats/nationality/?nationality=${nationality}`;
      if (applicationId) {
          url += `&applicationId=${applicationId}`;
      }

      const request = await PRIVATE_API.get(url);
      return request.data;
  } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
  }
}
