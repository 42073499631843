import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios';
import { PaginationType } from '@/core/types/shared';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { queryString } from '@/core/utils/query';
import { RegisterInput } from '@/app/dashboard/users/modals/Edit_user_modal';

export type RegisterUserPayload = {
    access_token: string;
};

export type LoginUserPayload = {
    access_token: string;
};

export const register_user = async (
    memberPayload: UserPayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/user', memberPayload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


export const admin_create_user = async (
    memberPayload: RegisterInput
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/user/admin', memberPayload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_user = async (payload : UserPayload, id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/user/${id}`,payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const check_user = async (): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.get('/user/check');
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_user = async (id: string): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/user/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const activate_user = async (activation_code:string): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.post(`/user/activate/account/${activation_code}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const request_reset= async (payload:UserPayload): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.post(`/user/password-reset/`,payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const Confirm_reset = async (payload:UserPayload): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.post(`/user/confirm-password-reset-otp`,payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_users = async (query? : string): Promise<PaginationType<UserType>> => {
    try {
        const request = await PRIVATE_API.get(`/user${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const get_applicants = async (query? : string): Promise<PaginationType<UserType>> => {
    try {
        const request = await PRIVATE_API.get(`/user/applicant${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const user_profile = async (): Promise<PaginationType<UserType>> => {
    try {
        const request = await PRIVATE_API.get(`/user/myProfile`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const login_user = async (
    userCredentials: AuthLoginPayload
): Promise<LoginUserPayload | any> => {
    try {
        const request = await PRIVATE_API.post('/user/login', userCredentials);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};