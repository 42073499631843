
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import {
    GetApplications,
    useCreateApplications,
} from '@/core/hooks/application';
import { GetCategories } from '@/core/hooks/category';
import { useCreateTopic } from '@/core/hooks/topics';
import { ApplicationPayload } from '@/core/types/application';
import { TopicPayload } from '@/core/types/topics';
import React, { Fragment, useEffect } from 'react';

import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { z } from 'zod';
import Applications from '../../applications';
import { SuggestedTopicPayload } from '@/core/types/suggested-topics';
import { useCreateSuggestedTopic } from '@/core/hooks/suggested-topics';
import { Dialog, Transition } from '@headlessui/react';
import IconPlus from '@/components/Icon/IconPlus';
import IconPencil from '@/components/Icon/IconPencil';

const SuggestedTopicShema = z.object({
    title: z.string().min(1, { message: 'Topic Tilte is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
    applicationId: z.string().min(1, { message: 'Application is required' }),
    categoryId: z.string().min(1, { message: 'Category is required' }),
});
export default function UpdateSuggestedTopic({refresh,topic}:any) {
    const [modal, setModal] = React.useState(false);
    const { loadingCreateSuggestedTopic, handleCreateSuggestedTopic } = useCreateSuggestedTopic(); 
    const { loadingApplications, handleGetApplications, applications } =
        GetApplications();

    const { loadingCategories, handleGetCategories, categories } =
        GetCategories();

    const query = location.search;
    console.log(query);
    useEffect(() => {
        handleGetApplications(query);
        handleGetCategories(query);
    }, [query]);

    const [categoryData, setCategoryData] = useState<any>({
        name: '',
        description: '',
        skills_required: [{ name: '' }],
    });
    
    const handleInputChange = (
        field: string,
        value: string,
        index?: number
    ) => {
        console.log('Before State Update:', categoryData);
        setCategoryData((prev: { skills_required: any[] }) => {
            if (index !== undefined && prev.skills_required[index]) {
                const updatedSkills = [...prev.skills_required];
                updatedSkills[index] = { name: value };
                return { ...prev, skills_required: updatedSkills };
            } else {
                return { ...prev, [field]: value };
            }
        });
        console.log('After State Update:', categoryData);
    };

    
    const addSkill = () => {
        setCategoryData((prev: { skills_required: any }) => ({
            ...prev,
            skills_required: [...prev.skills_required, { name: '' }],
        }));
    };

    const removeSkill = (index: number) => {
        setCategoryData((prev: { skills_required: any }) => {
            const updatedSkills = [...prev.skills_required];
            updatedSkills.splice(index, 1);
            return { ...prev, skills_required: updatedSkills };
        });
    };
    useEffect(() => {
        setCategoryData({
            skills_required: topic.skills_needed.map(
                (skill: { name: any }) => ({ name: skill.name })
            ),
        });
    }, [topic]);
    return (
        <div>
           <div className="">
            <div className="flex items-center justify-center">
            <button
                        type="button"
                        onClick={() => setModal(true)}
                        className="bg-warning text-gray-200 p-1 rounded"
                    >
                        <IconPencil className="w-4" />
                    </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Add Category
                                        </div>
                                    </div>
                                    <div className="p-5">
                                    <Form<SuggestedTopicPayload, typeof SuggestedTopicShema>
                schema={SuggestedTopicShema}
                onSubmit={async data => {
                  data.skills_needed = categoryData.skills_required
                    await handleCreateSuggestedTopic(data);
                    refresh(
                        (prev: any) => !prev
                    );
                    setModal(false)
                }}
                className="mt-3"
            >
                {({ register, formState }) => (
                    <>
                        <div className="px-4 grid grid-cols-2 gap-4 text-sm mb-3">
                            <InputField
                                type="text"
                                isLoading={loadingCreateSuggestedTopic}
                                placeholder="Topic title"
                                label="Topic Name"
                                defaultValue={topic?.title}

                                error={formState.errors.title}
                                registration={register('title')}
                            />
                           
                            <InputField
                                type="text"
                                isLoading={loadingCreateSuggestedTopic}
                                placeholder="Description"
                                label="Description"
                            defaultValue={topic?.description}
                                error={formState.errors.description}
                                registration={register('description')}
                            />
                           
                            <SelectField
                                placeholder="Select Application"
                                isLoading={loadingCreateSuggestedTopic || loadingApplications}
                                label="Application"
                                defaultValue={topic.application?.id}
                                error={formState.errors.applicationId}
                                options={(applications?.list || []).map(
                                    (app: { id: any; title: any }) => ({
                                        value: app?.id,
                                        text: app?.title,
                                    })
                                )}
                                registration={register('applicationId')}
                            />
                            <SelectField
                                placeholder="Select Category"
                                isLoading={loadingCreateSuggestedTopic || loadingCategories}
                                label="Category"
                                defaultValue={topic?.category?.id}
                                error={formState.errors.categoryId}
                                options={(categories?.list || [])?.map(
                                    (category: { id: any; name: any }) => ({
                                        value: category?.id,
                                        text: category?.name,
                                    })
                                )}
                                registration={register('categoryId')}
                            />
                              <div>
                                                    <label
                                                        htmlFor="skills"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Skills Required
                                                    </label>
                                                    {categoryData?.skills_required?.map(
                                                        (
                                                            skill: any,
                                                            index: any
                                                        ) => (
                                                            <div
                                                                key={index}
                                                                className="flex items-center mt-2"
                                                            >
                                                                <InputField
                                                                    type="text"
                                                                    isLoading={
                                                                        loadingCreateSuggestedTopic
                                                                    }
                                                                    placeholder={`Skill #${
                                                                        index +
                                                                        1
                                                                    }`}
                                                                    defaultValue={skill?.name}
                                                                    label=""
                                                                    onChange={e =>
                                                                        handleInputChange(
                                                                            'skills_required',
                                                                            e
                                                                                .target
                                                                                .value,
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                                {index > 0 && (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() =>
                                                                            removeSkill(
                                                                                index
                                                                            )
                                                                        }
                                                                        className="ml-2 text-red-500"
                                                                    >
                                                                        Remove
                                                                    </button>
                                                                )}
                                                            </div>
                                                        )
                                                    )}
                                                    <button
                                                        type="button"
                                                        onClick={addSkill}
                                                        className="mt-2 text-blue-500"
                                                    >
                                                        Add Skill
                                                    </button>
                                                </div>
                        </div>
                       

                        <div className="my-3 flex justify-end">
                            <Button disabled={loadingCreateSuggestedTopic}>Submit</Button>
                        </div>
                    </>
                )}
            </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
        </div>
    );
}
