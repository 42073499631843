import { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { SelectField, InputField } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { GetApplications, useAssignEvaluetor, useGetApplicationById } from '@/core/hooks/application';
import { useGetEvaluators } from '@/core/hooks/evaluators';
import { useParams } from 'react-router-dom';




export const AssignApplicationEvaluator = ({ refresh, user }: any) => {
    const [modal1, setModal1] = useState(false);
    const query = location.search;
    const pageSize = 1000000;
    const { applicationId } = useParams<{ applicationId: string }>();
    const { loadingApplications, handleGetApplications, applications }: any = GetApplications();
    const { loadingEvaluators, handleGetEvaluators, evaluators } = useGetEvaluators();
    const { handleAssignApplicationToEvaluetor, loadingAssing } = useAssignEvaluetor();

    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const [userIdError, setUserIdError] = useState<string>('');
    const [maxApplications, setMaxApplications] = useState<string>('');
    const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
    const [topics, setTopics] = useState<Array<{ value: string; text: string }>>([]);
    const [application, setApplication] = useState<any>(null);

    const updatePageSizeInQuery = (query: string, pageSize: number): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('pageSize', pageSize.toString());
        return '?' + urlParams.toString();
    };

    useEffect(() => {
        const fetchData = async () => {
            const updatedQuery = updatePageSizeInQuery(query, pageSize);
            await handleGetEvaluators(updatedQuery);
            await handleGetApplications(updatedQuery);
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (applications?.list) {
            const foundApplication = applications.list.find((app: any) => app.id === applicationId);
            if (foundApplication && foundApplication.topics) {
                const fetchedTopics = foundApplication.topics.map((topic: any) => ({
                    value: topic.id,
                    text: topic.name,
                }));
                setTopics(fetchedTopics);
                setApplication(foundApplication);
            }
        }
    }, [applications, applicationId]);

    const handleFormSubmit = () => {
        if (!selectedUserId) {
            setUserIdError('No Evaluator selected');
            return;
        } else {
            setUserIdError('');
        }

        const requestData: any = {
            UserId: selectedUserId,
            topicIds: selectedTopics,
        };

        if (maxApplications && maxApplications !== '0') {
            requestData.max_application = Number(maxApplications);
        }

        handleAssignApplicationToEvaluetor(requestData);
    };

    const handleCheckboxChange = (topicId: string) => {
        setSelectedTopics(prevSelectedTopics =>
            prevSelectedTopics.includes(topicId)
                ? prevSelectedTopics.filter(id => id !== topicId)
                : [...prevSelectedTopics, topicId]
        );
    };

    return (
        <div className="">
            <div className="flex items-center w-full">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary"
                >
                    Assign application
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Assign Evaluator to application
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form onSubmit={handleFormSubmit}>
                                            {({ register, formState }) => (
                                                <div>
                                                    <div className="mb-3">
                                                        <SelectField
                                                            placeholder="Select Evaluator"
                                                            isLoading={loadingEvaluators}
                                                            label="Evaluator"
                                                            options={(evaluators?.list || []).map((category: { id: any; firstName: any; lastName: any; }) => ({
                                                                value: category?.id,
                                                                text: `${category?.firstName} ${category?.lastName}`,
                                                            }))}
                                                            onChange={e => setSelectedUserId(e.target.value)}
                                                        />
                                                        {userIdError && (
                                                            <span className="text-red-500">
                                                                {userIdError}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className="mb-3">
                                                        <InputField
                                                            placeholder="Enter maximum number of applications"
                                                            label="Maximum applications"
                                                            type="number"
                                                            onChange={e => setMaxApplications(e.target.value)}
                                                        />
                                                    </div>
                                                    <label className="block text-gray-700 dark:text-white-dark mb-2"> Select Topics</label>
                                                    <div className="mb-3 form-input ">
                                                        
                                                        {topics.map(topic => (
                                                            <div key={topic.value} className="flex items-start  mb-2">
                                                                <div>
                                                                <input
                                                                    type="checkbox"
                                                                    id={`topic-${topic.value}`}
                                                                    value={topic.value}
                                                                    checked={selectedTopics.includes(topic.value)}
                                                                    onChange={() => handleCheckboxChange(topic.value)}
                                                                    className="mr-2"
                                                                />
                                                                </div>
                                                                
                                                                <label htmlFor={`topic-${topic.value}`}>{topic.text}</label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            type="submit"
                                                            disabled={loadingEvaluators || loadingApplications || loadingAssing}
                                                        >
                                                            Assign
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};


