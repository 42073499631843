import {
    FunnelIcon,
} from '@heroicons/react/24/outline';
import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { UserFilterPayload, UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import Button from '@/components/button';
import Modal from '@/components/modal';
import { Form } from '@/components/form';
import { InputField } from '@/components/input';
import { TableColumnV2 } from '@/components/datatable/DataTable';
import { EvaluationsStep2 } from '@/core/hooks/evaluators';
import { EvaluationType } from '@/core/types/evaluation';
import IconEye from '@/components/Icon/IconEye';
import { useAppSelector } from '@/store';
import DataTablev3 from '@/components/datatable/Datatable2';
import { Tab } from '@headlessui/react';
import IconHome from '@/components/Icon/IconHome';
import IconChecks from '@/components/Icon/IconChecks';
import formatDateToLongForm from '@/core/utils/DateFormattter';


function Evaluationsstep2pending() {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<UserType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<UserFilterPayload>({
        from: '',
        to: '',
    });
    const location = useLocation();
    const query = location.search;
    const navigate = useNavigate()
    const { loadingEvaluations, handleGetEvaluations, evaluations } = EvaluationsStep2();

    const [evaluatorDetails, setEvaluatorDetails] = useState<UserType| null>(null);
    const { userAccount: user }: any = useAppSelector(store => store.user);
    useEffect(() => {
        if (user) {
            const foundEvaluator = evaluations?.list.find((evaluation:any) =>
                evaluation.evaluatorOne.email === user.email ||
                evaluation.evaluatorTwo.email === user.email
            );
            if (foundEvaluator) {
                if (foundEvaluator.evaluatorOne.email === user.email) {
                    setEvaluatorDetails(foundEvaluator.evaluatorOne);
                } else {
                    setEvaluatorDetails(foundEvaluator.evaluatorTwo);
                }
            }
        }
    }, [user, evaluations]);

   
    const updatePageSizeInQuery = (query: string, evaluated: string): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('evaluated', evaluated.toString());
        return '?' + urlParams.toString();
    };
    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, 'IsNotEvaluated');
        handleGetEvaluations(updatedQuery);
    }, [query]);

    const columns: TableColumnV2<EvaluationType>[] = [
        {
            title: 'Applicant Code',
            accessor: 'submittedApplication.appplicant_code',
            render: row => <Link to={`/account/evaluator/user_details/step2/${row?.submittedApplication?.user.id}/${row?.id}/${row?.submittedApplication?.application.id}`} className='hover:underline'>{row.submittedApplication.applicant_code || '-'}</Link>,
        },
    
        {
            title: 'Highest Qualification',
            accessor: 'submittedApplication.user.academicHistories[0].degree',
            render: row => <p>{row.submittedApplication.user.academicHistories[0].degree || '-'}</p>,
        },
        {
            title: 'Qualification instutition',
            accessor: 'submittedApplication.user.academicHistories[0].institution',
            render: row => <p>{row.submittedApplication.user.academicHistories[0].institution || '-'}</p>,

        },
        {
            title: 'Qualification date',
            accessor: 'submittedApplication.user.academicHistories[0].endDate',
            render: row => <p>{formatDateToLongForm(row.submittedApplication.user.academicHistories[0].endDate) || '-'}</p>,

        },
        
        {
            title: ' Decision step 1',
            accessor: 'evaluator_one_marks',
            render: row => {
                const isEvaluatorOne = user && evaluatorDetails && row.evaluatorOne.email === user.email && row.evaluatorOne.lastName === user.lastName;
                const isEvaluatorTwo = user && evaluatorDetails && row.evaluatorTwo.email === user.email && row.evaluatorTwo.lastName === user.lastName;
                
                if (isEvaluatorOne ) {
                    return  <p>{row.evaluator_one_marks || '-'}</p>  ;
                } else {
                    return <p>{row.evaluator_two_marks || '-'}</p>
                }
            },
        },
        {
            title: ' Decision step2',
            accessor: 'evaluator_one_marks',
            render: row => {
                const isEvaluatorOne = user && evaluatorDetails && row.evaluatorOne.email === user.email && row.evaluatorOne.lastName === user.lastName;
                const isEvaluatorTwo = user && evaluatorDetails && row.evaluatorTwo.email === user.email && row.evaluatorTwo.lastName === user.lastName;
                
                if (isEvaluatorOne ) {
                    return  <p className='capitalize'>{row.evaluator_one_step_two === 'reject' ? 'Not Recommended' : row.evaluator_one_step_two || '-'}</p>  ;
                } else {
                    return <p className='capitalize'>{row.evaluator_two_step_two === 'reject' ? 'Not Recommended' : row.evaluator_two_step_two || '-'}</p>
                }
            },
        },
        
        {
            title: 'Action',
            accessor: 'action',
            render: row => <p> <button
            onClick={() => {
                navigate(`/account/evaluator/user_details/step2/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`);
            }}
            className="btn btn-primary btn-sm "
        >
            <IconEye/>
        </button></p>,
        },

    ];
    

    
    return (
        <>
        
        <div className="">
                <DataTablev3
                
                    columns={columns}
                    previousPage={evaluations?.previousPage ?? 0}
                    nextPage={evaluations?.nextPage ?? 0}
                    currentPage={evaluations?.currentPage ?? 0}
                    data={evaluations?.list ??  []}
                    total={evaluations?.total ?? 0}
                    lastPage={evaluations?.lastPage ?? 0}
                    isLoading={loadingEvaluations}
                    header={<div className="w-full flex my-3 justify-end items-center">
                        
                    </div>} />
            
            </div></>
    );
}

type FilterProps = {
    filters: UserFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<UserType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        channel: z.string().optional(),
    });
    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
        
    };
    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, to, channel } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (channel) searchParams.set('channel', channel);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };
    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };
    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<UserFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="flex flex-col justify-between gap-4 md:flex-row">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
            {/* <PrintModal
				title={"Print List of Events"}
				open={openPrint}
				setOpen={setOpenPrint}
				data={data}
			/> */}
        </>
    );
}
export default function Evaluationsstep() {
    return (
        <>
        
        <div className='my-2 '>
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <Link to={`/account/evaluator`} className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                            Home
                        </Link>
                    </li>
                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            Evaluations
                        </button>
                    </li>
                </ol>
            </div>
		<div className="my-4 " >
			<p className="text-lg font-bold "> Evaluation Step 2  </p>
		</div><Tab.Group>
                <Tab.List className="mt-3 flex flex-wrap">
                    <Tab as={Fragment}>
                        {({ selected }) => (
                            <button
                                className={`${selected ? 'border-b space-x-2 !border-secondary text-secondary !outline-none' : ''} -mb-[1px] flex items-center border-transparent p-5 py-3 before:inline-block hover:border-b hover:!border-secondary hover:text-secondary`}
                            >
                                <IconHome className='mx-1' />
                                Pending Applications
                            </button>
                        )}
                    </Tab>
                    <Tab as={Fragment}>
                        {({ selected }) => (
                            <button
                                className={`${selected ? 'border-b space-x-2 !border-secondary text-secondary !outline-none' : ''} -mb-[1px] flex items-center border-transparent p-5 py-3 before:inline-block hover:border-b hover:!border-secondary hover:text-secondary`}
                            >
                                <IconChecks />
                                Evaluated Applications
                            </button>
                        )}
                    </Tab>
                </Tab.List>
                <Tab.Panels>
                    <Tab.Panel>
                        <div className="active pt-5">
                            <Evaluationsstep2pending />
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div>
                            <Evaluationsstep2Checked />
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group></>
    );
}


function Evaluationsstep2Checked() {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<UserType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const [filters, setFilters] = useState<UserFilterPayload>({
        from: '',
        to: '',
    });
    const location = useLocation();
    const query = location.search;
    const navigate = useNavigate()
    const { loadingEvaluations, handleGetEvaluations, evaluations } = EvaluationsStep2();

    const [evaluatorDetails, setEvaluatorDetails] = useState<UserType| null>(null);
    const { userAccount: user }: any = useAppSelector(store => store.user);
    useEffect(() => {
        if (user) {
            const foundEvaluator = evaluations?.list.find((evaluation:any) =>
                evaluation.evaluatorOne.email === user.email ||
                evaluation.evaluatorTwo.email === user.email
            );
            if (foundEvaluator) {
                if (foundEvaluator.evaluatorOne.email === user.email) {
                    setEvaluatorDetails(foundEvaluator.evaluatorOne);
                } else {
                    setEvaluatorDetails(foundEvaluator.evaluatorTwo);
                }
            }
        }
    }, [user, evaluations]);
   
    
    const updatePageSizeInQuery = (query: string, evaluated: string): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('evaluated', evaluated.toString());
        return '?' + urlParams.toString();
    };
    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, 'IstEvaluated');
        handleGetEvaluations(updatedQuery);
    }, [query]);

    const columns: TableColumnV2<EvaluationType>[] = [
        {
            title: 'Applicant Code',
            accessor: 'submittedApplication.appplicant_code',
            render: row => <Link to={`/account/evaluator/user_details/step1/${row?.submittedApplication?.user.id}/${row?.id}/${row?.submittedApplication?.application.id}`} className='hover:underline'>{row.submittedApplication.applicant_code || '-'}</Link>,
        },
    
        {
            title: 'Highest Qualification',
            accessor: 'submittedApplication.user.academicHistories[0].degree',
            render: row => <p>{row.submittedApplication.user.academicHistories[0].degree || '-'}</p>,
        },
        {
            title: 'Qualification instutition',
            accessor: 'submittedApplication.user.academicHistories[0].institution',
            render: row => <p>{row.submittedApplication.user.academicHistories[0].institution || '-'}</p>,

        },
        {
            title: 'Qualification date',
            accessor: 'submittedApplication.user.academicHistories[0].endDate',
            render: row => <p> {formatDateToLongForm(row.submittedApplication.user.academicHistories[0].endDate) || '-'}</p>,

        },
        
        {
            title: ' Decision step 1',
            accessor: 'evaluator_one_marks',
            render: row => {
                const isEvaluatorOne = user && evaluatorDetails && row.evaluatorOne.email === user.email && row.evaluatorOne.lastName === user.lastName;
                const isEvaluatorTwo = user && evaluatorDetails && row.evaluatorTwo.email === user.email && row.evaluatorTwo.lastName === user.lastName;
                
                if (isEvaluatorOne ) {
                    return  <p>{row.evaluator_one_marks || '-'}</p>  ;
                } else {
                    return <p>{row.evaluator_two_marks || '-'}</p>
                }
            },
        },
        {
            title: ' Decision step 2',
            accessor: 'evaluator_one_marks',
            render: row => {
                const isEvaluatorOne = user && evaluatorDetails && row.evaluatorOne.email === user.email && row.evaluatorOne.lastName === user.lastName;
                const isEvaluatorTwo = user && evaluatorDetails && row.evaluatorTwo.email === user.email && row.evaluatorTwo.lastName === user.lastName;
                
                if (isEvaluatorOne ) {
                    return  <p className='capitalize'>      {row.evaluator_one_step_two === 'reject' ? 'Not Recommended' : row.evaluator_one_step_two || '-'}</p>  ;
                } else {
                    return <p className='capitalize'>      {row.evaluator_two_step_two === 'reject' ? 'Not Recommended' : row.evaluator_two_step_two || '-'}</p>
                }
            },
        },
        
        {
            title: 'Action',
            accessor: 'action',
            render: row => <p> <button
            onClick={() => {
                navigate(`/account/evaluator/user_details/step2/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`);
            }}
            className="btn btn-primary btn-sm "
        >
            <IconEye/>
        </button></p>,
        },

    ];
    

    
    return (
        <>
        <div className="">
                <DataTablev3
                
                    columns={columns}
                    previousPage={evaluations?.previousPage ?? 0}
                    nextPage={evaluations?.nextPage ?? 0}
                    currentPage={evaluations?.currentPage ?? 0}
                    data={evaluations?.list  ?? []}
                    total={evaluations?.total ?? 0}
                    lastPage={evaluations?.lastPage ?? 0}
                    isLoading={loadingEvaluations}
                    header={<div className="w-full flex my-3 justify-end items-center">
                        
                    </div>} />
            
            </div></>
    );
}