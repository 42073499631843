
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { add_academic, delete_academic, edit_academics, getAcademics, one_academic } from '@/core/api/academic';
import { AcademicInfoPayload, AcademicInfoType } from '@/core/types/academic';

export const GetAcademics = () => {
    const [LoadingAcademics, setLoadingAcCademics] = useState(false);
    const [academics, setAcadmics]: any = useState<PaginationType<UserType> | null>();
    const handleGetAccademics = async (query?: string, search?: string) => {
        try {
            setLoadingAcCademics(true);
            const data: PaginationType<AcademicInfoType> = await getAcademics(query);
            setAcadmics(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            
            setLoadingAcCademics(false);
        }
    };

    return {
        LoadingAcademics,
        academics,
        handleGetAccademics,
    };
};

export const GetMyAccademics = () => {
    const [loadingAcademics, setLoadingAccademics] = useState(false);
    const [academics, setAcademics]: any = useState<PaginationType<AcademicInfoType> | null>();

    const handleGetAccademics = async (id?:any) => {
        try {
            setLoadingAccademics(true);
            const data: PaginationType<AcademicInfoType> = await one_academic(id);
            setAcademics(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            
            setLoadingAccademics(false);
        }
    };

    return {
        loadingAcademics,
        academics,
        handleGetAccademics,
    };
};
export const EditAcademicInfo= () => {
    const [loadingEditAcademic,setLoadingEditAcademic] = useState(false);
    const hanldeEditAcademic = async (payload: AcademicInfoPayload, id: string) => {
        try {
            setLoadingEditAcademic(true);
            edit_academics(payload, id).then(() => toast.success('Academic Information updated'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditAcademic(false);
        }
    };

    return {
        hanldeEditAcademic,
        loadingEditAcademic,
    };
};


export const useCreateAcademics= () => {
    const [loadingCreate, setLoadingCreate] = useState(false);
    const handleCreate = async (payload: AcademicInfoPayload) => {
        try {
            setLoadingCreate(true);
            await add_academic(payload).then(()=>toast.success('Academic information  added'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreate,
    };
};

export const useDeletecademics = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);
    const handleDelete = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_academic(id).then(()=> toast.success('Academic information deleted'));
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDelete,
    };
};


