import { activate_application, assign_application } from '@/core/api/application';
import {
    get_users,
} from '@/core/api/auth';
import { academic_manager_decision, assign_all_applications, evaluation_step_one, evaluation_step_three, evaluation_step_two, get_academic_managers, get_evaluations, get_evaluations_status, get_evaluations_step2, get_evaluators, non_eligible_applications, statistics_evaluator } from '@/core/api/evaluators';
import { ApplicationPayload } from '@/core/types/application';
import { UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export const useGetAcademicManagers = () => {
    const [loadingAcademicManagers, setLoadingAcademicManagers] = useState(false);
    const [academicManagers, setAcademicManagers] = useState<PaginationType<any> | null>(null);

    const handleGetAcademicManagers = async (query?: string) => {
        try {
            setLoadingAcademicManagers(true);
            const data: PaginationType<UserType> = await get_academic_managers(query);
            setAcademicManagers(data);
        } catch (error: any) {
            toast.error(error.response?.message || 'An error occurred');
        } finally {
            setLoadingAcademicManagers(false);
        }
    };

    return {
        loadingAcademicManagers,
        academicManagers,
        handleGetAcademicManagers,
    };
};

export const useGetEvaluators = () => {
    const [loadingEvaluators, setLoadingEvaluators] = useState(false);
    const [evaluators, setEvaluators]: any = useState<PaginationType<UserType> | null>();
    const handleGetEvaluators = async (query?: string) => {
        try {
            setLoadingEvaluators(true);
            const data: PaginationType<UserType> = await get_evaluators(query);
            setEvaluators(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEvaluators(false);
        }
    };

    return {
        loadingEvaluators,
        evaluators,
        handleGetEvaluators,
    };
};

export const useGetEvaluations = () => {
    const [loadingEvaluations, setLoadingEvaluations] = useState(false);
    const [evaluations, setEvaluations]: any = useState<PaginationType<UserType> | null>();
    const handleGetEvaluations = async (query?: string) => {
        try {
            setLoadingEvaluations(true);
            const data: PaginationType<UserType> = await get_evaluations(query);
            setEvaluations(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEvaluations(false);
        }
    };

    return {
        loadingEvaluations,
        evaluations,
        handleGetEvaluations,
    };
};


export const NonEligibleApplications = () => {
    const [loadingEvaluations, setLoadingEvaluations] = useState(false);
    const [evaluations, setEvaluations]: any = useState<PaginationType<UserType> | null>();
    const handleGetEvaluations = async (query?: string) => {
        try {
            setLoadingEvaluations(true);
            const data: PaginationType<UserType> = await non_eligible_applications(query);
            setEvaluations(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEvaluations(false);
        }
    };

    return {
        loadingEvaluations,
        evaluations,
        handleGetEvaluations,
    };
};

export const useGteStatisticsEvaluator = () => {
    const [loadingEvaluations, setLoadingEvaluations] = useState(false);
    const [statistics, setEvaluations]: any = useState<PaginationType<UserType> | null>();
    const handleGetEvaluations = async () => {
        try {
            setLoadingEvaluations(true);
            const data: PaginationType<UserType> = await statistics_evaluator();
            setEvaluations(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEvaluations(false);
        }
    };

    return {
        loadingEvaluations,
        statistics,
        handleGetEvaluations,
    };
};



export const EvaluationsStep2 = () => {
    const [loadingEvaluations, setLoadingEvaluations] = useState(false);
    const [evaluations, setEvaluations]: any = useState<PaginationType<UserType> | null>();
    const handleGetEvaluations = async (query?: string) => {
        try {
            setLoadingEvaluations(true);
            const data: PaginationType<UserType> = await get_evaluations_step2(query);
            setEvaluations(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEvaluations(false);
        }
    };

    return {
        loadingEvaluations,
        evaluations,
        handleGetEvaluations,
    };
};

export const AssignAllApplications = () => {
    const [loadingAssign, setLoadingAssign] = useState(false);

    const handleAssinfApplications = async (id:string) => {
        try {
            setLoadingAssign(true);
            await assign_all_applications(id);
            toast.success('Application Assigned');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingAssign(false);
        }
    };

    return {
        loadingAssign,
        handleAssinfApplications,
    };
};

export const EvaluationStepOne = () => {
    const [loadingEvaluaete, setLoadingEvaluete] = useState(false);

    const handleEvaluete = async (id:string,payload:ApplicationPayload) => {
        try {
            setLoadingEvaluete(true);
            await evaluation_step_one(id,payload);
            toast.success('Application evaluated ');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingEvaluete(false);
        }
    };

    return {
        loadingEvaluaete,
        handleEvaluete,
    };
};

export const EvaluationStepTwo = () => {
    const [loadingEvaluaete, setLoadingEvaluete] = useState(false);

    const handleEvaluete = async (id:string,payload:ApplicationPayload) => {
        try {
            setLoadingEvaluete(true);
            await evaluation_step_two(id,payload);
            toast.success('Application e');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingEvaluete(false);
        }
    };

    return {
        loadingEvaluaete,
        handleEvaluete,
    };
};
export const EvaluationStepThree = () => {
    const [loadingEvaluaete, setLoadingEvaluete] = useState(false);

    const handleEvaluete = async (id:string,payload:ApplicationPayload) => {
        try {
            setLoadingEvaluete(true);
            await evaluation_step_three(id,payload);
            toast.success('Application decision added');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingEvaluete(false);
        }
    };

    return {
        loadingEvaluaete,
        handleEvaluete,
    };
};

export const EvaluationSAcademicManagerDecision = () => {
    const [loadingEvaluaete, setLoadingEvaluete] = useState(false);

    const handleEvaluete = async (id:string,payload:ApplicationPayload) => {
        try {
            setLoadingEvaluete(true);
            await academic_manager_decision(id,payload);
            toast.success('Application decision added');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingEvaluete(false);
        }
    };

    return {
        loadingEvaluaete,
        handleEvaluete,
    };
};
export const useGteEvaluationStats = () => {
    const [loadingEvaluationsstats, setLoadingEvaluations] = useState(false);
    const [evaluationsstats, setEvaluations]: any = useState<PaginationType<UserType> | null>();
    const handleGetEvaluationStatus = async (applicationId?:string) => {
        try {
            setLoadingEvaluations(true);
            const data: PaginationType<UserType> = await get_evaluations_status(applicationId);
            setEvaluations(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingEvaluations(false);
        }
    };

    return {
        loadingEvaluationsstats,
        evaluationsstats,
        handleGetEvaluationStatus,
    };
};
