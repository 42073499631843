
import Button from '@/components/button';
import Modal from '@/components/modal';
import { useDeletecademics } from '@/core/hooks/academics';
import { useDeleteApplications } from '@/core/hooks/application';
import { useDeleteUser } from '@/core/hooks/auth';
import { useDeleteCategory } from '@/core/hooks/category';
import React, { SetStateAction } from 'react';
import { LoaderIcon } from 'react-hot-toast';

type DeleteUserModalProps = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    title: string;
    selected: any;
    refresh:  React.Dispatch<SetStateAction<boolean>>;
}

export default function DeleteCategory({ open, setOpen, title, selected, refresh }: DeleteUserModalProps) {
    const { handleDeleteCategory, loadingDelete } = useDeleteCategory();
    return (
        <Modal show={open} setShow={setOpen} title={title} >
            <div className="flex flex-col justify-center items-center">
                <div className="pb-4 max-w-[500px]">Are you sure you want to delete the Category  <b>{selected?.name}</b>  ?</div>
                <button
                 onClick={() => {
                    handleDeleteCategory(selected.id).then(() => {
                        refresh((prev: any) => !prev);
                     
                        setOpen(false);
                    });
                    refresh((prev) => !prev);
                }} disabled={loadingDelete} className='btn btn-danger '>{ loadingDelete ? <LoaderIcon  className='bg-white'/> : 'Delete' } </button>
            </div>
        </Modal>
    );
}
