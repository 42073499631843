import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API, { PRIVATE_API_v2 } from '../axios';

import { PaginationType } from '@/core/types/shared';
import { UserType } from '@/core/types/auth';
import { queryString } from '@/core/utils/query';
import { ApplicationPayload, ApplicationType } from '@/core/types/application';


export const get_evaluators = async (query? : string): Promise<PaginationType<UserType>> => {
    try {
        const request = await PRIVATE_API.get(`/user/evaluator${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const get_academic_managers = async (query? : string): Promise<PaginationType<UserType>> => {
  try {
      const request = await PRIVATE_API.get(`/user/academic-managers${queryString(query)}`);
      return await request.data;
  } catch (error) {
      throw new CustomError(AxiosErrorHandler(error));
  }
};

export const get_evaluations = async (query? : string): Promise<PaginationType<UserType>> => {
    try {
        const request = await PRIVATE_API_v2.get(`/evaluation${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_evaluations_status = async (applicationId?:string): Promise<PaginationType<UserType>> => {
  const params = applicationId ? { applicationId } : {};
  try {
      const request = await PRIVATE_API_v2.get(`/stats/evaluation`,{params});
      return await request.data;
  } catch (error) {
      throw new CustomError(AxiosErrorHandler(error));
  }
};

export const non_eligible_applications = async (query? : string): Promise<PaginationType<UserType>> => {
  try {
      const request = await PRIVATE_API_v2.get(`/evaluation/unselected${queryString(query)}`);
      return await request.data;
  } catch (error) {
      throw new CustomError(AxiosErrorHandler(error));
  }
};

export const statistics_evaluator = async (): Promise<PaginationType<UserType>> => {
  try {
      const request = await PRIVATE_API_v2.get(`/evaluation/stats`);
      return await request.data;
  } catch (error) {
      throw new CustomError(AxiosErrorHandler(error));
  }
};
export const get_evaluations_step2 = async (query? : string): Promise<PaginationType<UserType>> => {
  try {
      const request = await PRIVATE_API_v2.get(`/evaluation/step-two${queryString(query)}`);
      return await request.data;
  } catch (error) {
      throw new CustomError(AxiosErrorHandler(error));
  }
};
export const assign_all_applications = async (id:string): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API_v2.post(`/evaluation/assign-topic/${id}`,);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };

  export const evaluation_step_one = async (id?:string,payload?:ApplicationPayload): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API_v2.post(`/evaluation/step-one/${id}`,payload);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };

  export const evaluation_step_two = async (id?:string,payload?:ApplicationPayload): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API_v2.post(`/evaluation/step-two/${id}`,payload);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };

  export const evaluation_step_three = async (id?:string,payload?:ApplicationPayload): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API_v2.post(`/evaluation/step-three/${id}`,payload);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };
  export const academic_manager_decision = async (id?:string,payload?:ApplicationPayload): Promise<ApplicationType | any> => {
    try {
      const request = await PRIVATE_API_v2.post(`/evaluation/academic-manager-decision/${id}`,payload);
      return await request.data;
    } catch (error: any) {
      throw new CustomError(AxiosErrorHandler(error));
    }
  };