
import Button from '@/components/button';
import Modal from '@/components/modal';
import { useDeleteAddress } from '@/core/hooks/address';
import { useDeleteUser } from '@/core/hooks/auth';
import React, { SetStateAction } from 'react';
import { LoaderIcon } from 'react-hot-toast';

type DeleteUserModalProps = {
    open: boolean;
    setOpen: React.Dispatch<SetStateAction<boolean>>;
    title: string;
    selected: any;
    refresh:  React.Dispatch<SetStateAction<boolean>>;
}

export default function DeletAddressModal({ open, setOpen, title, selected, refresh }: DeleteUserModalProps) {
    const { handleDelete, loadingDelete } = useDeleteAddress();
    return (
        <Modal show={open} setShow={setOpen} title={title} >
            <div className="flex flex-col justify-center items-center">
                <div className="pb-4 max-w-[500px]">Are you sure you want to delete this Address <b> {selected?.county} {selected?.street}</b>  ?</div>
                <Button onClick={() => {
                    handleDelete(selected.id).then(() => {
                        setOpen(false);
                    });
                    refresh((prev) => !prev);
                }} disabled={loadingDelete}>{ loadingDelete ? <LoaderIcon /> : 'Delete' }</Button>
            </div>
        </Modal>
    );
}
