import { useEffect, useState } from 'react';
import {
    useCreateAddress,
} from '@/core/hooks/address';
import { TableColumnV2 } from '@/components/datatable/DataTable';
import { useLocation } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';
import { ReferenceType } from '@/core/types/reference';
import { AddReference } from './modal/Add_ref';
import { UpdateReferene } from './modal/Edit_ref';
import DeleteUserRefs from './modal/Delete_Reference';
import { GetReferences } from '@/core/hooks/Reference';
import DataTablev3 from '@/components/datatable/Datatable2';



export default function ReferenceInfo() {
    const { loadingCreate, handleCreate } = useCreateAddress();
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<ReferenceType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = location.search;
    const { userAccount:user }:any = useAppSelector((store) => store.user);
    console.log(user)
    const { loadingReferences,handleGetReferences,references} =GetReferences();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    const MAX_REFERENCES_COUNT = 3;

    const remainingreferencesCount = MAX_REFERENCES_COUNT - (references?.total ?? 0);
    const canAddMoreAddresses = remainingreferencesCount > 0;

    useEffect(() => {
        handleGetReferences(query);
    }, [query, refresh]);
    const columns: TableColumnV2<ReferenceType>[] = [
        {
            title: 'Ref Names',
            accessor: 'firstname',
            render: row => <p className="capitalize">{row?.firstName } {row?.lastName}</p>,
        },
        {
            title: 'campany',
            accessor: 'campany',
            render: row => <p className="capitalize">{row?.company}</p>,
        },
        {
            title: 'Position',
            accessor: 'position',
            render: row => <p className="capitalize">{row?.position}</p>,
        },
        {
            title: 'Relationship',
            accessor: 'relationship',
            render: row => <p className="capitalize">{row?.relationship}</p>,
        },
        {
            title: 'Email',
            accessor: 'email',
            render: row => <p>{row?.email}</p>,
        },
        {
            title: 'PhoneNumber',
            accessor: 'phoneNumber',
            render: row => <p>{(row?.phoneNumber)}</p>, // Adjust the formatting as needed
        },
        {
            title: 'Country',
            accessor: 'country',
            render: row => <p>{(row?.country)}</p>, // Adjust the formatting as needed
        },
        {
            title: 'Position',
            accessor: 'position',
            render: row => <p>{row?.position}</p>,
        },
        
        {
            title: 'Date Created',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>
                    {/* Replace UpdateAcademicInfo with your component for updating experience information */}
                    <UpdateReferene experience={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];
    
    return (
        <div className="panel py-4 ">
            <div>
                <div className='flex flex-row justify-between'>
                {canAddMoreAddresses ? (
                  <p className='text-red-500 font-bold text-md '> Add atleast {remainingreferencesCount}  reference{remainingreferencesCount !== 1 ?'s' : ''}.</p> 
                ) : (
                    ''
                )}
                <AddReference refresh = {setRefresh}/>
                </div>
            
            </div>
            <div className="">
                <DataTablev3
                    columns={columns}
                    previousPage={0}
                    nextPage={0}
                    currentPage={1}
                    data={references?.list ?? []}
                    total={references?.total ?? 0}
                    lastPage={references?.lastPage ?? 0}
                    isLoading={loadingReferences}
                />
                <DeleteUserRefs
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete Reference'}
                />
            </div>
        </div>
    );
}
