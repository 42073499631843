import { TableColumnV2 } from '@/components/datatable/DataTable';
import DataTablev3 from '@/components/datatable/Datatable2';
import { useGetEvaluators } from '@/core/hooks/evaluators';
import { ApplicationType } from '@/core/types/application';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { useAppSelector } from '@/store';
import { set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function EvaluatorApplications() {
    const { userAccount: userData }: any = useAppSelector(store => store.user);
    console.log(userData);
    const [applications, setApplications] = useState<any>([]);

    const { loadingEvaluators, handleGetEvaluators, evaluators } =
        useGetEvaluators();

    useEffect(() => {
        handleGetEvaluators();
        setApplications(evaluators?.list[0]?.theApplication);
    }, []);
    const navigate = useNavigate();

    const columns: TableColumnV2<ApplicationType>[] = [
        {
            title: 'Application Code',
            accessor: 'code',
            render: row => <p className="capitalize">{row?.code || '-'}</p>,
        },
        {
            title: 'Application',
            accessor: 'title',
            render: row => <p className="capitalize">{row?.title}</p>,
        },
        {
            title: 'Description',
            accessor: 'description',
            render: row => (
                <p
                    className="capitalize whitespace-wrap"
                    dangerouslySetInnerHTML={{
                        __html: row?.description?.slice(0, 50),
                    }}
                ></p>
            ),
        },
        {
            title: 'Start Date',
            accessor: 'starting_date',
            render: row => <p>{formatDateToLongForm(row?.starting_date)}</p>,
        },
        {
            title: 'End Date',
            accessor: 'ending_date',
            render: row => <p>{formatDateToLongForm(row?.ending_date)}</p>,
        },

        {
            title: 'Status',
            accessor: 'status',
            render: row => (
                <p>
                    {row?.status === '1' || row?.status === 'active' ? (
                        <span className="badge bg-success">Active</span>
                    ) : (
                        <span className="badge bg-danger">Inactive</span>
                    )}
                </p>
            ),
        },
        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            navigate(`/account/evaluator/evaluation/evaluate/${row.id}`);
                        }}
                        className="btn btn-primary"
                    >
                        Open
                    </button>
                </div>
            ),
        },
    ];
    return (
        <div>
            
           <div className=" my-2 ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <Link to='/account/evaluator'  className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <button className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                            Home
                        </button>
                    </Link>
                  
                
                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            Applications 
                        </button>
                    </li>
                </ol>

            </div>
           
            <DataTablev3
                columns={columns}
                previousPage={0}
                nextPage={1}
                currentPage={1}
                data={evaluators?.list[0]?.theApplication ?? []}
                total={applications?.length ?? 0}
                lastPage={1}
                isLoading={loadingEvaluators}
            />
        </div>
    );
}
