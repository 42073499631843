import { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useState, Fragment } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { SelectField } from '@/components/input';
import { GetApplications, useAssignModerator } from '@/core/hooks/application';
import { useParams } from 'react-router-dom';
import { useGetAllUsers } from '@/core/hooks/auth';

export const AssingModerator = ({ refresh, user }: any) => {
    const [modal1, setModal1] = useState(false);
    const { applicationId } = useParams();
    const query = location.search;
    const pageSize = 1000000;
    const { loadingApplications, handleGetApplications, applications } =
        GetApplications();
    const { loadingUsers, handleGetUsers, users } = useGetAllUsers();
    const { handleAssignModeratorToApplication, loadingAssign } =
        useAssignModerator();

    const [selectedUserId, setSelectedUserId] = useState<string>('');
    const [userIdError, setUserIdError] = useState<string>('');

    const updatePageSizeInQuery = (query: string, pageSize: number): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('pageSize', pageSize.toString());
        urlParams.set('role', 'modulator');
        return '?' + urlParams.toString();
    };

    const PageSize = 1000000;
    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, PageSize);
        handleGetUsers(updatedQuery);
        handleGetApplications(updatedQuery);
    }, []);

    const handleFormSubmit = () => {
        if (!selectedUserId) {
            setUserIdError('No Moderator selected');
        } else {
            setUserIdError('');
        }

        if (!selectedUserId) {
            return;
        }

        const requestData: any = {
            modulatorId: selectedUserId,
            applicationId: applicationId,
        };
        handleAssignModeratorToApplication(requestData);
        console.log(requestData);
    };

    return (
        <div className="">
            <div className="flex items-center w-full ">
                <button
                    type="button"
                    onClick={() => setModal1(true)}
                    className="btn btn-primary "
                >
                    Assign application
                </button>
            </div>
            <Transition appear show={modal1} as={Fragment}>
                <Dialog as="div" open={modal1} onClose={() => setModal1(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-lg text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full ">
                                            Assign application
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form onSubmit={handleFormSubmit}>
                                            {({ register, formState }) => (
                                                <div>
                                                    <div className="mb-3">
                                                        <SelectField
                                                            placeholder="Select moderator"
                                                            isLoading={
                                                                loadingUsers
                                                            }
                                                            label="Moderator"
                                                            options={(
                                                                users?.list ||
                                                                []
                                                            ).map(
                                                                (category: {
                                                                    id: any;
                                                                    firstName: any;
                                                                    lastName: any;
                                                                }) => ({
                                                                    value: category?.id,
                                                                    text: `${category?.firstName} ${category?.lastName}`,
                                                                })
                                                            )}
                                                            onChange={e =>
                                                                setSelectedUserId(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                        {userIdError && (
                                                            <span className="text-red-500">
                                                                {userIdError}
                                                            </span>
                                                        )}
                                                    </div>

                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            type="submit"
                                                            disabled={
                                                                loadingUsers ||
                                                                loadingApplications
                                                            }
                                                        >
                                                            Assign
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
