import { useAppSelector } from '@/store';
import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

export default function TopicsLayout() {
    const location = useLocation();
    const { userAccount: user } = useAppSelector(store => store.user);

    const link = [
        {
            to: `/account/${user?.role}/topics`,
            title: 'Topics',
            active: location.pathname === `/account/${user?.role}/topics`,
        },
    ];
    return (
        <>
            <div className="my-2 ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <li className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <Link
                            to="/account/admin"
                            className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70"
                        >
                            Home
                        </Link>
                    </li>
                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            Topics
                        </button>
                    </li>
                </ol>
            </div>

            <div className="my-4 ">
                <p className="text-lg font-bold "> Topics </p>
            </div>

            <div className="mt-5">
                <Outlet />
            </div>
        </>
    );
}
