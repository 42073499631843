import { GetAnswers } from '@/core/hooks/answers';
import { GetApplications, useApplyApplication } from '@/core/hooks/application';
import { GetTopics } from '@/core/hooks/topics';
import { ApplicationType } from '@/core/types/application';
import { CircularProgress } from '@mui/material';
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { UpdateApplication } from '../modal/Update_Application';
import Topics from '../../topics';

export default function ApplicatonDATA() {
    const { applicationId }: any = useParams();
    const { handleGetApplications, applications } = GetApplications();
    const { handleGetAnswers, answers } = GetAnswers();
    const { handleGetTopics, topics } = GetTopics();
    const { applyApplication, loadingApply } = useApplyApplication();
    const [selectedApplication, setSelectedApplication] =
        useState<ApplicationType | null>(null);

    const location = useLocation();
    const query = location.search;

    useEffect(() => {
        handleGetApplications(query);
        handleGetTopics(query);
        handleGetAnswers(query);
    }, [query]);

    useEffect(() => {
        const selected = applications?.list.find(
            (app: { id: any }) => app.id === applicationId
        );

        setSelectedApplication(selected);
    }, [applications, applicationId]);

    useEffect(() => {
        const selected = applications?.list.find(
            (app: { id: any }) => app.id === applicationId
        );
        setSelectedApplication(selected);
    }, [applications, applicationId]);

    const handleApply = () => {
        applyApplication(applicationId);
    };

    return (
        <>
            <div className="  flex flex-row justify-between ">
                <p className="text-lg font-bold "> Application data </p>
                <div>
                    {selectedApplication && (
                        <UpdateApplication
                            application={selectedApplication}
                            refresh={function (prev: any): void {
                                throw new Error('Function not implemented.');
                            }}
                        />
                    )}
                </div>
            </div>
            {selectedApplication ? (
                <>
                    <div className="mx-auto mt-8 p-6 rounded-lg shadow-md">
                        <div className="flex flex-row justify-between">
                            <h1 className="text-xl font-bold mb-4 text-primary dark:text-gray-400">
                                {selectedApplication?.title}
                            </h1>
                            <div></div>
                        </div>

                        <div
                            className="dark:text-gray-400"
                            dangerouslySetInnerHTML={{
                                __html: selectedApplication?.description || '',
                            }}
                        />
                        <div>
                            <h2 className="text-2xl font-bold mb-2 text-primary">
                                Instructions
                            </h2>
                            <div
                                className="dark:text-gray-400"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        selectedApplication?.instruction_note ||
                                        '',
                                }}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div>
                    <CircularProgress />
                </div>
            )}
            <Topics/>
            
        </>
    );
}
