import IconEye from '@/components/Icon/IconEye';
import { TableColumnV2 } from '@/components/datatable/DataTable';
import DataTablev3 from '@/components/datatable/Datatable2';
import { InputField, SelectField } from '@/components/input';
import { getUserSubmittedDetails } from '@/core/hooks/application';
import { AcademicInfoType } from '@/core/types/academic';
import { AddressType } from '@/core/types/address';
import { ExperienceType } from '@/core/types/experience';
import { LanguageType } from '@/core/types/languages';
import { PersonalType } from '@/core/types/personal_info';
import { ReferenceType } from '@/core/types/reference';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { Dialog, Transition } from '@headlessui/react';
import { CircularProgress, capitalize } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

function UserDatas({ user }: any) {
    const { userId }: any = useParams();
    const [modal, setModal] = React.useState(false);

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="btn-primary btn btn-sm"
                >
                    <IconEye className="w-4" />
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-7xl text-black dark:text-white-dark"
                                >
                                    <div className=" w-full   p-5 ">
                                        <div className="pt-5 font-semibold flex flex-col gap-3">
                                            <div className=" border border-primary  ">
                                                <div className="bg-primary flex flex-row justify-center p-2 text-lg  text-white ">
                                                    PROFILE INFORMATION
                                                </div>
                                                <div className="w-full py-1 flex justify-center items-center   bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold">
                                                    IDENTIFICATION
                                                </div>
                                                {user?.personalInformation?.map(
                                                    (
                                                        personalInfo: any
                                                    ) => (
                                                        <div className="py-4 flex justify-start items-start gap-3 flex-wrap">
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    First Name:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {
                                                                        personalInfo?.firstName
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Middle Name:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {personalInfo?.middleName ||
                                                                        'None'}
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Last Name:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {personalInfo?.lastName ||
                                                                        '-'}
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Phone
                                                                    Number:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {
                                                                        personalInfo?.phoneNumber
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Email:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {
                                                                        personalInfo?.email
                                                                    }
                                                                </p>
                                                            </div>{' '}
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Gender:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {
                                                                        personalInfo?.gender
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Date of
                                                                    Birth:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {formatDateToLongForm(
                                                                        personalInfo?.date_of_birth
                                                                    )}
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Martial
                                                                    Status:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {
                                                                        personalInfo?.maritalStatus
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Nationality:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {
                                                                        personalInfo?.Nationality
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Citizenship:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {personalInfo?.citizenship ||
                                                                        '-'}
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    FaceBook:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {personalInfo?.facebook ||
                                                                        '-'}
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Twitter:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {
                                                                        personalInfo?.twitter
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    LinkedIn:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {
                                                                        personalInfo?.linkedIn
                                                                    }
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Has Passport
                                                                    :
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {personalInfo?.hasPassport
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </p>
                                                            </div>
                                                            {personalInfo?.hasPassport && (
                                                                <>
                                                                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                        <p className="font-bold dark:text-gray-300">
                                                                            Passport
                                                                            Number:
                                                                        </p>
                                                                        <p className="font-semibold text-gray-500">
                                                                            {personalInfo?.passportNumber ||
                                                                                '-'}
                                                                        </p>
                                                                    </div>
                                                                    <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                        <p className="font-bold dark:text-gray-300">
                                                                            Passport
                                                                            Expiry
                                                                            Date:
                                                                        </p>
                                                                        <p className="font-semibold text-gray-500">
                                                                            {personalInfo?.passport_expired_data ||
                                                                                '-'}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            )}
                                                            <div className="p-2 flex flex-row justify-between w-80 bg-gray-10 0 rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Is displaced
                                                                    person:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {personalInfo?.displaced_person
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    Is refugee:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {personalInfo?.refugee
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </p>
                                                            </div>
                                                            <div className="p-2 flex flex-row justify-between w-80   rounded-md">
                                                                <p className="font-bold dark:text-gray-300">
                                                                    {' '}
                                                                    Have
                                                                    disablility:
                                                                </p>
                                                                <p className="font-semibold text-gray-500">
                                                                    {personalInfo?.disability
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </p>
                                                            </div>
                                            
                                                        </div>
                                                    )
                                                )}

                                              

{
     user.academicHistories &&(

        <><div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                                                            Academic Information
                                                        </div><div className="flex justify-start items-start gap-3 flex-wrap">
                                                                <div className="table-responsive  w-full m-5">
                                                                    <table className="border rounded   ">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="font-bold">
                                                                                    Institution
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Country
                                                                                </th>

                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    Graduation
                                                                                    Date
                                                                                </th>
                                                                                <th className="font-bold whitespace-nowrap ">
                                                                                    Level of
                                                                                    Study
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Degree
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Major
                                                                                </th>

                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    Language
                                                                                    of
                                                                                    Instructions
                                                                                </th>
                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    Highest
                                                                                    Qualification
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {user?.academicHistories?.map(
                                                                                (
                                                                                    data: AcademicInfoType
                                                                                ) => {
                                                                                    return (
                                                                                        <tr
                                                                                            key={data.id}
                                                                                        >
                                                                                            <td>
                                                                                                {data.institution}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.country}
                                                                                            </td>

                                                                                            <td>
                                                                                                {formatDateToLongForm(
                                                                                                    data.graduationDate
                                                                                                ) ||
                                                                                                    '-'}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.levelOfStudy}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.degree}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.major}
                                                                                            </td>

                                                                                            <td>
                                                                                                {data.language_of_instructions ||
                                                                                                    '-'}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.highest_qualification
                                                                                                    ? 'Yes'
                                                                                                    : 'No'}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div></>
     )
}
{
     user.experience && (
        <><div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                                                            Experience Information
                                                        </div><div className="flex justify-start items-start gap-3 flex-wrap">
                                                                <div className="table-responsive  w-full m-5">
                                                                    <table className="border rounded">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    Employer
                                                                                    Name
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Country
                                                                                </th>

                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    Start
                                                                                    Date
                                                                                </th>
                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    End Date
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Position
                                                                                </th>
                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    Roles
                                                                                    and
                                                                                    Responsibilities
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Type
                                                                                </th>
                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    Employer
                                                                                    Sector
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {user?.experience?.map(
                                                                                (
                                                                                    data: ExperienceType
                                                                                ) => {
                                                                                    return (
                                                                                        <tr
                                                                                            key={data.id}
                                                                                        >
                                                                                            <td>
                                                                                                {data.employer_name}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.country}
                                                                                            </td>

                                                                                            <td>
                                                                                                {formatDateToLongForm(
                                                                                                    data.start_date
                                                                                                ) ||
                                                                                                    '-'}
                                                                                            </td>
                                                                                            <td>
                                                                                                {formatDateToLongForm(
                                                                                                    data.end_date
                                                                                                ) ||
                                                                                                    '-'}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.position}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.roles_and_responsibilities ||
                                                                                                    '-'}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.type}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.employer_sector ||
                                                                                                    '-'}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div></>
     )
}
                                            

                                                {
                                                     user.language  &&(
                                                        <><div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                                                            Languages
                                                        </div><div className="flex justify-start items-start gap-3 flex-wrap">
                                                                <div className="table-responsive  w-full m-5">
                                                                    <table className="border rounded">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="font-bold">
                                                                                    Language
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Verbal
                                                                                    Level
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Written
                                                                                    Level
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {user?.language?.map(
                                                                                (
                                                                                    data: LanguageType
                                                                                ) => {
                                                                                    return (
                                                                                        <tr
                                                                                            key={data.id}
                                                                                        >
                                                                                            <td>
                                                                                                {data.name}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.verbal_level}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.written_level}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div></>
                                                     ) 
                                                }
                                             

                                                {
                                                     user.reference && (
                                                        <><div className="w-full py-1 flex justify-center items-center bg-gray-200 dark:bg-slate-800 dark:text-gray-300 text-black font-bold uppercase ">
                                                            reference information
                                                        </div><div className="flex justify-start items-start gap-3 flex-wrap">
                                                                <div className="table-responsive  w-full m-5">
                                                                    <table className="border rounded">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    First
                                                                                    Name
                                                                                </th>
                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    Last
                                                                                    Name
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Company
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Position
                                                                                </th>
                                                                                <th className="font-bold whitespace-nowrap">
                                                                                    Relationship
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Email
                                                                                </th>
                                                                                <th className="font-bold whitespace-nowrap ">
                                                                                    Phone
                                                                                    Number
                                                                                </th>
                                                                                <th className="font-bold">
                                                                                    Country
                                                                                </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {user?.reference?.map(
                                                                                (
                                                                                    data: ReferenceType
                                                                                ) => {
                                                                                    return (
                                                                                        <tr
                                                                                            key={data.id}
                                                                                        >
                                                                                            <td>
                                                                                                {data.firstName}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.lastName}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.company}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.position}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.relationship}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.email}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.phoneNumber}
                                                                                            </td>
                                                                                            <td>
                                                                                                {data.country}
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div></>
                                                     )
                                                }
                                            
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}

export default UserDatas;
