import IconPlus from '@/components/Icon/IconPlus';
import Button from '@/components/button';
import { TableColumnV2 } from '@/components/datatable/DataTable';
import { Form } from '@/components/form';
import { InputField, SelectField, TextArea } from '@/components/input';
import { GetApplications } from '@/core/hooks/application';
import { GetCategories } from '@/core/hooks/category';
import { GetQuestions } from '@/core/hooks/question';
import { useCreateTopic } from '@/core/hooks/topics';
import { CategoryType } from '@/core/types/category';
import { QuestionFilterPayload, QuestionType } from '@/core/types/questions';
import { TopicPayload, TopicType } from '@/core/types/topics';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { useAppSelector } from '@/store';
import { Dialog, Transition } from '@headlessui/react';
import { FunnelIcon, TrashIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import UpdateQuestion from './modal/update_quetsion';
import CreateQuestion from './modal/add_question';
import { PaginationType } from '@/core/types/shared';
import Modal from '@/components/modal';
import DataTablev3 from '@/components/datatable/Datatable2';
import DeleteQuestion from './modal/delete_question';

const experienceSchema = z.object({
    name: z.string().min(1, { message: 'Topic name is required' }),
    description: z.string().min(1, { message: 'Description is required' }),
    type: z.string().min(1, { message: 'Type is required' }),
   
});
export default function Questions({topicId}:any) {
    const { applicationId }: any = useParams();
    const [modal, setModal] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<QuestionType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const query = location.search;
    const { userAccount: user }: any = useAppSelector(store => store.user);
    console.log(user);
    const { loadingQuestions, handleGetQuestions, questions } = GetQuestions();

    const [filters, setFilters] = useState<QuestionFilterPayload>({
        from: '',
        to: '',
        topicId: '',
    });
    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };
    const updatePageSizeInQuery = (query: string | string[][] | Record<string, string> | URLSearchParams | undefined, topicId: string) => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('topicId', topicId);
        return '?' + urlParams.toString();
    };
    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, topicId);
        
        handleGetQuestions(updatedQuery);
    }, [query, refresh]);
    const columns: TableColumnV2<QuestionType>[] = [
        {
            title: 'Question',
            accessor: 'title',
            render: row => <p className="capitalize">{row?.title}</p>,
        },
       

        {
            title: 'Description',
            accessor: 'description',
            render: row => (
                <p>
                    {' '}
                    {row.description.length > 50
                        ? `${row.description.slice(0, 50)}...`
                        : row.description}
                </p>
            ),
        },
        {
            title: 'Topic Name',
            accessor: 'topics.name',
            render: row => (
                <p className="capitalize whitespace-wrap">
                    {row?.topics?.name}
                </p>
            ),
        },
        {
            title: 'Topic description',
            accessor: 'category.name',
            render: row => (
                <p>
                    {' '}
                    {row?.topics?.description.length > 50
                        ? `${row?.topics?.description.slice(0, 50)}...`
                        : row?.topics?.description}
                </p>
            ),
        },
        {
            title: 'Priority',
            accessor: 'priority',
            render: row => <p className="capitalize">{row?.priority}</p>,
        },
        {
            title: 'Date Created  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.created_at)}</p>,
        },
        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <button
                        onClick={() => {
                            setSelected(row);
                            setOpen(true);
                        }}
                        className="bg-red-700 text-gray-200 p-1 rounded"
                    >
                        <TrashIcon className="w-4" />
                    </button>

                    <UpdateQuestion question={row} refresh={setRefresh} />
                </div>
            ),
        },
    ];
    return (
        <div>
            <div className="">
                <div className="flex items-center justify-center">
                    <button
                        type="button"
                        onClick={() => setModal(true)}
                        className="btn btn-primary  text-white px-4 py-2   "
                    >
                        Questions
                    </button>
                </div>
                <Transition appear show={modal} as={Fragment}>
                    <Dialog
                        as="div"
                        open={modal}
                        onClose={() => setModal(false)}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0" />
                        </Transition.Child>
                        <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                            <div className="flex items-start justify-center min-h-screen px-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        as="div"
                                        className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-7xl text-black dark:text-white-dark"
                                    >
                                         <div className="panel py-4 ">
            <div>
                
            </div>
            <div className="">
                <DataTablev3
                    columns={columns}
                    previousPage={questions?.previousPage ?? 0}
                    nextPage={questions?.nextPage ?? 0}
                    currentPage={questions?.currentPage ?? 0}
                    data={questions?.list ?? []}
                    total={questions?.total ?? 0}
                    lastPage={questions?.lastPage ?? 0}
                    isLoading={loadingQuestions}
                    header={
                        <div className="flex flex-row justify-end gap-3  mb-2  ">
                              <CreateQuestion refresh={setRefresh} />
                            <Filter
                                filters={filters}
                                data={questions}
                                onFilter={(payload: QuestionFilterPayload) => {
                                    setFilters(payload);
                                }}
                            />
                        </div>
                    }
                />
                <DeleteQuestion
                    refresh={setRefresh}
                    selected={selected}
                    open={open}
                    setOpen={setOpen}
                    title={'Delete Question'}
                />
            </div>
        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </div>
    );
}


type FilterProps = {
    filters: QuestionFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<TopicType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const { loadingApplications, handleGetApplications, applications } =
        GetApplications();

    const query = location.search;
    console.log(query);
    useEffect(() => {
        handleGetApplications(query);
    }, [query]);

    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
         topicId: z.string().optional(),
    });
    const defaultFilters: QuestionFilterPayload = {
        from: '',
        to: '',
        topicId: '',
    };
    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, to,  topicId } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if ( topicId) searchParams.set(' topicId',  topicId);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };

    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };
    return (
        <>
            <div className=" justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<QuestionFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="flex flex-col justify-between">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />
                                <SelectField
                                    placeholder="Select Application"
                                    label="Application"
                                    className="p-3"
                                    options={(applications?.list || []).map(
                                        (app: { id: any; title: any }) => ({
                                            value: app?.id,
                                            text: app?.title,
                                        })
                                    )}
                                    registration={register('topicId')}
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
            {/* <PrintModal
				title={"Print List of Events"}
				open={openPrint}
				setOpen={setOpenPrint}
				data={data}
			/> */}
        </>
    );
}
