import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { z } from 'zod';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField, TextArea } from '@/components/input';
import IconPencil from '@/components/Icon/IconPencil';
import { EditAddress } from '@/core/hooks/address';
import { ExperiencePayload } from '@/core/types/experience';
import IconPlus from '@/components/Icon/IconPlus';
import { useCreateExperiences } from '@/core/hooks/experience';
import { countries } from 'countries-list';

const experienceSchema = z.object({
    employer_name: z.string().min(1, { message: 'Employer Name is required' }),
    country: z.string().min(1, { message: 'Country is required' }),
    city: z.string().min(1, { message: 'City is required' }),
    
    
    employer_website: z
        .string()
        .min(1, { message: 'Employer Website is required' }),
    start_date: z.string().min(1, { message: 'Start Date is required' }),
    end_date: z.string().min(1, { message: 'End Date is required' }),
    position: z.string().min(1, { message: 'Position is required' }),
    roles_and_responsibilities: z
        .string()
        .min(1, { message: 'Roles and responsilities  are required' }),
    type: z.string().min(1, { message: 'Type is required' }),

    employer_sector: z
    .string()
    .min(1, { message: 'Employer Website is required' }),
});

interface AddExperienceProps {
    refresh: (prev: any) => void;
}

export const AddExperience: React.FC<AddExperienceProps> = ({ refresh }) => {
    const [modal, setModal] = React.useState(false);
    const { loadingCreate, handleCreateExperience } = useCreateExperiences(); // Updated hook
    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));
    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="btn-primary btn btn-sm"
                >
                    <IconPlus className="w-4" />
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-xl text-black dark:text-white-dark"
                                >
                                    <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                        <div className="text-lg flex flex-row justify-center w-full">
                                            Add Experience Information
                                        </div>
                                    </div>
                                    <div className="p-5">
                                        <Form<
                                            ExperiencePayload,
                                            typeof experienceSchema
                                        >
                                            schema={experienceSchema}
                                            onSubmit={async data => {
                                                await handleCreateExperience(
                                                    data
                                                );
                                                refresh((prev: any) => !prev);
                                                setModal(false);
                                            }}
                                            className="mt-3"
                                        >
                                            {({ register, formState }) => (
                                                <>
                                                    <div className="px-4 grid grid-cols-2 gap-4 text-sm mb-3">
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., ABC Corporation"
                                                            label="Employer Name"
                                                            error={
                                                                formState.errors
                                                                    .employer_name
                                                            }
                                                            registration={register(
                                                                'employer_name'
                                                            )}
                                                        />
                                                          <SelectField
                                        placeholder="Select Country"
                                        isLoading={loadingCreate}
                                        label="Country"
                                       
                                        error={formState.errors.country}
                                        options={countryOptions}
                                        registration={register('country')}
                                    />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., New York"
                                                            label="City"
                                                            error={
                                                                formState.errors
                                                                    .city
                                                            }
                                                            registration={register(
                                                                'city'
                                                            )}
                                                        />
                                                       
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., www.example.com"
                                                            label="Employer Website"
                                                            error={
                                                                formState.errors
                                                                    .employer_website
                                                            }
                                                            registration={register(
                                                                'employer_website'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="date"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., 2022-01-01"
                                                            label="Start Date"
                                                            error={
                                                                formState.errors
                                                                    .start_date
                                                            }
                                                            registration={register(
                                                                'start_date'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="date"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., 2026-12-31"
                                                            label="End Date"
                                                            error={
                                                                formState.errors
                                                                    .end_date
                                                            }
                                                            registration={register(
                                                                'end_date'
                                                            )}
                                                        />
                                                        <InputField
                                                            type="text"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., Software Engineer"
                                                            label="Position"
                                                            error={
                                                                formState.errors
                                                                    .position
                                                            }
                                                            registration={register(
                                                                'position'
                                                            )}
                                                        />
                                                     
                                                        <SelectField
                                                            placeholder="Type"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            label="Type"
                                                            error={
                                                                formState.errors
                                                                    .type
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'Entrepreneurial',
                                                                    text: 'Entrepreneurial',
                                                                },
                                                                {
                                                                    value: 'Internship',
                                                                    text: 'Internship',
                                                                },
                                                                {
                                                                    value: 'Professional Employment',
                                                                    text: 'Professional Employment',
                                                                },
                                                                {
                                                                    value: 'Research',
                                                                    text: 'Research',
                                                                },
                                                                {
                                                                    value: 'Volunteer',
                                                                    text: 'Volunteer',
                                                                },
                                                                {
                                                                    value: 'Work Study',
                                                                    text: 'Work Study',
                                                                },
                                                            ]}
                                                            registration={register(
                                                                'type'
                                                            )}
                                                        />

<SelectField
                                                            placeholder="Employer sector"
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            label="Employer sector"
                                                            error={
                                                                formState.errors
                                                                    .employer_sector
                                                            }
                                                            options={[
                                                                {
                                                                    value: 'academic',
                                                                    text: 'Academic',
                                                                },
                                                                {
                                                                    value: 'research',
                                                                    text: 'Research',
                                                                },
                                                                {
                                                                    value: 'industry',
                                                                    text: 'Industry',
                                                                },
                                                            ]}
                                                            registration={register(
                                                                'employer_sector'
                                                            )}
                                                        />
                                                       
                                                    </div>
                                                    <div className='px-4'>
                                                    <TextArea
                                                            
                                                            isLoading={
                                                                loadingCreate
                                                            }
                                                            placeholder="e.g., Responsible for..."
                                                            label="Position Roles and responsibilities "
                                                            error={
                                                                formState.errors
                                                                    .roles_and_responsibilities
                                                            }
                                                            registration={register(
                                                                'roles_and_responsibilities'
                                                            )}
                                                        />
                                                    </div>
                                                  
                                                    <div className="my-3 flex justify-end">
                                                        <Button
                                                            disabled={
                                                                loadingCreate
                                                            }
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </Form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
