import { useEffect, useState } from 'react';
import { IRootState, useAppSelector } from '@/store';
import {
    AdminDashboard,
    GetApplicationStats,
    GetApplications,
    GetNationalityStats,
    GetStatistics,
} from '@/core/hooks/application';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { SelectField } from '@/components/input';
import { Link } from 'react-router-dom';
import {
    CheckCircleIcon,
    DocumentChartBarIcon,
    ExclamationCircleIcon,
    UsersIcon,
} from '@heroicons/react/24/outline';
import { countries } from 'countries-list';

export default function AdminStats() {
    const countryOptions = Object.values(countries).map(country => ({
        value: country.name,
        text: country.name,
    }));
    const { userAccount: user }: any = useAppSelector(store => store.user);
    const { loadingStats, stats, handleGetApplicationStats } =
        GetApplicationStats();
    const { loadingApplications, handleGetApplications, applications } =
        GetApplications();
    const { statistics: nationalityStats, handleNationalityStats } =
        GetNationalityStats();

    const {
        loadingStatistics,
        statistics,
        handleGetApplications: adminStats,
    } = GetStatistics();
    const { statistics: adminDash, handleGetStatistics } = AdminDashboard();
    const query = location.search;
    const [dateRange, setDateRange] = useState('monthly');
    const [nationality, setNationality] = useState('');
    const [applicationId, setApplicationId] = useState('');

    useEffect(() => {
        handleGetApplicationStats(dateRange, applicationId);
        handleGetApplications(query);
        handleNationalityStats(nationality, applicationId);
        handleGetStatistics(applicationId, dateRange);
    }, [dateRange, applicationId, nationality]);

    const isDark = useSelector(
        (state: IRootState) =>
            state.themeConfig.theme === 'dark' || state.themeConfig.isDarkMode
    );

    const isRtl = useSelector(
        (state: IRootState) => state.themeConfig.rtlClass === 'rtl'
    );

    const maleData =
        stats?.data?.map(
            (entry: { gender: { male: any } }) => entry.gender.male
        ) ?? [];

    const femaleData =
        stats?.data?.map(
            (entry: { gender: { female: any } }) => entry.gender.female
        ) ?? [];

    const otherData =
        stats?.data?.map(
            (entry: { gender: { other: any } }) => entry.gender.other
        ) ?? [];

    const preferNotToSayData =
        stats?.data?.map(
            (entry: { gender: { prefer_not_to_say: any } }) =>
                entry.gender.prefer_not_to_say
        ) ?? [];

    const columnChart3: any = {
        series: [
            { name: 'Male', data: maleData, color: '#805dca' },
            { name: 'Female', data: femaleData, color: '#e7515a' },
            { name: 'Other', data: otherData, color: '#6ab975' },
            {
                name: 'Prefer Not to Say',
                data: preferNotToSayData,
                color: '#fbb040',
            },
        ],
        options: {
            chart: {
                height: 100,
                type: 'bar',
                zoom: { enabled: false },
                toolbar: { show: false },
            },
            colors: ['#805dca', '#e7515a', '#6ab975', '#fbb040'],
            dataLabels: { enabled: false },
            stroke: { show: true, width: 2, colors: ['transparent'] },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: { borderColor: isDark ? '#191e3a' : '#e0e6ed' },
            xaxis: {
                categories: Array.isArray(stats?.data)
                    ? stats.data?.map((sta: any) => sta.day || sta.month)
                    : [],
                axisBorder: { color: isDark ? '#e0e6ed' : '#e0e6ed' },
            },
            yaxis: {
                opposite: isRtl,
                labels: {
                    formatter: function (value: number) {
                        if (value >= 1000) {
                            return value / 1000 + 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };

    const countryChart: any = {
        series: [
            {
                name: 'Male',
                data:
                    nationalityStats?.map((entry: any) => entry.gender.male) ??
                    [],
                color: '#805dca',
            },
            {
                name: 'Female',
                data:
                    nationalityStats?.map(
                        (entry: any) => entry.gender.female
                    ) ?? [],
                color: '#e7515a',
            },
            {
                name: 'Other',
                data:
                    nationalityStats?.map((entry: any) => entry.gender.other) ??
                    [],
                color: '#6ab975',
            },
            {
                name: 'Prefer Not to Say',
                data:
                    nationalityStats?.map(
                        (entry: any) => entry.gender.prefer_not_to_say
                    ) ?? [],
                color: '#fbb040',
            },
        ],
        options: {
            chart: {
                height: 300, // Adjust the height here
                type: 'bar',
                zoom: { enabled: false },
                toolbar: { show: false },
            },
            colors: ['#805dca', '#e7515a', '#6ab975', '#fbb040'],
            dataLabels: { enabled: false },
            stroke: { show: true, width: 2, colors: ['transparent'] },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                },
            },
            grid: { borderColor: isDark ? '#191e3a' : '#e0e6ed' },
            xaxis: {
                categories:
                    nationalityStats?.map((entry: any) => entry.nationality) ??
                    [],
                axisBorder: { color: isDark ? '#e0e6ed' : '#e0e6ed' },
            },
            yaxis: {
                opposite: isRtl,
                labels: {
                    formatter: function (value: number) {
                        if (value >= 1000) {
                            return value / 1000 + 'K';
                        }
                        return value.toString();
                    },
                },
            },
            tooltip: {
                theme: isDark ? 'dark' : 'light',
                y: {
                    formatter: function (val: any) {
                        return val;
                    },
                },
            },
        },
    };


    const renderWarningCard = (title: string, value: number) => {
        const toPath =
            title.toLowerCase() === 'personal information'
                ? 'profile'
                : `profile`;

        if (value !== 100) {
            return (
                <Link to={toPath}>
                    <WarningCard title={title} value="Not filled" />
                </Link>
            );
        }

        return (
            <Link to={toPath}>
                <CheckedCard title={title} />
            </Link>
        );
    };

    return (
        <>
            <div className="w-full grid sm:grid-cols-2 grid-cols-1 gap-2 items-end border-gray-200 rounded-t-lg mb-4">
                <div>
                    <SelectField
                        placeholder="Select period"
                        label="Select period"
                        onChange={e => setDateRange(e.target.value)}
                        className="form-select"
                        options={[
                            { value: 'daily', text: 'Daily' },
                            { value: 'monthly', text: 'Monthly' },
                            { value: 'yearly', text: 'Yearly' },
                        ]}
                    />
                </div>
                <div>
                    <SelectField
                        placeholder="Select application"
                        isLoading={loadingApplications}
                        label="Select application"
                        options={(applications?.list || []).map(
                            (app: { id: any; title: any }) => ({
                                value: app?.id,
                                text: app?.title,
                            })
                        )}
                        onChange={e => {
                            setApplicationId(e.target.value);
                        }}
                    />
                </div>
              
            </div>

            <div className=" w-full grid lg:grid-cols-2 grid-cols-1 gap-4">
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <h2 className="text-lg font-semibold mb-4">
                        Gender Distribution
                    </h2>
                    <div className="">
                        <ReactApexChart
                            series={columnChart3.series}
                            options={columnChart3.options}
                            className="rounded-lg bg-white dark:bg-black"
                            type="bar"
                        />
                    </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <h2 className="text-lg font-semibold mb-4">Applicants</h2>
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Date
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Total
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Male
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Female
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Other
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Didn't say
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {stats && stats?.data?.length > 0 ? (
                                stats.data.map((entry: any, index: number) => (
                                    <tr
                                        key={index}
                                        className={
                                            index % 2 === 0
                                                ? 'bg-gray-50 hover:bg-gray-100'
                                                : 'hover:bg-gray-100'
                                        }
                                    >
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {entry.day || entry.month}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {entry.number_of_applicants}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {entry.gender.male}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {entry.gender.female}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {entry.gender.other}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {entry.gender.prefer_not_to_say}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={6}
                                        className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-900"
                                    >
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className=" w-full mt-8">
                <div className="bg-white  ">
                   
                   <div className=' w-[50%]'>
                   <h2 className="text-lg  font-semibold  mb-5">
                        Country-wise Statistics
                    </h2> 
                    <SelectField
                    placeholder="Select Country"
                    isLoading={loadingApplications}
                    label="Select Country"
                    
                    options={countryOptions}
                    onChange={e => {
                        setNationality(e.target.value);
                    }}
                />
                   </div>
                    <div className="panel mt-4">
                  
                        <ReactApexChart
                            series={countryChart.series}
                            options={countryChart.options}
                            className="rounded-lg bg-white dark:bg-black"
                            type="bar"
                        />
                    </div>

                    <div className='panel mt-4 '>
                    <h2 className="text-lg font-semibold mb-4">
                            Country-wise table
                        </h2>
                        <table className=" w-full divide-y mt-3  divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Country
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Number of Applicants
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Male
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Female
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Other
                                    </th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Prefer Not to Say
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {nationalityStats &&
                                nationalityStats.length > 0 ? (
                                    nationalityStats.map(
                                        (entry: any, index: any) => (
                                            <tr
                                                key={index}
                                                className={
                                                    index % 2 === 0
                                                        ? 'bg-gray-50 hover:bg-gray-100'
                                                        : 'hover:bg-gray-100'
                                                }
                                            >
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {entry.nationality}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {entry.number_of_applicants}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {entry.gender.male}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {entry.gender.female}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {entry.gender.other}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {
                                                        entry.gender
                                                            .prefer_not_to_say
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={6}
                                            className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-900"
                                        >
                                            No data available
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

function CheckedCard({ title }: any) {
    return (
        <DashCard
            bgColor="bg-green-500"
            title={title}
            value="Filled"
            icon={
                <CheckCircleIcon className="bg-green-500 text-white rounded-full " />
            }
        />
    );
}

function WarningCard({ title }: any) {
    return (
        <DashCard
            bgColor="bg-warning"
            title={title}
            value="Not filled"
            icon={
                <ExclamationCircleIcon className="bg-warning text-white  rounded-full" />
            }
        />
    );
}

type DashCardProps = {
    icon: JSX.Element;
    bgColor: any;
    title: string;
    value: any;
    children?: React.ReactNode;
};

function DashCard(props: DashCardProps) {
    return (
        <div className={`panel h-20 w-full p-0   `}>
            <div className="flex p-5">
                <div className="shrink-0 text-white   w-11 h-11 flex justify-center items-center ">
                    {props.icon}
                </div>
                <div className="ltr:ml-3 rtl:mr-3 font-semibold">
                    <p className="text-xl text-primary dark:text-gray-200 ">
                        {props.title}
                    </p>
                    <h5 className="text-gray-700   dark:text-gray-400  text-xl">
                        {props.value}
                    </h5>
                </div>
            </div>
        </div>
    );
}
