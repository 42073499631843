import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import {
    GetMyAddress,
    getAddresses,
    useCreateAddress,
} from '@/core/hooks/address';
import { AddressType } from '@/core/types/address';
import { z } from 'zod';
import IconX from '@/components/Icon/IconX';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { FunnelIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';

import {
    GetApplications,
    GetSubmittedApplications,
    SubmitApplication,
    useApplyApplication,
} from '@/core/hooks/application';
import {
    ApplicationFilterPayload,
    ApplicationType,
} from '@/core/types/application';

import DataTablev3 from '@/components/datatable/Datatable2';
import { UserFilterPayload } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import Modal from '@/components/modal';
import { useGetAllUsers } from '@/core/hooks/auth';
import IconEye from '@/components/Icon/IconEye';

export default function MySubmittedApplications() {
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<ApplicationType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<ApplicationFilterPayload>({
        from: '',
        to: '',
        userId: '',
    });
    const query = location.search;

    const { loadingSubmit, submitApplication } = SubmitApplication();
    const handleApply = (application_id: any) => {
        submitApplication(application_id);
    };

    const { userAccount: user }: any = useAppSelector(store => store.user);
    const { loadingApplications, handleGetApplications, applications } =
        GetSubmittedApplications();

    const toggleFormVisibility = () => {
        setShowForm(!showForm);
    };

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetApplications(query);
    }, [query, refresh]);

    const columns: TableColumnV2<ApplicationType>[] = [
        {
            title: 'Application',
            accessor: 'title',
            render: row => (
                <p className="capitalize">{row?.application?.title}</p>
            ),
        },
        {
            title: 'Description',
            accessor: 'description',
            render: row => (
                <div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: (row?.application.description || '').slice(
                            0,
                            100
                        ),
                    }}
                />
            </div>
            ),
        },

        {
            title: 'Application deadline',
            accessor: 'ending_date',
            render: row => (
                <p>{formatDateToLongForm(row?.application?.ending_date)}</p>
            ),
        },

        {
            title: 'Date Submitted  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.submitted_date)}</p>,
        },
        {
            title: 'Evaluation status',
            accessor: 'evaluationStatus',
            render: row => {
                let badgeColor;
                switch (row?.evaluationStatus) {
                    case 'approved':
                        badgeColor = 'success';
                        break;
                    case 'rejected':
                        badgeColor = 'danger';
                        break;
                    case 'assigned':
                        badgeColor = 'primary';
                        break;
                    case 'pending':
                        badgeColor = 'warning';
                        break;

                    default:
                        badgeColor = 'gray';
                }
                return (
                    <span className={` badge  bg-${badgeColor}`}>
                        {row?.evaluationStatus || 'Under Review'}
                    </span>
                );
            },
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <>
                        <button
                            onClick={() => {
                                navigate(
                                    `/account/admin/user_details/${row?.user?.id}/${row?.application?.id}`
                                );
                            }}
                            className="btn btn-primary btn-sm "
                        >
                            <IconEye />
                        </button>
                    </>

                    <></>
                </div>
            ),
        },
    ];

    return (
        <>
            <div>
                <div className=" my-2 ">
                    <ol className="flex text-primary font-semibold dark:text-white-dark">
                        <Link
                            to="/account/applicant"
                            className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]"
                        >
                            <button className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                                Home
                            </button>
                        </Link>
                        <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                            <Link
                                to="/account/applicant/application"
                                className="p-1.5 px-3 ltr:pl-6 rtl:pr-6 relative   h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70"
                            >
                                Application
                            </Link>
                        </li>
                        <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                            <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                                Submitted Application
                            </button>
                        </li>
                    </ol>
                </div>
                <div className="my-4 ">
                    <p className="text-lg font-bold ">
                        My Submitted Applications{' '}
                    </p>
                </div>
                <div>
                    <div className="space-y-4  my-2 ">
                        <p>
                            {' '}
                            <span className="badge bg-primary ">Pending</span>:
                            Application is waiting to be reviewed{' '}
                        </p>
                        <p>
                            {' '}
                            <span className="badge bg-success ">Approved</span>:
                            Application has been reviewed and approved{' '}
                        </p>

                        <p>
                            {' '}
                            <span className="badge bg-danger  ">Rejected</span>:
                            Application has not been selected{' '}
                        </p>
                    </div>
                </div>
            </div>
            <div className="panel py-4 ">
                <div className="">
                    <DataTablev3
                        columns={columns}
                        previousPage={applications?.previousPage ?? 0}
                        nextPage={applications?.nextPage ?? 0}
                        currentPage={applications?.currentPage ?? 0}
                        data={applications?.list}
                        total={applications?.total ?? 0}
                        lastPage={applications?.lastPage ?? 0}
                        isLoading={loadingApplications}
                    />

                    {/* <DeletApplications
        refresh={setRefresh}
        selected={selected}
        open={open}
        setOpen={setOpen}
        title={'Delete Applications'}
    /> */}
                </div>
            </div>
        </>
    );
}

type FilterProps = {
    filters: ApplicationFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<ApplicationType> | null;
};
