import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { addQuestion, deleteQuestion, editQuestion, getQuestions, getQuestion } from '@/core/api/questions';
import { QuestionPayload, QuestionType } from '@/core/types/questions';
import { PaginationType } from '@/core/types/shared';

export const GetQuestions = () => {
  const [loadingQuestions, setLoadingQuestions] = useState(false);
  const [questions, setQuestions]: any = useState<QuestionType[] | null>(null);

  const handleGetQuestions = async (query?: string) => {
    try {
      setLoadingQuestions(true);
      const data: PaginationType<QuestionType> = await getQuestions(query);
      setQuestions(data);
    } catch (error: any) {
      toast.error(error.response.message || 'Error fetching questions');
    } finally {
      setLoadingQuestions(false);
    }
  };

  return {
    loadingQuestions,
    questions,
    handleGetQuestions,
  };
};

export const GetSingleQuestion = () => {
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [question, setQuestion]: any = useState<QuestionType | null>(null);

  const handleGetQuestion = async (id?: string) => {
    try {
      setLoadingQuestion(true);
      const data: PaginationType<QuestionType> = await getQuestion(id || '');
      setQuestion(data);
    } catch (error: any) {
      toast.error(error.response.message || 'Error fetching question');
    } finally {
      setLoadingQuestion(false);
    }
  };

  return {
    loadingQuestion,
    question,
    handleGetQuestion,
  };
};

export const EditQuestionInfo = () => {
  const [loadingEditQuestion, setLoadingEditQuestion] = useState(false);

  const handleEditQuestion = async (payload: QuestionPayload, id: string) => {
    try {
      setLoadingEditQuestion(true);
      await editQuestion(payload, id);
      toast.success('Question information updated');
    } catch (error: any) {
      toast.error(error.response.message || 'Something went wrong');
    } finally {
      setLoadingEditQuestion(false);
    }
  };

  return {
    handleEditQuestion,
    loadingEditQuestion,
  };
};

export const useCreateQuestion = () => {
  const [loadingCreateQuestion, setLoadingCreateQuestion] = useState(false);

  const handleCreateQuestion = async (payload: QuestionPayload) => {
    try {
      setLoadingCreateQuestion(true);
      await addQuestion(payload);
      toast.success('Question information added');
    } catch (error: any) {
      toast.error(error.response.message || 'Something went wrong');
    } finally {
      setLoadingCreateQuestion(false);
    }
  };

  return {
    loadingCreateQuestion,
    handleCreateQuestion,
  };
};

export const useDeleteQuestion = () => {
  const [loadingDeleteQuestion, setLoadingDeleteQuestion] = useState(false);

  const handleDeleteQuestion = async (id: string) => {
    try {
      setLoadingDeleteQuestion(true);
      await deleteQuestion(id);
      toast.success('Question information deleted');
    } catch (error: any) {
      toast.error(error.response.message || 'Error Occurred');
    } finally {
      setLoadingDeleteQuestion(false);
    }
  };

  return {
    loadingDeleteQuestion,
    handleDeleteQuestion,
  };
};
