import { Link } from 'react-router-dom';
import svg from '@/assets/aims_logo_2.png';
import CircularProgress from '@mui/material/CircularProgress';
import { useActivateUser } from '@/core/hooks/auth';
import { useRef, useState } from 'react';
import svg2 from '@/assets/img2.jpg';

export const ActivateUser = () => {
    const numberOfInputs = 6;
    const [otp, setOtp] = useState<string[]>(
        new Array(numberOfInputs).fill('')
    );
    const { handleActivate, loadingActivate } = useActivateUser();

    const inputRefs = useRef<HTMLInputElement[]>([]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        const value = e.target.value;
        if (!isNaN(Number(value)) && value.length <= 1) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);
            if (value && index < numberOfInputs - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleKeyDown = (
        e: React.KeyboardEvent<HTMLInputElement>,
        index: number
    ) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const otpString = otp.join('');
        handleActivate(otpString);
    };
    return (
        <div>
            <div className=" flex h-[100vh]  flex-row justify-center px-6 py-6 dark:bg-[#060818]  overflow-none">
                <div className="relative flex w-full max-w-[1502px] flex-row  justify-between overflow-hidden rounded-md bg-white/60 backdrop-blur-lg dark:bg-black/50 lg:min-h-[90vh] lg:flex-row lg:gap-10 xl:gap-0">
                 
                    <div className="relative flex w-full flex-col items-center justify-center gap-6 px-4 pb-16 pt-6 sm:px-6 lg:max-w-[667px]">
                    <div className="flex w-full items-center justify-center gap-2 lg:absolute lg:end-6 lg:top-6 lg:max-w-full">
                            <Link to="/" className="">
                                <div className="md:ml-20  ml-10 w-full  lg:w-[50%] ">
                                   <img src={svg} alt="" />
                                </div>
                            </Link>
                        </div>
                        <div className="w-full max-w-[440px] ">
                            <div className="mb-10">
                                <h1 className="text-xl font-bold  !leading-snug text-primary md:text-4xl">
                                    Activate Account
                                </h1>
                                <p className="text-base  leading-normal text-white-dark">
                                    Enter The OTP Sent to your email
                                </p>
                            </div>

                            <form onSubmit={handleSubmit} className="mt-10">
                                <>
                                    <div className="space-y-4">
                                        <div className="">
                                            <label
                                                htmlFor="OTP"
                                                className=" text-gray-600 dark:text-gray-400 "
                                            >
                                                Activation code
                                            </label>
                                            <div className="flex justify-between gap-2 ">
                                                {otp.map((digit, index) => (
                                                    <input
                                                        key={index}
                                                        ref={el =>
                                                            (inputRefs.current[
                                                                index
                                                            ] =
                                                                el as HTMLInputElement)
                                                        }
                                                        type="text"
                                                        value={digit}
                                                        onChange={e =>
                                                            handleChange(
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        onKeyDown={e =>
                                                            handleKeyDown(
                                                                e,
                                                                index
                                                            )
                                                        }
                                                        maxLength={1}
                                                        className="form-input text-center"
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {loadingActivate ? (
                                        <button
                                            type="submit"
                                            className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase  text-white"
                                        >
                                            <CircularProgress
                                                size={24}
                                                sx={{ color: 'white' }}
                                            />
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="mt-6 h-12 w-full rounded bg-primary font-semibold uppercase text-white"
                                        >
                                            Activate Account
                                        </button>
                                    )}
                                </>
                            </form>
                        </div>
                        <p className="absolute bottom-6 w-full text-center dark:text-white">
                            AIMS RIC {new Date().getFullYear()} All Rights
                            Reserved.
                        </p>
                    </div>
                    <div className=" website-agency-home">
                        <div
                            className="absolute inset-0 opacity-30 bg-cover bg-center bg-opacity-50 m-0"
                            style={{ backgroundImage: `url(${svg2})` }}
                        />

                        <div className="website-agency-home-child" />
                        <div className="welcome-to-aims-wrapper">
                            <h1 className="welcome-to-aims">welcome to aims</h1>
                        </div>
                        <h1 className="explore-exciting-opportunities">{`Explore exciting opportunities and transform your future with AIMS. `}</h1>
                        <div className="join-us-on">
                            Join us on a journey of discovery and innovation.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
