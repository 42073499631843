import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import {
    EditSupervisorInfo,
    GetSupervisors,
    useCreateSupervisor,
} from '@/core/hooks/supervisor';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import IconPlus from '@/components/Icon/IconPlus';
import { SupervisorPayload, SupervisorType } from '@/core/types/supervisor';
import { z } from 'zod';
import { GetSuggestedTopics } from '@/core/hooks/suggested-topics';
import IconPencil from '@/components/Icon/IconPencil';

const supervisorSchema = z.object({
    name: z.string().min(1, { message: 'Name is required' }),
    tittle: z.string().min(1, { message: 'Title is required' }),
    institution: z.string().min(1, { message: 'Institution is required' }),
    country: z.string().min(1, { message: 'Country is required' }),
    phoneNumber: z.string().min(1, { message: 'Phone Number is required' }),
    email: z.string().min(1, { message: 'Email is required' }),
    experience_years: z.string().min(0, {
        message: 'Experience years should be a positive number',
    }),
    suggestedTopicId: z
        .string()
        .min(1, { message: 'Suggested Topic is required' }),
});

export default function UpdateSupervisor({ refresh, supervisor }: any) {
    const [modal, setModal] = React.useState(false);
    const { loadingEditSupervisor, handleEditSupervisor } =
        EditSupervisorInfo();
    const {
        loadingSuggestedTopics,
        handleGetSuggestedTopics,
        suggestedTopics,
    } = GetSuggestedTopics();

    const query = location.search;
    console.log(query);

    useEffect(() => {
        handleGetSuggestedTopics(query);
    }, [query]);

    return (
        <div>
            <div className="">
                <div className="flex items-center justify-center">
                    <button
                        type="button"
                        onClick={() => setModal(true)}
                        className="bg-warning text-gray-200 p-1 rounded"
                    >
                        <IconPencil className="w-4" />
                    </button>
                </div>
                <Transition appear show={modal} as={Fragment}>
                    <Dialog
                        as="div"
                        open={modal}
                        onClose={() => setModal(false)}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0" />
                        </Transition.Child>
                        <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                            <div className="flex items-start justify-center min-h-screen px-4">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        as="div"
                                        className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-xl text-black dark:text-white-dark"
                                    >
                                        <div className="flex bg-[#fbfbfb] dark:bg-[#121c2c] items-center justify-between px-5 py-3">
                                            <div className="text-lg flex flex-row justify-center w-full">
                                                Add Supervisor
                                            </div>
                                        </div>
                                        <div className="p-5">
                                            <Form<
                                                SupervisorPayload,
                                                typeof supervisorSchema
                                            >
                                                schema={supervisorSchema}
                                                onSubmit={async data => {
                                                    await handleEditSupervisor(
                                                        data,
                                                        supervisor?.id
                                                    );
                                                    refresh(
                                                        (prev: any) => !prev
                                                    );
                                                    setModal(false);
                                                }}
                                                className="mt-3"
                                            >
                                                {({ register, formState }) => (
                                                    <>
                                                        <div className="px-4 grid grid-cols-2 gap-4 text-sm mb-3">
                                                            <InputField
                                                                type="text"
                                                                isLoading={
                                                                    loadingEditSupervisor
                                                                }
                                                                defaultValue={
                                                                    supervisor?.name
                                                                }
                                                                placeholder="Supervisor Name"
                                                                label="Name"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .name
                                                                }
                                                                registration={register(
                                                                    'name'
                                                                )}
                                                            />

                                                            <InputField
                                                                type="text"
                                                                isLoading={
                                                                    loadingEditSupervisor
                                                                }
                                                                defaultValue={
                                                                    supervisor?.tittle
                                                                }
                                                                placeholder="Title"
                                                                label="Title"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .tittle
                                                                }
                                                                registration={register(
                                                                    'tittle'
                                                                )}
                                                            />

                                                            <InputField
                                                                type="text"
                                                                isLoading={
                                                                    loadingEditSupervisor
                                                                }
                                                                defaultValue={
                                                                    supervisor?.institution
                                                                }
                                                                placeholder="Institution"
                                                                label="Institution"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .institution
                                                                }
                                                                registration={register(
                                                                    'institution'
                                                                )}
                                                            />

                                                            <InputField
                                                                type="text"
                                                                isLoading={
                                                                    loadingEditSupervisor
                                                                }
                                                                defaultValue={
                                                                    supervisor?.country
                                                                }
                                                                placeholder="Country"
                                                                label="Country"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .country
                                                                }
                                                                registration={register(
                                                                    'country'
                                                                )}
                                                            />

                                                            <InputField
                                                                type="text"
                                                                isLoading={
                                                                    loadingEditSupervisor
                                                                }
                                                                defaultValue={
                                                                    supervisor?.phoneNumber
                                                                }
                                                                placeholder="Phone Number"
                                                                label="Phone Number"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .phoneNumber
                                                                }
                                                                registration={register(
                                                                    'phoneNumber'
                                                                )}
                                                            />

                                                            <InputField
                                                                type="text"
                                                                isLoading={
                                                                    loadingEditSupervisor
                                                                }
                                                                defaultValue={
                                                                    supervisor?.email
                                                                }
                                                                placeholder="Email"
                                                                label="Email"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .email
                                                                }
                                                                registration={register(
                                                                    'email'
                                                                )}
                                                            />

                                                            <InputField
                                                                type="number"
                                                                isLoading={
                                                                    loadingEditSupervisor
                                                                }
                                                                defaultValue={
                                                                    supervisor?.experience_years
                                                                }
                                                                placeholder="Experience Years"
                                                                label="Experience Years"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .experience_years
                                                                }
                                                                registration={register(
                                                                    'experience_years'
                                                                )}
                                                            />

                                                            <SelectField
                                                                placeholder="Select Suggested Topic"
                                                                isLoading={
                                                                    loadingEditSupervisor ||
                                                                    loadingSuggestedTopics
                                                                }
                                                                defaultValue={
                                                                    supervisor
                                                                        ?.suggestedTopic
                                                                        ?.id
                                                                }
                                                                label="Suggested Topic"
                                                                error={
                                                                    formState
                                                                        .errors
                                                                        .suggestedTopicId
                                                                }
                                                                options={(
                                                                    suggestedTopics?.list ||
                                                                    []
                                                                ).map(
                                                                    (supervisor: {
                                                                        id: any;
                                                                        title: any;
                                                                    }) => ({
                                                                        value: supervisor?.id,
                                                                        text: supervisor?.title,
                                                                    })
                                                                )}
                                                                registration={register(
                                                                    'suggestedTopicId'
                                                                )}
                                                            />
                                                        </div>

                                                        <div className="my-3 flex justify-end">
                                                            <Button
                                                                disabled={
                                                                    loadingEditSupervisor
                                                                }
                                                            >
                                                                Submit
                                                            </Button>
                                                        </div>
                                                    </>
                                                )}
                                            </Form>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </div>
        </div>
    );
}
