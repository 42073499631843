import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios';

import { PaginationType } from '@/core/types/shared';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { queryString } from '@/core/utils/query';
import { AddressPayload } from '@/core/types/address';

import { ExperiencePayload, ExperienceType } from '@/core/types/experience';



export const add_experience = async (
    payload: ExperiencePayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/experience', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_experience = async (payload : ExperiencePayload, id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/experience/${id}`,payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const one_experience = async (id:string): Promise<ExperienceType | any> => {
    try {
        const request = await PRIVATE_API.get(`academic/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_experience = async (id: string): Promise<ExperienceType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/experience/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


export const get_experinces = async (query? : string): Promise<PaginationType<ExperienceType>> => {
    try {
        const request = await PRIVATE_API.get(`/experience${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


