import { TableColumnV2 } from "@/components/datatable/DataTable";
import DataTablev3 from "@/components/datatable/Datatable2";
import IconEye from "@/components/Icon/IconEye";
import { useGetEvaluations } from "@/core/hooks/evaluators";
import { UserFilterPayload, UserType } from "@/core/types/auth";
import { EvaluationType } from "@/core/types/evaluation";
import formatDateToLongForm from "@/core/utils/DateFormattter";
import { useAppSelector } from "@/store";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

export default function AcademicChecked() {
    const [filters, setFilters] = useState<UserFilterPayload>({
        from: '',
        to: '',
        country: '',
        gender: '',
    });
    const location = useLocation();
    const query = location.search;
    const navigate = useNavigate();
    const { loadingEvaluations, handleGetEvaluations, evaluations } =
        useGetEvaluations();
    const { userAccount: user }: any = useAppSelector(store => store.user);
    const [evaluatorDetails, setEvaluatorDetails] = useState<UserType | null>(
        null
    );

    const updatePageSizeInQuery = (
        query: string,
        evaluated: string
    ): string => {
        const urlParams = new URLSearchParams(query);
        urlParams.set('isEvaluatedByAcademicManager', evaluated.toString());
        return '?' + urlParams.toString();
    };
    useEffect(() => {
        const updatedQuery = updatePageSizeInQuery(query, 'true');
        handleGetEvaluations(updatedQuery);
    }, [query]);

    useEffect(() => {
        if (user) {
            const foundEvaluator = evaluations?.list.find(
                (evaluation: any) =>
                    evaluation.evaluatorOne?.email === user?.email ||
                    evaluation.evaluatorTwo?.email === user?.email
            );
            if (foundEvaluator) {
                if (foundEvaluator.evaluatorOne?.email === user?.email) {
                    setEvaluatorDetails(foundEvaluator.evaluatorOne);
                } else {
                    setEvaluatorDetails(foundEvaluator.evaluatorTwo);
                }
            }
        }
    }, [user, evaluations]);

    // const filteredEvaluations = evaluations?.list.filter(
    //     (evaluation: EvaluationType) => {
    //         const isEvaluatorOne =
    //             user &&
    //             evaluatorDetails &&
    //             evaluation.evaluatorOne.email === user.email &&
    //             evaluation.evaluatorOne.lastName === user.lastName;
    //         const isEvaluatorTwo =
    //             user &&
    //             evaluatorDetails &&
    //             evaluation.evaluatorTwo.email === user.email &&
    //             evaluation.evaluatorTwo.lastName === user.lastName;

    //         if (isEvaluatorOne && evaluation.evaluator_one_step_two) {
    //             return true;
    //         } else if (isEvaluatorTwo && evaluation.evaluator_two_step_two) {
    //             return true;
    //         }

    //         return false;
    //     }
    // );

    const columns: TableColumnV2<EvaluationType>[] = [
        {
            title: 'Applicant Code',
            accessor: 'submittedApplication.applicant_code',
            render: row => (
                <Link
                    to={`/account/academic_manager/user_details/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`}
                    className="hover:underline"
                >
                    {row.submittedApplication.applicant_code || '-'}
                </Link>
            ),
        },
        {
            title: 'Highest Qualification',
            accessor: 'submittedApplication.user.academicHistories[0].degree',
            render: row => (
                <p>
                    {row.submittedApplication.user.academicHistories[0]
                        .degree || '-'}
                </p>
            ),
        },
        {
            title: 'Qualification institution',
            accessor:
                'submittedApplication.user.academicHistories[0].institution',
            render: row => (
                <p>
                    {row.submittedApplication.user.academicHistories[0]
                        .institution || '-'}
                </p>
            ),
        },
        {
            title: 'Qualification date',
            accessor: 'submittedApplication.user.academicHistories[0].endDate',
            render: row => (
                <p>
                    {' '}
                    {formatDateToLongForm(
                        row.submittedApplication.user.academicHistories[0]
                            .endDate
                    ) || '-'}
                </p>
            ),
        },

        {
            title: 'Application Name',
            accessor: 'submittedApplication.application.title',
            render: row => (
                <p>{row.submittedApplication.application.title || '-'}</p>
            ),
        },
        {
            title: 'Academic Manager decision',
            accessor: 'academic_manager_decision',
            render: row => (
                <div
                    className={`badge w-fit ${
                        row?.academic_manager_decision === 'reject'
                            ? 'bg-danger'
                            : row?.academic_manager_decision === 'accept'
                            ? 'bg-success'
                            : ''
                    }`}
                >
                    <p>
                        {row?.academic_manager_decision === 'reject'
                            ? 'Rejected'
                            : row?.academic_manager_decision === 'accept'
                            ? 'Accepted'
                            : '-'}
                    </p>
                </div>
            ),
        },

        {
            title: 'Action',
            accessor: 'actions',
            render: row => (
                <p>
                    <button
                        onClick={() => {
                            navigate(
                                `/account/academic_manager/user_details/${row?.submittedApplication?.user.id}/${row?.id}/${row.submittedApplication.application.id}`
                            );
                        }}
                        className="btn btn-primary btn-sm"
                    >
                        <IconEye />
                    </button>
                </p>
            ),
        },
    ];

    return (
        <>
            <div className="my-4 ">
                <p className="text-lg font-bold ">
                    {' '}
                    Academic Manager Evaluation checked{' '}
                </p>
            </div>
            <div className="">
                <DataTablev3
                    columns={columns}
                    previousPage={evaluations?.previousPage ?? 0}
                    nextPage={evaluations?.nextPage ?? 0}
                    currentPage={evaluations?.currentPage ?? 0}
                    data={evaluations?.list ?? []}
                    total={evaluations?.total ?? 0}
                    lastPage={evaluations?.lastPage ?? 0}
                    isLoading={loadingEvaluations}
                    header={
                        <div className="w-full flex my-3 justify-end items-center"></div>
                    }
                />
            </div>
        </>
    );
}
