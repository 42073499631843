import { useEffect, useState } from 'react';
import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import {
    GetMyAddress,
    getAddresses,
    useCreateAddress,
} from '@/core/hooks/address';
import { AddressType } from '@/core/types/address';
import { z } from 'zod';
import IconX from '@/components/Icon/IconX';
import DataTableV2, { TableColumnV2 } from '@/components/datatable/DataTable';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import formatDateToLongForm from '@/core/utils/DateFormattter';
import { FunnelIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useAppSelector } from '@/store';

import {
    GetApplications,
    GetSubmittedApplications,
    SubmitApplication,
    useApplyApplication,
} from '@/core/hooks/application';
import {
    ApplicationFilterPayload,
    ApplicationType,
} from '@/core/types/application';

import DataTablev3 from '@/components/datatable/Datatable2';
import { UserFilterPayload } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import Modal from '@/components/modal';
import { useGetAllUsers } from '@/core/hooks/auth';
import IconEye from '@/components/Icon/IconEye';

export default function SubmittedApplicationsForEvaluation() {
    const [showForm, setShowForm] = useState(false);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState<ApplicationType | null>(null);
    const [refresh, setRefresh] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [filters, setFilters] = useState<ApplicationFilterPayload>({
        from: '',
        to: '',
        userId: '',
    });
    const query = location.search;

    const { loadingSubmit, submitApplication } = SubmitApplication();
    const handleApply = (application_id: any) => {
        submitApplication(application_id);
    };

    const { userAccount: user }: any = useAppSelector(store => store.user);
    const { loadingApplications, handleGetApplications, applications } =
        GetSubmittedApplications();
   

    const closeForm = () => {
        setShowForm(false);
    };

    useEffect(() => {
        handleGetApplications(query);
    }, [query, refresh]);

    const columns: TableColumnV2<ApplicationType>[] = [
        {
            title: 'Application',
            accessor: 'title',
            render: row => (
                <p className="capitalize">{row?.application?.title}</p>
            ),
        },
        {
            title: 'Description',
            accessor: 'description',
            render: row => (
                <p className="capitalize whitespace-wrap">
                    {row?.application.description?.slice(0, 50)}
                    {row?.application?.description &&
                        row?.application?.description.length > 50 &&
                        '...'}
                </p>
            ),
        },

        {
            title: 'Application deadline',
            accessor: 'ending_date',
            render: row => (
                <p>{formatDateToLongForm(row?.application?.ending_date)}</p>
            ),
        },
        // {
        //     title: 'User Name',
        //     accessor: 'user.firstName',
        //     render: row => (
        //         <p className="capitalize whitespace-wrap">
        //             {row?.user?.firstName} {row?.user?.lastName}
        //         </p>
        //     ),
        // },
        // {
        //     title: 'User Email',
        //     accessor: 'user.email',
        //     render: row => (
        //         <p className="capitalize whitespace-wrap">{row?.user?.email}</p>
        //     ),
        // },

        {
            title: 'Date Submitted  ',
            accessor: 'created_at',
            render: row => <p>{formatDateToLongForm(row?.submitted_date)}</p>,
        },

        {
            title: 'Actions',
            accessor: 'actions',
            render: row => (
                <div className="flex gap-2 justify-center">
                    <>
                        <button
                            onClick={() => {
                                navigate(`/account/evaluator/user_details/${row?.user?.id}/${row?.id}`);
                            }}
                            className="btn btn-primary btn-sm "
                        >
                            <IconEye/>
                        </button>

                    </>

                    <></>
                </div>
            ),
        },
    ];

    return (
        <><div>

            <div className=" my-2 ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <Link to='/account/evaluator'  className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]">
                        <button className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                            Home
                        </button>
                    </Link>
                    <li className="bg-[hsl(223,21%,94%)] dark:bg-[#1b2e4b]">
                        <Link to='/account/evaluator/evaluation' className="p-1.5 px-3 ltr:pl-6 rtl:pr-6 relative   h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                            Evaluation
                        </Link>
                    </li>
                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            Submitted Application
                        </button>
                    </li>
                </ol>

            </div>
            <div className="my-4 ">
                <p className="text-lg font-bold ">Submitted Applications </p>
            </div>
        </div><div className="panel py-4 ">

                <div className="">
                    
                        <DataTableV2
                        tableName='Submitted applications'
                            columns={columns}
                            previousPage={0}
                            nextPage={0}
                            currentPage={1}
                            data={applications?.list ?? []}
                            total={applications?.total ?? 0}
                            lastPage={applications?.lastPage ?? 0}
                            isLoading={loadingApplications}
                            header={<div className="w-full flex my-3 justify-end items-center">
                                <Filter
                                    filters={filters}
                                    data={applications}
                                    onFilter={(
                                        payload: ApplicationFilterPayload
                                    ) => {
                                        setFilters(payload);
                                    } } />
                            </div>} />
                    
                  

           
                </div>
            </div></>
    );
}

type FilterProps = {
    filters: ApplicationFilterPayload;
    onFilter: (...args: any) => void;
    data: PaginationType<ApplicationType> | null;
};

function Filter(props: FilterProps) {
    const { onFilter, filters, data } = props;
    const [open, setOpen] = useState(false);
    const [openPrint, setOpenPrint] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const schema = z.object({
        from: z.string().optional(),
        to: z.string().optional(),
        userId: z.string().optional(),
    });

    const defaultFilters: UserFilterPayload = {
        from: '',
        to: '',
    };
    const handleOnSubmit = (payload: any) => {
        const searchParams = new URLSearchParams(location.search);

        const { from, to, userId } = payload;

        if (from) searchParams.set('from', from);
        if (to) searchParams.set('to', to);
        if (userId) searchParams.set('userId', userId);

        const newSearch = searchParams.toString();
        onFilter(payload);
        navigate(`${location.pathname}?${newSearch}`);

        setOpen(false);
    };
    const resetFilter = () => {
        onFilter(defaultFilters);
        navigate(`${location.pathname}`);
        setOpen(false);
    };

    const query = location.search;
    const { loadingUsers, handleGetUsers, users } = useGetAllUsers();
    useEffect(() => {
        handleGetUsers(query);
    }, []);

    return (
        <>
            <div className="w-full justify-end flex flex-wrap">
                <div className="flex flex-row gap-3">
                    <Button
                        title="Filter results"
                        onClick={() => setOpen(true)}
                    >
                        <FunnelIcon className="w-4" /> Filter
                    </Button>
                </div>
            </div>
            <Modal show={open} setShow={setOpen} title={'Filter'}>
                <Form<ApplicationFilterPayload, typeof schema>
                    onSubmit={handleOnSubmit}
                    schema={schema}
                    options={{ defaultValues: filters }}
                >
                    {({ register, formState }) => (
                        <div className="px-3">
                            <div className="grid grid-cols-1">
                                <InputField
                                    type="date"
                                    error={formState.errors.from}
                                    registration={register('from')}
                                    className="h-12"
                                    label="Start Date"
                                />

                                <InputField
                                    type="date"
                                    error={formState.errors.to}
                                    registration={register('to')}
                                    className="h-12"
                                    label="End Date"
                                />
                                <SelectField
                                    placeholder="Select User"
                                    isLoading={loadingUsers}
                                    label="User"
                                    error={formState.errors.userId}
                                    options={(users?.list || [])
                                        .filter(
                                            (user: {
                                                status: string;
                                                role: string;
                                            }) =>
                                                user.status === 'active' &&
                                                user.role === 'admin'
                                        )
                                        .map(
                                            (user: {
                                                id: any;
                                                firstName: any;
                                                lastName: any;
                                            }) => ({
                                                value: user?.id,
                                                text: `${user?.firstName} ${user?.lastName}`,
                                            })
                                        )}
                                    registration={register('userId')}
                                />
                            </div>
                            <div className="py-4 flex flex-row justify-between">
                                <Button
                                    onClick={resetFilter}
                                    background="bg-gray-500"
                                >
                                    Reset
                                </Button>
                                <Button>Filter</Button>
                            </div>
                        </div>
                    )}
                </Form>
            </Modal>
            {/* <PrintModal
				title={"Print List of Events"}
				open={openPrint}
				setOpen={setOpenPrint}
				data={data}
			/> */}
        </>
    );
}
