import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { PaginationType } from '@/core/types/shared';
import { get_categories, one_category, edit_category, add_category, delete_category, add_sub_category } from '@/core/api/category';
import { CategoryType, CategoryPayload } from '@/core/types/category';

export const GetCategories = () => {
    const [loadingCategories, setLoadingCategories] = useState(false);
    const [categories, setCategories]: any = useState<CategoryType[] | null>();

    const handleGetCategories = async (query?: string) => {
        try {
            setLoadingCategories(true);
            const data: PaginationType<CategoryType> = await get_categories(query);
            setCategories(data);
        } catch (error: any) {
            toast.error(error.response.message || 'Error fetching categories');
        } finally {
            setLoadingCategories(false);
        }
    };

    return {
        loadingCategories,
        categories,
        handleGetCategories,
    };
};

export const GetSingleCategory = () => {
    const [loadingCategory, setLoadingCategory] = useState(false);
    const [category, setCategory]: any = useState<CategoryType | null>();

    const handleGetCategory = async (id?: any) => {
        try {
            setLoadingCategory(true);
            const data: CategoryType = await one_category(id);
            setCategory(data);
        } catch (error: any) {
            toast.error(error.response.message || 'Error fetching category');
        } finally {
            setLoadingCategory(false);
        }
    };

    return {
        loadingCategory,
        category,
        handleGetCategory,
    };
};

export const EditCategoryInfo = () => {
    const [loadingEditCategory, setLoadingEditCategory] = useState(false);

    const handleEditCategory = async (payload: CategoryPayload, id: string) => {
        try {
            setLoadingEditCategory(true);
            await edit_category(payload, id);
            toast.success('Category information updated');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditCategory(false);
        }
    };

    return {
        handleEditCategory,
        loadingEditCategory,
    };
};

export const AddSubCategory = () => {
    const [loadingAddSubCategory, setLoadingAddSubCategory] = useState(false);

    const handleAddSubcategory = async (payload:any) => {
        try {
            setLoadingAddSubCategory(true);
            await add_sub_category(payload);
            toast.success('Sub Category added ');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingAddSubCategory(false);
        }
    };

    return {
        handleAddSubcategory,
        loadingAddSubCategory,
    };
};


export const useCreateCategory = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);

    const handleCreateCategory = async (payload: CategoryPayload) => {
        try {
            setLoadingCreate(true);
            await add_category(payload);
            toast.success('Category information added');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreateCategory,
    };
};

export const useDeleteCategory = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);

    const handleDeleteCategory = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_category(id);
            toast.success('Category information deleted');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDeleteCategory,
    };
};
