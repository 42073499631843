// src/core/hooks/answers.ts
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { addAnswer, deleteAnswer, editAnswer, getAnswers, getAnswer } from '@/core/api/answers';
import { AnswerPayload, AnswerType } from '@/core/types/answers';
import { PaginationType } from '@/core/types/shared';

export const GetAnswers = () => {
  const [loadingAnswers, setLoadingAnswers] = useState(false);
  const [answers, setAnswers]: any = useState<AnswerType[] | null>(null);

  const handleGetAnswers = async (    query?: string) => {
    try {
      setLoadingAnswers(true);
      const data: PaginationType<AnswerType>= await getAnswers(query);
      setAnswers(data);
    } catch (error: any) {
      toast.error(error.response.message || 'Error fetching answers');
    } finally {
      setLoadingAnswers(false);
    }
  };

  return {
    loadingAnswers,
    answers,
    handleGetAnswers,
  };
};

export const GetSingleAnswer = () => {
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [answer, setAnswer]: any = useState<AnswerType | null>(null);

  const handleGetAnswer = async (id?: string) => {
    try {
      setLoadingAnswer(true);
      const data: AnswerType = await getAnswer(id || '');
      setAnswer(data);
    } catch (error: any) {
      toast.error(error.response.message || 'Error fetching answer');
    } finally {
      setLoadingAnswer(false);
    }
  };

  return {
    loadingAnswer,
    answer,
    handleGetAnswer,
  };
};

export const EditAnswerInfo = () => {
  const [loadingEditAnswer, setLoadingEditAnswer] = useState(false);

  const handleEditAnswer = async (payload: AnswerPayload, id: string) => {
    try {
      setLoadingEditAnswer(true);
      await editAnswer(payload, id);
      toast.success('Response  Updated ');
    } catch (error: any) {
      toast.error(error.response.message || 'Something went wrong');
    } finally {
      setLoadingEditAnswer(false);
    }
  };

  return {
    handleEditAnswer,
    loadingEditAnswer,
  };
};

export const useCreateAnswer = () => {
  const [loadingCreateAnswer, setLoadingCreateAnswer] = useState(false);

  const handleCreateAnswer = async (payload: AnswerPayload) => {
    try {
      setLoadingCreateAnswer(true);
      await addAnswer(payload);
      toast.success('Response recorded');
    } catch (error: any) {
      toast.error(error.response.message || 'Something went wrong');
    } finally {
      setLoadingCreateAnswer(false);
    }
  };

  return {
    loadingCreateAnswer,
    handleCreateAnswer,
  };
};

export const useDeleteAnswer = () => {
  const [loadingDeleteAnswer, setLoadingDeleteAnswer] = useState(false);

  const handleDeleteAnswer = async (id: string) => {
    try {
      setLoadingDeleteAnswer(true);
      await deleteAnswer(id);
      toast.success('Answer information deleted');
    } catch (error: any) {
      toast.error(error.response.message || 'Error Occurred');
    } finally {
      setLoadingDeleteAnswer(false);
    }
  };

  return {
    loadingDeleteAnswer,
    handleDeleteAnswer,
  };
};
