import { useState } from 'react';
import { toast } from 'react-hot-toast';
import {
    add_reference,
    delete_reference,
    edit_reference,
    get_references,
    one_reference
} from '@/core/api/reference';
import { ReferencePayload, ReferenceType } from '@/core/types/reference';
import { PaginationType } from '@/core/types/shared';

export const GetReferences = () => {
    const [loadingReferences, setLoadingReferences] = useState(false);
    const [references, setReferences]: any = useState<ReferenceType[] | null>();

    const handleGetReferences = async (query?: string) => {
        try {
            setLoadingReferences(true);
            const data: PaginationType<ReferenceType> = await get_references(query);
            setReferences(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingReferences(false);
        }
    };

    return {
        loadingReferences,
        references,
        handleGetReferences,
    };
};

export const GetMyReference = () => {
    const [loadingReference, setLoadingReference] = useState(false);
    const [reference, setReference]: any = useState<ReferenceType | null>();

    const handleGetReference = async (id?: any) => {
        try {
            setLoadingReference(true);
            const data: ReferenceType = await one_reference(id);
            setReference(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            setLoadingReference(false);
        }
    };

    return {
        loadingReference,
        reference,
        handleGetReference,
    };
};

export const EditReferenceInfo = () => {
    const [loadingEditReference, setLoadingEditReference] = useState(false);

    const handleEditReference = async (payload: ReferencePayload, id: string) => {
        try {
            setLoadingEditReference(true);
            await edit_reference(payload, id);
            toast.success('Reference Information updated');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditReference(false);
        }
    };

    return {
        handleEditReference,
        loadingEditReference,
    };
};

export const useCreateReferences = () => {
    const [loadingCreate, setLoadingCreate] = useState(false);

    const handleCreateReference = async (payload: ReferencePayload) => {
        try {
            setLoadingCreate(true);
            await add_reference(payload);
            toast.success('Reference information added');
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreateReference,
    };
};

export const useDeleteReferences = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);

    const handleDeleteReference = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_reference(id);
            toast.success('Reference information deleted');
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDeleteReference,
    };
};
