import {
    delete_user,
    edit_user,
    get_users,
    login_user,
    register_user,
} from '@/core/api/auth';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { PaginationType } from '@/core/types/shared';
import { useAppDispatch } from '@/store';
import { addUser, removeUser } from '@/store/slices/userslice';
import { storage } from '@/core/utils';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { add_address, delete_address, edit_adddress, get_addresses, my_address } from '@/core/api/address';
import { AddressPayload } from '@/core/types/address';

export const getAddresses = () => {
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [addresses, setAddresses]: any = useState<PaginationType<UserType> | null>();
    const handleGetAddresses = async (query?: string, search?: string) => {
        try {
            setLoadingAddress(true);
            const data: PaginationType<UserType> = await get_addresses(query);
            setAddresses(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            
            setLoadingAddress(false);
        }
    };

    return {
        loadingAddress,
        addresses,
        handleGetAddresses,
    };
};

export const GetMyAddress = () => {
    const [loadingAddress, setLoadingAddress] = useState(false);
    const [addresses, setAddresses]: any = useState<PaginationType<UserType> | null>();
    const handleGetAddresses = async (query?: string, search?: string) => {
        try {
            setLoadingAddress(true);
            const data: PaginationType<UserType> = await my_address();
            setAddresses(data);
        } catch (error: any) {
            toast.error(error.response.message);
        } finally {
            
            setLoadingAddress(false);
        }
    };

    return {
        loadingAddress,
        addresses,
        handleGetAddresses,
    };
};
export const EditAddress = () => {
    const [loadingEditAddress,setLoadingEditAddress] = useState(false);
    const hanldeEditaddress = async (payload: AddressPayload, id: string) => {
        try {
            setLoadingEditAddress(true);
            edit_adddress(payload, id).then(() => toast.success('address updated'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong');
        } finally {
            setLoadingEditAddress(false);
        }
    };

    return {
        hanldeEditaddress,
        loadingEditAddress,
    };
};


export const useCreateAddress= () => {
    const [loadingCreate, setLoadingCreate] = useState(false);
    const handleCreate = async (payload: AddressPayload) => {
        try {
            setLoadingCreate(true);
            await add_address(payload).then(()=>toast.success('Address added'));
        } catch (error: any) {
            toast.error(error.response.message || 'Something went wrong.');
        } finally {
            setLoadingCreate(false);
        }
    };

    return {
        loadingCreate,
        handleCreate,
    };
};

export const useDeleteAddress = () => {
    const [loadingDelete, setLoadingDelete] = useState(false);
    const handleDelete = async (id: string) => {
        try {
            setLoadingDelete(true);
            await delete_address(id).then(()=> toast.success('Address deleted'));
        } catch (error: any) {
            toast.error(error.response.message || 'Error Occurred');
        } finally {
            setLoadingDelete(false);
        }
    };

    return {
        loadingDelete,
        handleDelete,
    };
};


