import Button from '@/components/button';
import { Form } from '@/components/form';
import { InputField, SelectField } from '@/components/input';
import { AdminCreateUser, useCreateUser } from '@/core/hooks/auth';
import { UserPayload } from '@/core/types/auth';
import { Link } from 'react-router-dom';
import { z } from 'zod';

const schema = z.object({
    email: z
        .string()
        .min(1, 'Email Name required')
        .refine(value => value.trim() === value, {
            message: 'Email cannot have leading or trailing spaces',
        }),

    firstName: z.string().min(1, { message: 'First name are required' }),
    lastName: z.string().min(1, { message: 'Last name are required' }),
    phoneNumber: z.string().min(1, { message: 'Phone number is required' }),

    role: z.string().min(1, { message: 'Role is required' }),
});

export default function CreateUser({referesh}:any) {
    const { loadingCreate, handleCreate } = AdminCreateUser();

    return (
        <>
            <div className=" my-2 ">
                <ol className="flex text-primary font-semibold dark:text-white-dark">
                    <Link
                        to="/account/admin"
                        className="bg-[#ebedf2] ltr:rounded-l-md rtl:rounded-r-md dark:bg-[#1b2e4b]"
                    >
                        <button className="p-1.5 ltr:pl-3 rtl:pr-3 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70">
                            Home
                        </button>
                    </Link>
                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <Link
                            to="/account/admin/users"
                            className="p-1.5 px-3 ltr:pl-6 rtl:pr-6 relative   h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-[#ebedf2] before:z-[1] dark:before:border-l-[#1b2e4b] hover:text-primary/70 dark:hover:text-white-dark/70"
                        >
                            Users
                        </Link>
                    </li>
                    <li className="bg-[#ebedf2] dark:bg-[#1b2e4b]">
                        <button className="bg-primary text-white-light p-1.5 ltr:pl-6 rtl:pr-6 ltr:pr-2 rtl:pl-2 relative  h-full flex items-center before:absolute ltr:before:-right-[15px] rtl:before:-left-[15px] rtl:before:rotate-180 before:inset-y-0 before:m-auto before:w-0 before:h-0 before:border-[16px] before:border-l-[15px] before:border-r-0 before:border-t-transparent before:border-b-transparent before:border-l-primary before:z-[1]">
                            Create User
                        </button>
                    </li>
                </ol>
            </div>
            <div className="my-4 ">
                <p className="text-lg font-bold ">Create User </p>
            </div>
            <div className=" panel">
                <div className="">
                    <Form<UserPayload, typeof schema>
                        onSubmit={data => handleCreate(data)}
                        schema={schema}
                    >
                        {({ register, formState }) => (
                            <div className="w-full">
                                <div className="px-4 flex flex-col gap-4 text-sm mb-3">
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            type="email"
                                            isLoading={loadingCreate}
                                            label="Email"
                                            error={formState.errors.email}
                                            registration={register('email')}
                                        />
                                    </div>
                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreate}
                                            label="First Name"
                                            error={formState.errors.firstName}
                                            registration={register('firstName')}
                                        />
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreate}
                                            label="Last Name"
                                            error={formState.errors.lastName}
                                            registration={register('lastName')}
                                        />
                                    </div>

                                    <div className="flex flex-col md:flex-row gap-2">
                                        <InputField
                                            type="text"
                                            isLoading={loadingCreate}
                                            label="Phone Number"
                                            error={formState.errors.phoneNumber}
                                            registration={register(
                                                'phoneNumber'
                                            )}
                                        />
                                    </div>

                                    <div className="flex flex-col md:flex-row gap-2">
                                        <SelectField
                                            placeholder="Select Role"
                                            isLoading={loadingCreate}
                                            label="Role"
                                            error={formState.errors.role}
                                            options={[
                                                {
                                                    value: 'admin',
                                                    text: 'Admin',
                                                },
                                                {
                                                    value: 'applicant',
                                                    text: 'Applicant',
                                                },
                                                {
                                                    value: 'evaluator',
                                                    text: ' Evaluator',
                                                },
                                                {
                                                    value: 'modulator',
                                                    text: 'Moderator',
                                                },
                                                {
                                                    value: 'academic_manager',
                                                    text: ' Academic Manager',
                                                },
                                            ]}
                                            registration={register('role')}
                                        />
                                    </div>
                                    <div className="my-3 flex justify-end">
                                        <Button disabled={loadingCreate}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Form>
                </div>
            </div>
        </>
    );
}
