import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import { PaginationType } from '@/core/types/shared';
import { TopicPayload, TopicType } from '@/core/types/topics';
import { queryString } from '@/core/utils/query';
import PRIVATE_API from '../axios';

export const add_topic = async (payload: TopicPayload): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/topic', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_topic = async (
    payload: TopicPayload,
    id: string
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/topic/${id}`, payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const one_topic = async (id: string): Promise<TopicType | any> => {
    try {
        const request = await PRIVATE_API.get(`/topic/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_topic = async (id: string): Promise<TopicType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/topic/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_topics = async (
    query?: string
): Promise<PaginationType<TopicType>> => {
    try {
        const request = await PRIVATE_API.get(`/topic${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
