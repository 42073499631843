import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios';
import { SupervisorPayload, SupervisorType } from '@/core/types/supervisor';
import { PaginationType } from '@/core/types/shared';
import { queryString } from '@/core/utils/query';

export const addSupervisor = async (payload: SupervisorPayload): Promise<any> => {
  try {
    const request = await PRIVATE_API.post('/supervisor', payload);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const editSupervisor = async (payload: SupervisorPayload, id: string): Promise<any> => {
  try {
    const request = await PRIVATE_API.patch(`/supervisor/${id}`, payload);
    return await request.data;
  } catch (error) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const getSupervisors =async (
  query?: string
): Promise<PaginationType<SupervisorType>> => {
  try {
    const request = await PRIVATE_API.get(`/supervisor${queryString(query)}`);
    return await request.data;
  } catch (error) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const getSupervisor = async (id: string): Promise<SupervisorType | any> => {
  try {
    const request = await PRIVATE_API.get(`/supervisor/${id}`);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const deleteSupervisor = async (id: string): Promise<any> => {
  try {
    const request = await PRIVATE_API.delete(`/supervisor/${id}`);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};
