import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios';

import { PaginationType } from '@/core/types/shared';
import { AuthLoginPayload, UserPayload, UserType } from '@/core/types/auth';
import { queryString } from '@/core/utils/query';
import { PersonalInfoPayload } from '@/core/types/personal_info';




export const add_personal_information = async (payload : PersonalInfoPayload, id: string): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/personal_information/${id}`,payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const my_personal_information = async (): Promise<UserType | any> => {
    try {
        const request = await PRIVATE_API.get('/personal_information/my-personal-information');
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};



export const get_personal_information = async (query? : string): Promise<PaginationType<UserType>> => {
    try {
        const request = await PRIVATE_API.get(`/personal_information${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

