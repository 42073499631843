import { useState } from 'react';
import { Tab } from '@headlessui/react';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import IconUser from '@/components/Icon/IconUser';
import IconClock from '@/components/Icon/IconClock';
import IconListCheck from '@/components/Icon/IconListCheck';
import { AcademicCapIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline';
import IconMenuWidgets from '@/components/Icon/Menu/IconMenuWidgets';
import IconMenuCalendar from '@/components/Icon/Menu/IconMenuCalendar';
import IconMicrophoneOff from '@/components/Icon/IconMicrophoneOff';
import IconMenuApps from '@/components/Icon/Menu/IconMenuApps';
import { useAppSelector } from '@/store';

const ApplicantLayout = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState(0); 
    const navigate = useNavigate();
    const { userAccount:user } = useAppSelector((store) => store.user);

   
    const links = [
        {
            to:  `/${user?.role}/profile`,
            icon: <IconUser />,
            title: 'Personal ',
            index: 0, 
        },
        {
            to:  `/${user?.role}/profile/address`,
            icon: <IconClock />,
            title: 'Address',
            index: 1,
        },
        {
            to:  `/${user?.role}/profile/academic-information`,
            icon: <IconMenuCalendar />,
            title: 'Academics',
            index: 2,
        },
        {
            to:  `/${user?.role}/profile/experience`,
            icon: <IconMenuWidgets />,
            title: 'Experience',
            index: 3,
        },
        {
            to: `/${user?.role}/profile/language`,
            icon: <IconMenuApps />,
            title: 'Languages',
            index: 4,
        },
        {
            to:  `/${user?.role}/profile/reference`,
            icon: <IconClock />,
            title: 'Reference',
            index: 5,
        },
    ];

    return (
        <div className="w-full">
            
            <div className="mt-4">
                <Outlet />
            </div>
          
          
        </div>
    );
};

export default ApplicantLayout;
