import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios'
import { PaginationType } from '@/core/types/shared';
import { queryString } from '@/core/utils/query';
import { LanguagePayload, LanguageType } from '@/core/types/languages';

export const add_language = async (
    payload: LanguagePayload
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/language', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_language = async (
    payload: LanguagePayload,
    id: string
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/language/${id}`, payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const one_language = async (
    id: string
): Promise<LanguageType | any> => {
    try {
        const request = await PRIVATE_API.get(`/language/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_language = async (
    id: string
): Promise<LanguageType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/language/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const get_languages = async (
    query?: string
): Promise<PaginationType<LanguageType>> => {
    try {
        const request = await PRIVATE_API.get(`/language${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
