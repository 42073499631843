import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { EditApplicationInfo } from '@/core/hooks/application';

export const More = ({ evaluation }: any) => {
    const [modal, setModal] = React.useState(false);
    const { loadingEditApplication, handleEditApplication } =
        EditApplicationInfo();

    return (
        <div className="">
            <div className="flex items-center justify-center">
                <button
                    type="button"
                    onClick={() => setModal(true)}
                    className="btn btn-primary btn-sm"
                >
                    Comments
                </button>
            </div>
            <Transition appear show={modal} as={Fragment}>
                <Dialog as="div" open={modal} onClose={() => setModal(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0" />
                    </Transition.Child>
                    <div className="fixed inset-0 bg-[black]/60 z-[999] overflow-y-auto">
                        <div className="flex items-start justify-center min-h-screen px-4">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel
                                    as="div"
                                    className="panel border-0 p-0 rounded-lg overflow-hidden my-8 w-full max-w-3xl text-black dark:text-white-dark"
                                >
                                    <div className="border border-primary ">
                                        <div className="bg-primary flex flex-row justify-center p-2 text-lg  text-white ">
                                            Evaluator decisions and comments
                                        </div>
                                        <table className="table-auto w-full">
                                            <tbody>
                                                <tr>
                                                    <td className="font-bold">
                                                        Evaluator
                                                    </td>
                                                    <td className="font-bold">
                                                        Decision 
                                                    </td>
                                                    <td className="font-bold">
                                                        Comment 
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-medium">
                                                        Evaluator 1
                                                    </td>
                                                    <td>
                                                        {evaluation?.evaluator_one_marks ||
                                                            '-'}
                                                    </td>
                                                    <td>
                                                        {evaluation?.evaluator_one_stepOne_comment ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-medium">
                                                        Evaluator 2
                                                    </td>
                                                    <td>
                                                        {evaluation?.evaluator_two_marks ||
                                                            '-'}
                                                    </td>
                                                    <td>
                                                        {evaluation?.evaluator_two_stepOne_comment ||
                                                            '-'}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="font-medium">
                                                        Evaluator 3
                                                    </td>
                                                    <td>
                                                        {evaluation?.evaluatorThree ? (
                                                            evaluation?.evaluator_three_marks || '-'
                                                        ) : (
                                                            'No evaluator 3'
                                                        )}
                                                    </td>
                                                    <td>
                                                        {evaluation?.evaluatorThree ? (
                                                            evaluation?.evaluator_three_stepOne_comment || '-'
                                                        ) : (
                                                            'No evaluator 3'
                                                        )}
                                                    </td>
                                                   
                                                </tr>
                                                <tr>
                                                    <td className="font-medium">
                                                        Academic manager
                                                    </td>
                                                    <td>
                                                        {evaluation?.academicManager
                                                            ? evaluation?.academic_manager_decision ||
                                                              '-'
                                                            : 'No Academic manager '}
                                                    </td>
                                                    <td>
                                                        {evaluation?.academicManager
                                                            ? evaluation?.academic_manager_comment ||
                                                              '-'
                                                            : 'No Academic manager '}
                                                    </td>
                                                   
                                                   
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};
