
import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios'
import { CategoryPayload, CategoryType } from '@/core/types/category';
import { PaginationType } from '@/core/types/shared';
import { queryString } from '@/core/utils/query';

export const add_category = async (payload: CategoryPayload): Promise<any> => {
    try {
        const request = await PRIVATE_API.post('/category', payload);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const edit_category = async (
    payload: CategoryPayload,
    id: string
): Promise<any> => {
    try {
        const request = await PRIVATE_API.patch(`/category/${id}`, payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
export const add_sub_category = async (
    payload: any,
  
): Promise<any> => {
    try {
        const request = await PRIVATE_API.post(`/category/add-sub-category`, payload);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const one_category = async (id: string): Promise<CategoryType | any> => {
    try {
        const request = await PRIVATE_API.get(`/category/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const delete_category = async (id: string): Promise<CategoryType | any> => {
    try {
        const request = await PRIVATE_API.delete(`/category/${id}`);
        return await request.data;
    } catch (error: any) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};


export const get_categories = async (
    query?: string
): Promise<PaginationType<CategoryType>> => {
    try {
        const request = await PRIVATE_API.get(`/category${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};
