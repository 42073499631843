import { GetAnswers } from '@/core/hooks/answers';
import {
    GetApplications,
    useApplyApplication,
} from '@/core/hooks/application';
import { GetTopics } from '@/core/hooks/topics';
import { ApplicationType } from '@/core/types/application';
import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import { Fragment } from 'react';
import { UpdateApplication } from '../modal/Update_Application';

export default function ApplicantsApplications() {
    const location = useLocation();
    const currentPath = location.pathname;

    const link = [
        {
            to: '',
            title: 'Incomplete Applications',
            exact: true,
        },
        {
            to: 'submitted-applications',
            title: 'Submitted Applications',
            exact: false,
        },
    ];

    return (
        <>
            <Tab.Group>
                <Tab.List className="mt-1 flex flex-wrap border-2 border-primary justify-center w-fit   ">
                    {link.map((tab, index) => (
                        <Tab as={Fragment} key={index}>
                            {() => (
                                <TabLink
                                    title={tab.title}
                                    to={tab.to}
                                    active={
                                        tab.exact
                                            ? currentPath === '/' || currentPath === `/${tab.to}`
                                            : currentPath.includes(tab.to)
                                    }
                                />
                            )}
                        </Tab>
                    ))}
                </Tab.List>
            </Tab.Group>
            <div className="mt-5 p-4">
                <Outlet />
            </div>
        </>
    );
}

function TabLink(props: { title: string; to: string; active: boolean }) {
    return (
        <Link
            to={props.to}
            className={`py-2 px-3  transition-colors duration-300 ${
                props.active
                    ? 'bg-primary text-white border-b-2 border-secondary'
                    : 'bg-gray-100 text-gray-700 hover:bg-primary/100 hover:text-white'
            }`}
        >
            {props.title}
        </Link>
    );
}
