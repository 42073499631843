import { AxiosErrorHandler, CustomError } from '@/core/utils/request';
import PRIVATE_API from '../axios';
import { QuestionPayload, QuestionType } from '@/core/types/questions';
import { PaginationType } from '@/core/types/shared';
import { queryString } from '@/core/utils/query';

export const addQuestion = async (payload: QuestionPayload): Promise<any> => {
  try {
    const request = await PRIVATE_API.post('/question', payload);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const editQuestion = async (payload: QuestionPayload, id: string): Promise<any> => {
  try {
    const request = await PRIVATE_API.patch(`/question/${id}`, payload);
    return await request.data;
  } catch (error) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};


export const getQuestions = async (
    query?: string
): Promise<PaginationType<QuestionType>> => {
    try {
        const request = await PRIVATE_API.get(`/question${queryString(query)}`);
        return await request.data;
    } catch (error) {
        throw new CustomError(AxiosErrorHandler(error));
    }
};

export const getQuestion = async (id: string): Promise<QuestionType | any> => {
  try {
    const request = await PRIVATE_API.get(`/question/${id}`);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};

export const deleteQuestion = async (id: string): Promise<any> => {
  try {
    const request = await PRIVATE_API.delete(`/question/${id}`);
    return await request.data;
  } catch (error: any) {
    throw new CustomError(AxiosErrorHandler(error));
  }
};
